import { createSlice } from '@reduxjs/toolkit'

// Services
import { requestCollegesStates } from './services'

// Utils

// Define a type for the slice state
export type CollegesState = {
  colleges: string[]
}

// Define the initial state using that type
const initialState: CollegesState = {
  colleges: [],
}

export const colleges = createSlice({
  name: 'colleges',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestCollegesStates(builder)
  },
})
