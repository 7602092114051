import { requestLists } from '../lists/services'
import { requestCandidates } from '../scrutins/services'

export const requestListsStates = (builder) => {
  builder.addCase(requestCandidates.pending, (state) => {
    state.isLoading = true
  })
  builder.addCase(requestLists.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestLists.fulfilled, (state, { payload }) => {
    state.isLoading = false
    if (payload.data) {
      state.candidatesById = {
        ...state.candidatesById,
        ...payload.data.reduce((acc, list) => {
          list.candidates.forEach((candidate) => {
            acc[candidate.id] = candidate
          })
          return acc
        }, {}),
      }
    }
  })
  builder.addCase(requestCandidates.fulfilled, (state, { payload }) => {
    state.isLoading = false
    if (payload.data) {
      state.candidatesById = {
        ...state.candidatesById,
        ...payload.data.reduce((acc, candidate) => {
          acc[candidate.id] = candidate
          return acc
        }, {}),
      }
    }
  })

  builder.addCase(requestLists.rejected, (state) => {
    state.isLoading = false
  })
  builder.addCase(requestCandidates.rejected, (state) => {
    state.isLoading = false
  })
}
