import { USER_COLORS } from '../constants/Colors'

const getColorMaker = (fullname, username) => {
  if (!fullname?.length && !username?.length) {
    return USER_COLORS[0]
  }
  const name = fullname || username

  let hash = 0
  for (var i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash)
    hash = hash & hash
  }
  hash = ((hash % USER_COLORS.length) + USER_COLORS.length) % USER_COLORS.length
  return USER_COLORS[hash]
}

export default getColorMaker
