import { useCallback } from 'react'
import { useAppDispatch } from '../../init/store'
import {
  closeModal as closeModalAction,
  editContext as editContextAction,
  openModal as openModalAction,
  resetContext as resetContextAction,
} from './redux'

export const useModalsEditContext = (modalName: string) => {
  const dispatch = useAppDispatch()
  const editContext = useCallback(
    (context) => dispatch(editContextAction({ modalName, context })),
    [dispatch, modalName]
  )

  return {
    editContext,
  }
}

export const useModalsOpen = (modalName: string) => {
  const dispatch = useAppDispatch()
  const openModal = useCallback(
    (payload) => dispatch(openModalAction({ modalName, ...payload })),
    [dispatch, modalName]
  )

  return { openModal }
}

export const useModalsClose = (modalName: string) => {
  const dispatch = useAppDispatch()
  const closeModal = useCallback(
    () => dispatch(closeModalAction({ modalName })),
    [dispatch, modalName]
  )

  return { closeModal }
}

export const useModalsResetContext = (modalName: string) => {
  const dispatch = useAppDispatch()
  const { closeModal } = useModalsClose(modalName)
  const resetContext = useCallback(() => {
    closeModal()
    dispatch(resetContextAction({ modalName }))
  }, [closeModal, dispatch, modalName])

  return { resetContext }
}
