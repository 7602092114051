import axios from 'axios'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './Image.scss'

const IMAGE_FOLDER = '/assets/images/'
const DEFAULT_EXT = 'svg'

const Image: React.FC<{
  src: string
  ext?: 'png' | 'jpg' | 'svg'
  id?: string
  className?: string
}> = ({ src, ext = DEFAULT_EXT, id = '', className = '' }) => {
  const ref = useRef(true)
  const [data, setData] = useState<any>(null)
  const source = useMemo(
    () =>
      src.startsWith('https://') || src.startsWith('http://') || src.startsWith('/')
        ? src
        : `${IMAGE_FOLDER}${src}.${ext || DEFAULT_EXT}`,
    [ext, src]
  )

  const getData = useCallback(async () => {
    let imageData: any = ''
    try {
      imageData = (await axios.get(source)).data
    } catch (err) {
      imageData = ''
    }
    if (ref.current) {
      setData(imageData)
    }
  }, [source])

  useEffect(() => {
    if ((!src.startsWith('https://') && !src.startsWith('http://')) || src.startsWith('/')) {
      getData()
    }
  }, [getData, src])

  useEffect(
    () => () => {
      ref.current = false
    },
    []
  )

  if (ext === 'svg' && !!data && data !== '') {
    return (
      <div
        id={id}
        className={`img CustomImageComponent ${className}`}
        dangerouslySetInnerHTML={data ? { __html: data } : undefined}
      />
    )
  }
  return (
    <img
      id={id}
      src={source}
      className={`img CustomImageComponent ${className}`}
      alt='default_image'
    />
  )
}

export default Image
