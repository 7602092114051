// Hooks
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useLocalize from '../../../../locales/hook'

// Components
import UserItem from './UserItem'
import Icon from '../../../../atoms/Icon/Icon'

// Types
import IconTypes from '../../../../utils/types/IconTypes'
import {
  useCellingUsers,
  useRandomCellMember,
  useRandomCellMemberOverride,
} from '../../../../redux/sealing/hooks'

const CellMemberItem: React.FC<{
  filters: any
  members: any
  currentUserId: string
  onUserSelect: (userId: string) => void
}> = ({ currentUserId, filters, members, onUserSelect }) => {
  const role = 'cell_member'

  // Hooks
  const t = useLocalize()
  const getRandomCellMember = useRandomCellMember()
  const { users, getCellingUsers } = useCellingUsers()
  const { overrideCellMemberUsers, setUsersCellMemberOverride } = useRandomCellMemberOverride()

  // Refs
  const timer = useRef<NodeJS.Timeout | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  // States
  const [search, setSearch] = useState<string>('')
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [status, setStatus] = useState<boolean>(false)

  // Memos
  const usersToShow = useMemo(() => {
    return users.filter((userId) => !overrideCellMemberUsers.includes(userId))
  }, [users, overrideCellMemberUsers])

  // Handlers

  //Click sur le lien "Ajouter"
  const handleAdd = useCallback(() => {
    setSearch('')
    setIsOpen((prev) => !prev)
  }, [])

  //Changement du texte de recherche
  const handleTextChange = useCallback(
    (ev) => {
      setSearch(ev.target.value || '')
      if (timer.current) clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        getCellingUsers(ev.target.value, {
          excludedUserIds: overrideCellMemberUsers,
        })
      }, 800)
    },
    [getCellingUsers, overrideCellMemberUsers]
  )

  //Choix random d'un user
  const handleRandom = useCallback(() => {
    setStatus(false)
    getRandomCellMember({
      excludedUserIds: overrideCellMemberUsers,
    })
  }, [overrideCellMemberUsers, getRandomCellMember])

  const handleDpClick = useCallback(
    (ev) => {
      setStatus(!status)
      ev.preventDefault()
    },
    [setStatus, status]
  )

  const handleUserClick = useCallback(
    (userId: string) => {
      setIsOpen(false)
      let cpSelection = JSON.parse(JSON.stringify(overrideCellMemberUsers))

      if (!cpSelection) cpSelection = [userId]
      else if (!cpSelection.includes(userId)) {
        cpSelection = [userId, ...cpSelection]
      } else {
        cpSelection = cpSelection.filter((val) => val !== userId)
      }
      setUsersCellMemberOverride(cpSelection)
    },
    [overrideCellMemberUsers, setUsersCellMemberOverride]
  )

  const handleFocus = useCallback(() => {
    if (timer.current) clearTimeout(timer.current)
    setStatus(true)
  }, [])

  const handleUserSelect = useCallback(
    (userId) => {
      onUserSelect(userId)
    },
    [onUserSelect]
  )

  const handleBlur = useCallback(() => setStatus(false), [])

  // Effect
  useEffect(() => {
    getCellingUsers(search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <div className='roleItem'>
      <div className='head'>
        <div className='label'>{t(`role_${role.toLowerCase()}`)}</div>
        {(!!usersToShow?.length || !!search?.length || members.length >= 2) && (
          <div className='add' onClick={handleAdd}>
            <Icon icon={isOpen ? IconTypes.MINUS : IconTypes.PLUS} />
            <div className='label'>{isOpen ? t('close') : t('add')}</div>
          </div>
        )}
      </div>
      {(!!usersToShow?.length || !!search?.length) && isOpen && (
        <div className='roleBox'>
          <Icon
            icon={IconTypes.DICE}
            className='random'
            onClick={handleRandom}
            disabled={overrideCellMemberUsers?.length > 15}
          />
          <div className='dropdownBox'>
            <div className={`inputBox ${status ? 'open' : ''}`}>
              {!overrideCellMemberUsers?.length && (
                <div className='searchIcon'>
                  <Icon className='search' icon={IconTypes.SEARCH} />
                </div>
              )}
              {!!overrideCellMemberUsers?.length && <div className='search' />}
              <div className='selectionInput'>
                <input
                  ref={inputRef}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={handleTextChange}
                  placeholder={t('search')}
                />
              </div>
              <div className='dropdownIcon'>
                <Icon
                  onMouseDown={handleDpClick}
                  onPointerDown={handleDpClick}
                  icon={status ? IconTypes.CHEVRON_UP : IconTypes.CHEVRON_DOWN}
                />
              </div>
            </div>
            <div className={`dropdown ${status ? '' : 'hidden'}`}>
              {usersToShow.map((userId) => (
                <UserItem userId={userId} key={userId} small onClick={handleUserClick} />
              ))}
              {!usersToShow.length && <div className='no_data'>{t('no_users')}</div>}
            </div>
          </div>
        </div>
      )}
      <div className='box'>
        {overrideCellMemberUsers?.map((userId) => (
          <UserItem
            userId={userId}
            key={userId}
            selected={userId === currentUserId}
            onClick={handleUserSelect}
            onDelete={handleUserClick}
          />
        ))}
        {!overrideCellMemberUsers?.length && (
          <div className='nothing'>{t('demo_nothing_selected')}</div>
        )}
      </div>
    </div>
  )
}

export default CellMemberItem
