import { createSlice } from '@reduxjs/toolkit'

// // Services

import { requestControlPollStates, requestEditControlPollStates } from './services'

// Utils

// Define a type for the slice state
export type ControlStates = {
  controlByPollId: { [id: string]: any }
}

// Define the initial state using that type
const initialState: ControlStates = {
  controlByPollId: {},
}

export const control = createSlice({
  name: 'control',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestControlPollStates(builder)
    requestEditControlPollStates(builder)
  },
})
