import React, { useCallback } from 'react'
import IconTypes from '../../utils/types/IconTypes'
import Icon from '../Icon/Icon'

// Styles & Config
import './CheckBox.scss'

interface Props {
  label?: string
  labelLink?: string
  noLabel?: boolean
  link?: string
  value?: any
  onSelect?: (value: any) => void
  selected: boolean
  blocked?: boolean
  error?: boolean
  errorMessage?: string
  fontStyle?: string
  addIcon?: boolean
  preventDefault?: boolean
}

const CheckBox: React.FC<Props> = React.memo(
  ({
    label,
    value,
    onSelect,
    selected,
    blocked,
    error,
    errorMessage,
    labelLink,
    noLabel = false,
    preventDefault = false,
    link,
    fontStyle,
    addIcon,
  }) => {
    const handleClick = useCallback(
      (ev) => {
        preventDefault && ev.preventDefault()
        !blocked && onSelect?.(value || label)
      },
      [preventDefault, blocked, onSelect, value, label]
    )

    const handleLink = useCallback(() => {
      window.open(link, '_blank')
    }, [link])

    return (
      <div
        id='CheckBox'
        className={`${!!selected} ${blocked ? 'blocked' : ''} ${error ? 'isError' : ''}`}
        onClick={preventDefault ? undefined : handleClick}
        onMouseDown={preventDefault ? handleClick : undefined}
        onPointerDown={preventDefault ? handleClick : undefined}
      >
        <div
          className={`selectBox ${!!selected} ${addIcon ? 'icon' : ''} ${blocked ? 'blocked' : ''}`}
        >
          {addIcon && <Icon icon={IconTypes.CHECK} />}
        </div>
        {!labelLink && !noLabel && (!!label || !!value) && (
          <div className={`label ${fontStyle} ${!!selected ? 'selected' : ''}`}>
            {label || value}
          </div>
        )}
        {!!labelLink && !noLabel && (
          <div className={`label  ${fontStyle}`}>
            {label} <div onClick={handleLink}>{labelLink}</div>
          </div>
        )}
        {!!error && <div className='errorMessage'>{errorMessage}</div>}
      </div>
    )
  }
)

export default CheckBox
