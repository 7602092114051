import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  deleteAxios,
  getAxios,
  getTokenHeaders,
  patchAxios,
  setAxios,
} from '../../utils/lib/requestAxios'

// Request the lists of a poll
export const requestLists = createAsyncThunk<any, { pollId: string }>(
  'lists/requestLists',
  async ({ pollId }, { rejectWithValue }) => {
    const response: any = await getAxios(`/aggregated/poll?pollId=${pollId}`, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    return {
      pollId,
      data: response.data,
    }
  }
)

export const requestListsStates = (builder) => {
  builder.addCase(requestLists.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestLists.fulfilled, (state, { payload }) => {
    state.isLoading = false
    if (payload.data) {
      const { data, pollId } = payload
      data.forEach((list) => {
        if (!state.listIds[pollId]) {
          state.listIds[pollId] = [list.id]
        } else if (!state.listIds[pollId].includes(list.id)) {
          state.listIds[pollId] = [...state.listIds[pollId], list.id]
        }
        state.listsById[list.id] = {
          ...(state.listsById[list.id] || {}),
          ...list,
          candidates: undefined,
          candidateIds: list.candidates.sort((a, b) => a.position - b.position).map(({ id }) => id),
        }
      })
      state.listIds[pollId] = state.listIds[pollId]?.sort(
        (a, b) => state.listsById[a]?.position - state.listsById[b]?.position
      )
    }
  })

  builder.addCase(requestLists.rejected, (state) => {
    state.isLoading = false
  })
}

// Create or Change List
export const requestUpdateList = createAsyncThunk<
  any,
  { listId?: number; scrutinId?: string; info: any }
>('lists/requestUpdateList', async ({ listId, scrutinId, info }) => {
  const NULL: any = null
  const body = new FormData()
  body.append('title', info.title)
  if (info.listRepartition) {
    body.append('listRepartition', info.listRepartition)
  }

  if (!(info.video instanceof String)) {
    if (info.video === null) {
      body.append('videoUrl', NULL)
    } else {
      body.append('video', info.video)
    }
  }
  if (!(info.professionOfFaith instanceof String)) {
    if (info.professionOfFaith === null) {
      body.append('professionOfFaithUrl', NULL)
    } else {
      body.append('pdf', info.professionOfFaith)
    }
  }
  if (!(info.logo instanceof String)) {
    if (info.logo === null) {
      body.append('logoUrl', NULL)
    } else {
      body.append('image', info.logo)
    }
  }

  let response: any
  if (!listId) {
    response = await setAxios(
      `/cse/polls/${scrutinId}/add-list_with-media`,
      body,
      getTokenHeaders()
    )
  } else {
    response = await patchAxios(`/lists/${listId}/with-media`, body, getTokenHeaders())
  }

  return {
    data: response.data,
  }
})

export const requestUpdateListStates = (builder) => {
  builder.addCase(requestUpdateList.fulfilled, (state, { payload }) => {
    const { data } = payload

    if (data) {
      if (!state.listIds[data.pollId]) {
        state.listIds[data.pollId] = [data.id]
      } else if (!state.listIds[data.pollId].includes(data.id)) {
        state.listIds[data.pollId] = [...state.listIds[data.pollId], data.id]
      }
      state.listsById[data.id] = {
        ...(state.listsById[data.id] || {}),
        ...data,
        candidates: undefined,
        candidateIds:
          data.candidates?.sort((a, b) => a.position - b.position).map(({ id }) => id) ||
          state.listsById[data.id]?.candidateIds,
      }
      state.listIds[data.pollId] = state.listIds[data.pollId]?.sort(
        (a, b) => state.listsById[a]?.position - state.listsById[b]?.position
      )
    }
  })
}

// Change List order
export const requestChangeListOrder = createAsyncThunk<any, { pollId: string; orderIds: number[] }>(
  'lists/requestChangeListOrder',
  async ({ pollId, orderIds }) => {
    await setAxios(
      `/cse/polls/${pollId}/list-positions`,
      { orderedListIds: orderIds },
      getTokenHeaders()
    )
    return {
      pollId,
      orderIds,
    }
  }
)

export const requestChangeListOrderStates = (builder) => {
  builder.addCase(requestChangeListOrder.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestChangeListOrder.fulfilled, (state, { payload }) => {
    state.isLoading = false
    const { orderIds, pollId } = payload

    state.listIds[pollId] = orderIds
  })

  builder.addCase(requestChangeListOrder.rejected, (state) => {
    state.isLoading = false
  })
}

// Delete a list
export const requestDeleteList = createAsyncThunk<any, { listId: number }>(
  'lists/requestDeleteList',
  async ({ listId }, { rejectWithValue }) => {
    const response: any = await deleteAxios(`/lists/${listId}`, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    return {
      listId,
    }
  }
)

export const requestDeleteListStates = (builder) => {
  builder.addCase(requestDeleteList.fulfilled, (state, { payload }) => {
    const { listId } = payload

    const { pollId } = state.listsById[listId]
    state.listIds[pollId] = state.listIds?.[pollId]?.filter((id) => id !== listId)
    delete state.listsById[listId]
  })
}

// Delete a candidate in a list
export const requestDeleteCandidate = createAsyncThunk<
  any,
  { candidateId: number; listId: number }
>('lists/requestDeleteCandidate', async ({ candidateId, listId }, { rejectWithValue }) => {
  const response: any = await setAxios(
    `/lists/${listId}/remove-candidate`,
    { candidateId },
    getTokenHeaders()
  )

  if (response.error) {
    return rejectWithValue(response.error.response.status)
  }
  return {
    data: {
      candidateId,
      listId,
    },
  }
})

export const requestDeleteCandidateStates = (builder) => {
  builder.addCase(requestDeleteCandidate.fulfilled, (state, { payload }) => {
    if (payload.data) {
      const { candidateId, listId } = payload.data

      state.listsById[listId].candidateIds = state.listsById[listId].candidateIds.filter(
        (id) => id !== candidateId
      )
    }
  })
}

// Reorder the candidates of a list
export const requestReorderCandidates = createAsyncThunk<
  any,
  { orderedCandidateIds: number[]; listId: number }
>(
  'lists/requestReorderCandidates',
  async ({ orderedCandidateIds, listId }, { rejectWithValue }) => {
    const response: any = await setAxios(
      `/lists/${listId}/candidate-positions`,
      { orderedCandidateIds },
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    return {
      data: {
        orderedCandidateIds,
        listId,
      },
    }
  }
)

export const requestReorderCandidatesStates = (builder) => {
  builder.addCase(requestReorderCandidates.fulfilled, (state, { payload }) => {
    if (payload.data) {
      const { orderedCandidateIds, listId } = payload.data

      state.listsById[listId].candidateIds = orderedCandidateIds
    }
  })
}
