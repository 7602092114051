import { createSlice } from '@reduxjs/toolkit'
import {
  requestEditCandidateState,
  requestAddCandidateToUniPollStates,
} from '../../pages/CandidatesElection/partials/AddCandidateScrutinModal/services'
import {
  requestDeleteUniCandidateStates,
  requestReorderCandidatesStates,
  requestUniScrutinStates,
  requestVoteUninominalStates,
} from './services'

// // Services

// Utils

// Define a type for the slice state
export type UniPollState = {
  candidatesByUniId: { [id: string]: Array<number> }
  candidatesById: { [id: number]: any }
  meSignatures: { [id: string]: any }
  ballots: { [id: number]: any }
}

// Define the initial state using that type
const initialState: UniPollState = {
  meSignatures: {},
  candidatesByUniId: {},
  candidatesById: {},
  ballots: {},
}

export const uninominal = createSlice({
  name: 'uninominal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestUniScrutinStates(builder)
    requestEditCandidateState(builder)
    requestVoteUninominalStates(builder)
    requestDeleteUniCandidateStates(builder)
    requestAddCandidateToUniPollStates(builder)
    requestReorderCandidatesStates(builder)
  },
})
