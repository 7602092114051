import { createAsyncThunk } from '@reduxjs/toolkit'
import { downloadFile } from '../../utils/lib/downloadFile'
import { getAxios, getTokenHeaders, setAxios } from '../../utils/lib/requestAxios'

// Upload physical vote for mixed polls
export const requestUploadMixedPoll = createAsyncThunk<
  {
    file: File | null
    voteType: String
    errorCallback: Function
    successCallback: Function
  },
  any
>(
  'mixedPoll/requestUploadMixedPoll',
  async (
    { file, voteType, errorCallback, successCallback },
    { rejectWithValue, fulfillWithValue }
  ) => {
    if (!file) return successCallback()

    const body = new FormData()
    if (file) {
      body.append('zip', file)
    }

    const url = voteType === 'CSE' ? 'results' : 'results-not-cse'

    let response: any = await setAxios('/imports/' + url + '/zip', body, getTokenHeaders())

    if (response.error) {
      if (response.error?.response?.data?.message) {
        errorCallback(response.error?.response?.data?.message)
        return
      } else {
        return rejectWithValue(response.error.response.status)
      }
    }
    successCallback()
    return response.data
  }
)

export const requestUploadMixedPollState = (builder) => {
  builder.addCase(requestUploadMixedPoll.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestUploadMixedPoll.fulfilled, (state, { payload }) => {
    state.isLoading = false
  })

  builder.addCase(requestUploadMixedPoll.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestExportScrutins = createAsyncThunk<unknown, { round?: string }>(
  'mixedPoll/requestExportScrutins',
  async ({ round = 'FIRST' }, { rejectWithValue }) => {
    const headers = {
      responseType: 'blob',
      ...getTokenHeaders(),
    }
    const response: any = await getAxios(`/cse/exports/mixed-votes/${round}`, headers)

    if (response.error) {
      return response.error?.message
        ? { error: response.error?.message }
        : rejectWithValue(response.error.statusCode)
    }

    downloadFile(response)
    return null
  }
)

export const requestExportScrutinsNotCse = createAsyncThunk<unknown, { round?: string }>(
  'mixedPoll/requestExportScrutins',
  async ({ round = 'FIRST' }, { rejectWithValue }) => {
    const headers = {
      responseType: 'blob',
      ...getTokenHeaders(),
    }
    const response: any = await getAxios(`/not-cse/exports/mixed-votes/${round}`, headers)

    if (response.error) {
      return response.error?.message
        ? { error: response.error?.message }
        : rejectWithValue(response.error.statusCode)
    }

    downloadFile(response)
    return null
  }
)
