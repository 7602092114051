// Hooks
import { useMemo } from 'react'
import useLocalize from '../../../locales/hook'

// Components
import WarningModal from '../../../atoms/WarningModal/WarningModal'

// Styles
import './ErrorModal.scss'
import { useAppError } from '../../../redux/app/hooks'

const ErrorModal: React.FC = () => {
  const t = useLocalize()
  const { errorMessage, errorParams, errorTitle, isError, setError } = useAppError()

  const message = useMemo(
    () => ({
      description: errorMessage?.length
        ? t(errorMessage, errorParams || {})
        : t('global_error_message'),
    }),
    [errorMessage, errorParams, t]
  )

  return (
    <WarningModal
      hideCancel
      hideConfirm
      visible={isError}
      message={message}
      setVisible={setError}
      title={errorTitle?.length ? t(errorTitle) : t('global_error_title')}
    />
  )
}

export default ErrorModal
