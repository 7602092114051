import { createSlice } from '@reduxjs/toolkit'

// Services
import {
  requestGetGeneralHelpFilesStates,
  requestGetPollHelpFilesStates,
  requestGetRoleHelpFilesStates,
  requestSetGeneralHelpFilesStates,
  requestSetPollHelpFilesStates,
  requestSetRoleHelpFilesStates,
} from './services'

// Utils

// Define a type for the slice state
export type HelpFilesState = {
  general: {
    id?: string | number
    globalHelpPdfUrl?: File[] | string | null
    globalHelpMp4Url?: File[] | string | null
  }
  poll: {
    [key: string]: {
      id?: string | number
      helpPdfUrl?: File[] | string | null
      helpMp4Url?: File[] | string | null
      role?: string
    }
  }
  role: {
    [key: string]: {
      id?: string | number
      helpPdfUrl?: File[] | string | null
      helpMp4Url?: File[] | string | null
      pollId?: string
    }
  }
}

// Define the initial state using that type
const initialState: HelpFilesState = {
  general: {},
  poll: {},
  role: {},
}

export const helpFiles = createSlice({
  name: 'helpFiles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestGetGeneralHelpFilesStates(builder)
    requestSetGeneralHelpFilesStates(builder)
    requestGetRoleHelpFilesStates(builder)
    requestSetRoleHelpFilesStates(builder)
    requestGetPollHelpFilesStates(builder)
    requestSetPollHelpFilesStates(builder)
  },
})

// export const { } = files.actions
