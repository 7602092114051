import { useCallback, useMemo } from 'react'
import {
  requestCheckThirdPartyAvailable,
  requestDownloadArchive,
  requestGetConfigInfos,
  requestIdRules,
  requestPatchConfigInfos,
  requestResetFor2ndRound,
  requestSetIdRules,
  requestSetVoteInfos,
  requestVoteInfos,
} from './services'

import {
  SetConfigTmp,
  ResetConfigTmp,
  SetVoteTmp,
  SetSelectedEstablishment,
  SetIsError,
  SetVoteTmpById,
  ResetVoteTmp,
  ResetAllConfig,
  SetEstablishmentNotCSE,
  SetRedirectedToUniquePolls,
} from './redux'
import { useAppDispatch, useAppSelector } from '../../init/store'
import { requestEstablishmentsData, requestSetConfig } from '../appConfig/services'
import { ELECTION_ROUND } from '../../utils/constants/ElectionConstants'
import { use2ndRoundExport } from '../../redux/files/hooks'
import { setDownload1RoundStatus } from '../appConfig/redux'
import { requestSetDownload1Round } from '../appConfig/services'
import { authLogout } from '../auth/redux'

// Demo
export const useConfigTmp = () => {
  const dispatch = useAppDispatch()
  const isCSE = useAppSelector((state) => state.configuration.type === 'CSE')

  const getConfigInfos = useCallback(() => dispatch(requestGetConfigInfos()), [dispatch])

  const getThirdPartyServerStatus = useCallback(
    () => dispatch(requestCheckThirdPartyAvailable()),
    [dispatch]
  )

  const setTmpConfig = useCallback(
    (key, value) => {
      if (key === 'closingDate') {
        dispatch(SetConfigTmp({ key: 'closingTime', value }))
      }
      dispatch(SetConfigTmp({ key, value }))

      !isCSE && dispatch(SetEstablishmentNotCSE())
    },
    [dispatch, isCSE]
  )

  const configInit = useAppSelector((state) => state.configuration.config)
  const configTmp = useAppSelector((state) => state.configuration.configTmp)
  const thirdPartyAvailable = useAppSelector((state) => state.configuration.thirdPartyAvailable)

  const config = useMemo(
    () => ({
      ...configInit,
      ...configTmp,
    }),
    [configInit, configTmp]
  )

  return {
    getConfigInfos,
    config,
    setTmpConfig,
    getThirdPartyServerStatus,
    thirdPartyAvailable,
  }
}

export const useResetConfig = () => {
  const dispatch = useAppDispatch()

  const establishmentsLength = useAppSelector((state) => state.appConfig.establishments).length

  const resetConfigTmp = useCallback(() => dispatch(ResetConfigTmp()), [dispatch])
  const resetAllConfig = useCallback(() => dispatch(ResetAllConfig()), [dispatch])

  const loadEstablishmentsList = useCallback(
    () => dispatch(requestEstablishmentsData()),
    [dispatch]
  )

  return { resetConfigTmp, resetAllConfig, establishmentsLength, loadEstablishmentsList }
}

export const useSetConfig = () => {
  const dispatch = useAppDispatch()
  const voteTmpConfig = useAppSelector((state) => state.configuration.voteTmp)
  const isCSE = useAppSelector((state) => state.configuration.type === 'CSE')

  const selectedEstablishment = useAppSelector((state) => state.configuration.selectedEstablishment)
  const establishments = useAppSelector((state) => state.appConfig.establishments)
  let isAllEstablishments = useMemo(() => {
    if (selectedEstablishment?.id === 'all') return establishments.map(({ id }) => id)
    return undefined
  }, [establishments, selectedEstablishment?.id])

  const config = useAppSelector((state) => state.configuration.configTmp)
  const configNotCSE = useAppSelector((state) => state.configuration.selectedEstablishment)
  const setConfigCSE = useCallback(
    (files) => {
      dispatch(requestPatchConfigInfos({ config }))
      dispatch(
        requestSetVoteInfos({
          voteTmpConfig,
          files,
          isAllEstablishments,
        })
      )
    },
    [config, dispatch, isAllEstablishments, voteTmpConfig]
  )

  const setConfig = useCallback(
    (_) => {
      dispatch(requestPatchConfigInfos({ config: configNotCSE }))
      dispatch(requestSetVoteInfos({}))
    },
    [configNotCSE, dispatch]
  )

  return { setConfig: isCSE ? setConfigCSE : setConfig }
}

export const useVoteConfig = () => {
  const dispatch = useAppDispatch()

  const voteConfigByEtablishement = useAppSelector(
    (state) => state.configuration.configByEstablishement
  )
  const voteTmpConfig = useAppSelector((state) => state.configuration.voteTmp)
  const establishments = useAppSelector((state) => state.appConfig.establishments)

  const getVoteConfig = useCallback(
    () => dispatch(requestVoteInfos({ establishments })),
    [establishments, dispatch]
  )

  const setVoteTmp = useCallback((config) => dispatch(SetVoteTmp({ config })), [dispatch])
  const selectedEstablishment = useAppSelector((state) => state.appConfig.selectedEstablishment)

  const resetVoteTmp = useCallback(() => dispatch(ResetVoteTmp()), [dispatch])

  return {
    voteConfigByEtablishement,
    voteTmpConfig,
    selectedEstablishment,
    setVoteTmp,
    getVoteConfig,
    resetVoteTmp,
  }
}

export const useEstablishmentDetail = (id) => {
  const defaultDetail = useAppSelector((state) => state.configuration?.configByEstablishement[0])

  const voteTmp = useAppSelector((state) => state.configuration.voteTmp?.[id])
  const establishmentDetail = useAppSelector((state) =>
    state.configuration.configByEstablishement?.find(
      ({ establishmentId }) => establishmentId === id
    )
  )
  const isError = useAppSelector((state) => state.configuration.isError)

  return { voteTmp, establishmentDetail, isError, defaultDetail }
}

export const useEstablishmentAllSelected = () => {
  const dispatch = useAppDispatch()

  const setSelectedEstablishment = useCallback(
    (id) => dispatch(SetSelectedEstablishment(id)),
    [dispatch]
  )

  return { setSelectedEstablishment }
}

export const useSetEstablishmentNotCSE = () => {
  const dispatch = useAppDispatch()

  const setEstablishmentNotCSE = useCallback(() => dispatch(SetEstablishmentNotCSE()), [dispatch])

  return { setEstablishmentNotCSE }
}

export const useSelectedEstablishment = () => {
  const dispatch = useAppDispatch()

  const setSelectedEstablishment = useCallback(
    (id) => dispatch(SetSelectedEstablishment(id)),
    [dispatch]
  )
  const selectedEstablishmentId = useAppSelector(
    (state) => state.configuration.selectedEstablishment?.id
  )

  const isError = useAppSelector((state) => state.configuration.isError)

  return { setSelectedEstablishment, isError, selectedEstablishmentId }
}

export const useGetCurrentEstablishment = () => {
  const dispatch = useAppDispatch()

  const selectedEstablishment = useAppSelector((state) => state.configuration.selectedEstablishment)
  const setVoteTmpById = useCallback(
    (config) => dispatch(SetVoteTmpById({ id: selectedEstablishment.id, config })),
    [dispatch, selectedEstablishment?.id]
  )
  const setIsError = useCallback((status) => dispatch(SetIsError(status)), [dispatch])

  return { selectedEstablishment, setVoteTmpById, setIsError }
}

export const useConfigHasChanged = () => {
  const config = useAppSelector((state) => state.configuration.config)
  const configTmp = useAppSelector((state) => state.configuration.configTmp)
  const voteTmp = useAppSelector((state) => state.configuration.voteTmp)
  const selectedEstablishment = useAppSelector((state) => state.configuration.selectedEstablishment)

  if (selectedEstablishment?.id === 'all' && Object.keys(voteTmp).includes('all')) return true
  else if (Object.keys(voteTmp).filter((key) => key !== 'all').length) return true
  return !!configTmp && Object.keys(configTmp).some((key) => configTmp[key] !== config[key])
}

export const useConfiguration = () => {
  const dispatch = useAppDispatch()
  const config = useAppSelector((state) => state.configuration.config)

  // Définir la fonction logout
  const logout = useCallback(() => {
    dispatch(authLogout())
  }, [dispatch])

  return {
    ...config,
    logout,
  }
}

export const useRedirectConfiguration = () => {
  const dispatch = useAppDispatch()
  const electorsRedirectedOnUniquePolls = useAppSelector(
    (state) => state.configuration.config?.electorsRedirectedOnUniquePolls
  )

  const setRedirectedToUniquePolls = useCallback((value) => {
    dispatch(SetRedirectedToUniquePolls(value))
  }, [])

  const redirectedToUniquePolls = useAppSelector(
    (state) => state.configuration?.redirectedToUniquePolls
  )

  return { electorsRedirectedOnUniquePolls, setRedirectedToUniquePolls, redirectedToUniquePolls }
}

export const useDisplayCandidatePicture = () => {
  const candidatePicturesDisplayed = useAppSelector(
    (state) => state.configuration?.config?.candidatePicturesDisplayed
  )

  return { candidatePicturesDisplayed }
}

export const useIsCSE = () => {
  const isCSE = useAppSelector((state) => state.configuration.type === 'CSE')
  return isCSE
}

export const useValidationParams = () => {
  const config = useAppSelector((state) => state.configuration.config)
  const userId = useAppSelector((state) => state.users.userId)
  const personalInformation = useAppSelector(
    (state) => state.users.userById[userId]?.personalInformation
  )
  const password = useAppSelector((state) => state.users.userById?.[userId]?.password)

  let modalType: string = 'none'
  if (config?.votesToBeConfirmedWithPersonalInformation && config?.votesToBeConfirmedWithPassword) {
    modalType = 'both'
  } else if (config?.votesToBeConfirmedWithPersonalInformation) {
    modalType = 'personalInfo'
  } else if (config?.votesToBeConfirmedWithPassword) {
    modalType = 'password'
  }

  return {
    modalType,
    personalInformation,
    password,
  }
}

export const useIdRules = () => {
  const dispatch = useAppDispatch()

  const getIdRules = useCallback(() => dispatch(requestIdRules()), [dispatch])
  const setIdRules = useCallback((params) => dispatch(requestSetIdRules(params)), [dispatch])

  const idRules = useAppSelector((state) => state.configuration.idRules)

  return { getIdRules, idRules, setIdRules }
}

export const use2ndRound = () => {
  const dispatch = useAppDispatch()
  const setConfigStatus = useCallback(() => dispatch(requestSetConfig()), [dispatch])

  const resetFor2ndRound = useCallback(() => dispatch(requestResetFor2ndRound()), [dispatch])

  const is2ndRound = useAppSelector(
    (state) => state.configuration.config?.currentRound === ELECTION_ROUND.second
  )
  const needs2ndRound = useAppSelector((state) => state.configuration.config?.secondRoundNeeded)

  return { is2ndRound, needs2ndRound, resetFor2ndRound, setConfigStatus }
}

export const useArchiveData = () => {
  const dispatch = useAppDispatch()

  const currentRound = useAppSelector((state) => state.configuration.config?.currentRound)

  const downloadArchive = useCallback(
    () => dispatch(requestDownloadArchive({ currentRound })),
    [currentRound, dispatch]
  )

  return { downloadArchive }
}

export const useSecondRoundActions = () => {
  const dispatch = useAppDispatch()
  const download2ndRound = use2ndRoundExport()

  const enable2round = () => {
    // Met à jour le statut dans le store Redux immédiatement
    dispatch(setDownload1RoundStatus())
    // Met à jour le statut dans le backend
    dispatch(requestSetDownload1Round())
    // Lance le téléchargement
    download2ndRound()
  }

  return {
    enable2round,
  }
}
