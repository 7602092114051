import { useCallback, useEffect } from 'react'
import { requestTheme, requestEditTheme, requestResetColors } from './services'
import { useAppDispatch, useAppSelector } from '../../init/store'

// Scrutins
export const useTheme = () => {
  const dispatch = useAppDispatch()
  const theme = useAppSelector((state) => state.theme)

  const getTheme = useCallback(() => dispatch(requestTheme()), [dispatch])
  const editTheme = useCallback((params) => dispatch(requestEditTheme(params)), [dispatch])
  const resetColors = useCallback(() => dispatch(requestResetColors()), [dispatch])

  useEffect(() => {
    getTheme()
  }, [getTheme])

  return { theme, editTheme, resetColors }
}
