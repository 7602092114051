export const USER_COLORS = [
  '#d55c8b',
  '#ed7291',
  '#fc92ac',
  '#ffb5bd',
  '#f2a9d6',
  '#d592e6',
  '#b77eed',
  '#9479f7',
  '#737cf6',
  '#618ff3',
  '#57a2ec',
  '#68bcf7',
  '#5dccec',
  '#64d6db',
  '#64dfb4',
  '#69cc91',
  '#78d974',
  '#a4e079',
  '#b4d35d',
  '#cce138',
  '#e9ec09',
  '#f5db50',
  '#ffb861',
  '#ff8a70',
  '#fd6e6e',
  '#e0dddd',
  '#d88787',
  '#ae8b89',
  '#e0c5b6',
]
