import { createAsyncThunk } from '@reduxjs/toolkit'
import jsonToString from '../../utils/lib/jsonToString'
import { getAxios, getTokenHeaders, setAxios } from '../../utils/lib/requestAxios'

// Users request by search or/and by roleIds
export const requestProxyUsers = createAsyncThunk<any, any>(
  'proxies/requestProxyUsers',
  async ({ search, params = {} }, { rejectWithValue }) => {
    const response: any = await getAxios(
      `/users?${jsonToString({ search, ...params })}`,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        data: response.data,
      }
    }
  }
)

export const requestProxyUsersStates = (builder) => {
  builder.addCase(requestProxyUsers.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestProxyUsers.fulfilled, (state, { payload }) => {
    const { users } = payload?.data || { users: [] }

    state.isLoading = false
    state.userIds = users?.map(({ id }) => id)
  })

  builder.addCase(requestProxyUsers.rejected, (state) => {
    state.isLoading = false
  })
}
export const requestProxiesAll = createAsyncThunk<any, any>(
  'proxies/requestProxiesAll',
  async ({ userId }, { rejectWithValue }) => {
    const response: any = await getAxios(`/res/vote-proxies?userId=${userId}`, getTokenHeaders())
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestProxiesAllStates = (builder) => {
  builder.addCase(requestProxiesAll.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestProxiesAll.fulfilled, (state, { payload }) => {
    state.isLoading = false
    state.proxiesAll = payload
  })

  builder.addCase(requestProxiesAll.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestPostProxies = createAsyncThunk<any, any>(
  'proxies/requestPostProxies',
  async (body, { rejectWithValue }) => {
    const response: any = await setAxios(`/res/vote-proxies/set-all`, body, getTokenHeaders())
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestPostProxiesStates = (builder) => {
  builder.addCase(requestPostProxies.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestPostProxies.fulfilled, (state, { payload }) => {
    state.isLoading = false
    state.proxiesAll = payload
  })

  builder.addCase(requestPostProxies.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestGetUsersBySelectedPolls = createAsyncThunk<any, any>(
  'proxies/requestGetUsersBySelectedPolls',
  async (
    { userId, selectedPollIds = [], search = null, excludedUsers = [], callback },
    { rejectWithValue }
  ) => {
    // Presence of callback means it's a verification of users presence in pollIds => return null
    if (!selectedPollIds.length) {
      if (callback) {
        callback([])
        return null
      } else return []
    }
    const params = {
      representativeId: userId,
      search: null,
      [callback ? 'includedUserIds' : 'excludedUserIds']: excludedUsers,
      pollIds: selectedPollIds,
      skip: 0,
      take: 10,
    }

    const response: any = await getAxios(
      `/res/poll-electors/proxiable?${jsonToString(params)}`,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      if (callback) {
        callback(response.data)
        return null
      } else return response.data
    }
  }
)

export const requestGetUsersBySelectedPollsStates = (builder) => {
  builder.addCase(requestGetUsersBySelectedPolls.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestGetUsersBySelectedPolls.fulfilled, (state, { payload }) => {
    if (payload === null) return
    state.isLoading = false
    state.availableProxiesElectors = payload
  })

  builder.addCase(requestGetUsersBySelectedPolls.rejected, (state) => {
    state.isLoading = false
  })
}
