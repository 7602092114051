import React from 'react'
import { IonSpinner } from '@ionic/react'
import './Loader.scss'

interface Props {
  fullscreen?: boolean
  style?: any
  className?: any
}

const Loader: React.FC<Props> = React.memo(({ fullscreen = false, style, className = '' }) => (
  <div style={style} className={`loaderBox ${className} ${fullscreen ? 'fullscreen' : ''}`}>
    <IonSpinner name='crescent' color='primary' />
  </div>
))

export default Loader
