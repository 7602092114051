import {
  deleteAxios,
  getAxios,
  getTokenHeaders,
  putAxios,
  setAxios,
} from '../../utils/lib/requestAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { downloadFile } from '../../utils/lib/downloadFile'
import { setAppLoading, setErrorStatus } from '../app/redux'
import { requestScrutins, requestScrutinsElections } from '../scrutins/services'

const FieldsPath = {
  res: '/res/minute-fields',
  uni: '/uni/minute-fields',
  list: '/lis/minute-fields',
  lis: '/lis/minute-fields',
}

const RulesPath = {
  res: '/res/calc-rules',
  uni: '/uni/calc-rules',
  list: '/lis/calc-rules',
  lis: '/lis/calc-rules',
}

// Request PV settings
export const requestPollRecord = createAsyncThunk<
  any,
  { pollId: string; pollType: 'list' | 'uni' | 'res' }
>('records/requestPollRecord', async ({ pollId, pollType }, { rejectWithValue, dispatch }) => {
  dispatch(setAppLoading(true))

  let presenceResponse: any = []
  const fieldPath = FieldsPath[pollType]
  if (pollType !== 'list') {
    presenceResponse = await getAxios(`${fieldPath}/${pollId}/PRESENCE/fields`, getTokenHeaders())
    if (presenceResponse.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus(presenceResponse.error.response.data))
      return rejectWithValue(presenceResponse.error.response.status)
    }
  }

  const resultatResponse: any = await getAxios(
    `${fieldPath}/${pollId}/RESULT/fields`,
    getTokenHeaders()
  )

  if (resultatResponse.error) {
    dispatch(setAppLoading(false))
    dispatch(setErrorStatus({ message: resultatResponse.error.response.message }))
    return rejectWithValue(resultatResponse.error.response.status)
  }

  const response: any = await getAxios(`${RulesPath[pollType]}?pollId=${pollId}`, getTokenHeaders())
  if (response.error) {
    dispatch(setAppLoading(false))
    dispatch(setErrorStatus({ message: response.error.response.message }))
    return rejectWithValue(response.error.response.status)
  }

  dispatch(setAppLoading(false))
  return {
    pollId,
    pollType,
    rules: response.data,
    presenceFields: presenceResponse.data,
    resultatFields: resultatResponse.data,
  }
})

export const requestPollRecordStates = (builder) => {
  builder.addCase(
    requestPollRecord.fulfilled,
    (state, { payload: { pollId, rules, presenceFields, pollType, resultatFields } }) => {
      state.rulesByPollId[pollId] = rules.map(({ id }) => (pollType !== 'res' ? pollId : id))

      state.usedResolutionIds[pollId] = rules.reduce((acc, item) => {
        if (!item.resolutionIds?.length) return acc
        item.resolutionIds.forEach((id) => {
          if (!acc.includes(id)) acc.push(id)
        })
        return acc
      }, state.usedResolutionIds[pollId] || [])

      state.rules = rules.reduce((acc, rule) => {
        acc[pollType !== 'res' ? pollId : rule.id] = rule
        return acc
      }, state.rules || {})

      state.presenceByPollId[pollId] = presenceFields
      state.resultatByPollId[pollId] = resultatFields
    }
  )
}

// Request Update / Create rule
export const requestUpdatePollRecord = createAsyncThunk<
  any,
  {
    pollId: string
    ruleId?: number | string
    pollType: 'list' | 'uni' | 'res'
    params: any
  }
>(
  'records/requestUpdatePollRecord',
  async ({ pollId, pollType, ruleId, params }, { rejectWithValue, dispatch }) => {
    let response: any = null
    dispatch(setAppLoading(true))
    const body = {
      ...params,
      pollId,
      ruleId: pollType !== 'res' ? undefined : ruleId,
      resolutionIds: pollType !== 'res' ? undefined : params.resolutionIds || [],
    }

    if (ruleId) {
      response = await putAxios(`${RulesPath[pollType]}/${ruleId}`, body, getTokenHeaders())
    } else {
      response = await setAxios(`${RulesPath[pollType]}`, body, getTokenHeaders())
    }

    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: response.error.response.message }))
      return rejectWithValue(response.error.response.status)
    }

    dispatch(setAppLoading(false))
    return { pollId, data: response.data }
  }
)

export const requestUpdatePollRecordStates = (builder) => {
  builder.addCase(requestUpdatePollRecord.fulfilled, (state, { payload: { pollId, data } }) => {
    const ruleId = data.id || pollId

    if (!state.rulesByPollId[pollId].includes(ruleId)) {
      state.rulesByPollId[pollId].push(ruleId)
    }

    data.resolutionIds?.forEach((id) => {
      if (!state.usedResolutionIds[pollId].includes(id)) {
        state.usedResolutionIds[pollId].push(id)
      }
    })

    state.rules[ruleId] = data
  })
}

// Request Update Fields
export const requestUpdateFields = createAsyncThunk<
  any,
  {
    pollId: string
    pollType: 'list' | 'uni' | 'res'
    minuteType: 'PRESENCE' | 'RESULT'
    fields: Array<any>
    leftFields: Array<any>
  }
>(
  'records/requestUpdateFields',
  async ({ pollId, pollType, minuteType, fields, leftFields }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const fieldPath = FieldsPath[pollType]

    const response: any = await setAxios(
      `${fieldPath}/${pollId}/${minuteType}/fields`,
      fields,
      getTokenHeaders()
    )

    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: response.error.response.message }))
      return rejectWithValue(response.error.response.status)
    }

    dispatch(setAppLoading(false))
    return { pollId, minuteType, fields: [...fields, ...leftFields] }
  }
)

const tmpFields = {}
export const requestUpdateFieldsStates = (builder) => {
  builder.addCase(requestUpdateFields.pending, (state, { meta: { arg } }) => {
    const { pollId, minuteType } = arg
    const key = minuteType === 'RESULT' ? 'resultatByPollId' : 'presenceByPollId'
    if (!tmpFields[pollId]) {
      tmpFields[pollId] = {}
    }
    tmpFields[pollId][key] = state[key][pollId]
  })

  builder.addCase(
    requestUpdateFields.fulfilled,
    (state, { payload: { pollId, minuteType, fields } }) => {
      const key = minuteType === 'RESULT' ? 'resultatByPollId' : 'presenceByPollId'
      if (tmpFields?.[pollId]?.[key]) tmpFields[pollId][key] = undefined
      state[key][pollId] = fields
    }
  )

  builder.addCase(requestUpdateFields.rejected, (state, { meta: { arg } }) => {
    const { pollId, minuteType } = arg
    const key = minuteType === 'RESULT' ? 'resultatByPollId' : 'presenceByPollId'
    if (tmpFields?.[pollId]?.[key]) state[key][pollId] = tmpFields[pollId][key]
  })
}

// Request Download PV
export const requestDownloadRecord = createAsyncThunk<
  any,
  { pollId: string; pvType?: 'RESULT' | 'PRESENCE' }
>(
  'records/requestDownloadRecord',
  async ({ pollId, pvType = 'RESULT' }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))

    const headers: any = {
      responseType: 'blob',
      ...getTokenHeaders(),
    }

    const response: any = await getAxios(`/poll-minutes/${pollId}/download/${pvType}`, headers)

    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus(response.error))
      return rejectWithValue(response.error.statusCode)
    }
    downloadFile(response)
    dispatch(setAppLoading(false))
  }
)

export const requestDownloadResults = createAsyncThunk<any, { pollId: string; type: string }>(
  'records/requestDownloadResult',
  async ({ pollId, type }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))

    const headers: any = {
      responseType: 'blob',
      ...getTokenHeaders(),
    }

    const response: any = await getAxios(`/${type}/exports/polls/${pollId}/leading-lists`, headers)

    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus(response.error))
      return rejectWithValue(response.error.statusCode)
    }
    downloadFile(response)
    dispatch(setAppLoading(false))
  }
)

// Request Validate PV
export const requestValidatePV = createAsyncThunk<
  any,
  { pollId: string; pvType?: 'RESULT' | 'PRESENCE'; isCSE?: boolean; canUseAdmin?: boolean }
>(
  'records/requestValidatePV',
  async ({ pollId, canUseAdmin, isCSE = true }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const response: any = await setAxios(
      `/poll-minutes/${pollId}/approve`,
      {},
      getTokenHeaders(true)
    )

    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: response.error.response.message }))
      return rejectWithValue(response.error.response.status)
    }
    dispatch(setAppLoading(false))

    if (isCSE) {
      dispatch(requestScrutinsElections({ canUseAdmin }))
    } else {
      dispatch(requestScrutins())
    }
  }
)

// Request Delete Rules
export const requestDeleteRuleRecord = createAsyncThunk<
  any,
  { pollId: string; pollType: 'list' | 'uni' | 'res'; ruleId: number | string }
>(
  'records/requestDeleteRuleRecord',
  async ({ pollId, pollType, ruleId }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))

    const response: any = await deleteAxios(`${RulesPath[pollType]}/${ruleId}`, getTokenHeaders())

    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: response.error.response.message }))
      return rejectWithValue(response.error.response.status)
    }

    dispatch(setAppLoading(false))
    return { ruleId, pollId }
  }
)

export const requestDeleteRuleRecordStates = (builder) => {
  builder.addCase(
    requestDeleteRuleRecord.fulfilled,
    (state, { payload: { pollId, ruleId, pollType } }) => {
      const deletedRule = state.rules[ruleId]
      state.rulesByPollId[pollId] = state.rulesByPollId[pollId].filter((id) => id !== ruleId)

      state.usedResolutionIds[pollId] = state.usedResolutionIds[pollId].filter(
        (id) => !deletedRule.resolutionIds.includes(id)
      )

      delete state.rules[ruleId]
    }
  )
}
