import CryptoJS from 'crypto-js'

const SECRET = 'Random value to be set'

export const encryptData = (value, secret = SECRET) =>
  CryptoJS.AES.encrypt(typeof value === 'object' ? JSON.stringify(value) : value, secret).toString()

export const decryptData = (token) => {
  const bytes = CryptoJS.AES.decrypt(token, SECRET)
  const data = bytes.toString(CryptoJS.enc.Utf8)

  try {
    // In case the value is an object
    return JSON.parse(data)
  } catch (err) {
    return data
  }
}
