import { useCallback, useEffect /*, useMemo*/ } from 'react'
import { useAppDispatch, useAppSelector } from '../../init/store'
import { requestEstablishments } from './services'
import { use2ndRound } from '../configuration/hooks'

export const useEstablishments = () => {
  const dispatch = useAppDispatch()
  const establishmentIds = useAppSelector((state) => state.establishments.establishmentIds)
  const isCSE = useAppSelector((state) => state.configuration.type === 'CSE')
  const { is2ndRound } = use2ndRound()
  const isVoteDataImported = useAppSelector((state) => state.appConfig.status?.VOTE_DATA_IMPORTED)
  const establishmentsById = useAppSelector((state) => state.establishments.establishmentById)

  // const getEstablishments = useCallback(() => {
  //   if (isVoteDataImported) {
  //     dispatch(requestEstablishments({ type: isCSE ? 'cse' : 'lis' }))
  //   }
  // }, [dispatch, isCSE, isVoteDataImported])

  const getEstablishments = useCallback(() => {
    if ((isVoteDataImported || is2ndRound) && !establishmentIds.length) {
      dispatch(requestEstablishments({ type: isCSE ? 'cse' : 'lis' }))
    }
  }, [dispatch, isCSE, isVoteDataImported, is2ndRound, establishmentIds.length])

  useEffect(() => {
    if (isVoteDataImported || is2ndRound) {
      getEstablishments()
    }
  }, [getEstablishments, isVoteDataImported, is2ndRound])

  return { establishmentsById, establishmentIds, getEstablishments }
}
