import { createSlice } from '@reduxjs/toolkit'
import { requestAnimatorsState } from './services'

// Define a type for the slice state
export type AnimatorState = {
  userIds: string[]
  usersById: any
}

// Define the initial state using that type
const initialState: AnimatorState = {
  userIds: [],
  usersById: {},
}

export const animators = createSlice({
  name: 'animators',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestAnimatorsState(builder)
  },
})
