const getFirstLetters = (user) => {
  if (!user) return ''
  if (user?.firstName && user?.lastName) {
    return `${user?.firstName[0]}${user?.lastName[0]}`
  }
  if (user?.firstName) {
    return `${user?.firstName[0]}${user?.firstName[1]}`
  }
  if (user?.lastName) {
    return `${user?.lastName[0]}${user?.lastName[1]}`
  }
  return `${user?.username[0]}${user?.username[1]}`
}

export default getFirstLetters
