import { IonModal } from '@ionic/react'

// Hooks
import useLocalize from '../../../locales/hook'
import { useIdle } from '../../../redux/app/hooks'

// Components
import Button from '../../../atoms/Button/Button'

// Styles
import './IdleModal.scss'

const IdleModal: React.FC = () => {
  const t = useLocalize()
  const { isIdle, isIdleActive, wasIdle, wasExpired, onCancelIdle, onLogout } = useIdle()

  return (
    <>
      {!wasExpired && !!wasIdle && <div id='WasLoggedOut'>{t('wasIdle_message')}</div>}
      {!!wasExpired && <div id='WasLoggedOut'>{t('tokenExpired_message')}</div>}
      <IonModal id='IdleModal' isOpen={isIdleActive} onWillDismiss={onCancelIdle}>
        <div className='title'>{t('logout_inactivity')}</div>
        <div className='message'>{t('logout_inactivity_message', { timer: isIdle })}</div>
        <div className='buttonsBox'>
          <Button label={t('cancel')} fill onClick={onCancelIdle} />
          <Button label={t('logout_now')} onClick={onLogout} />
        </div>
      </IonModal>
    </>
  )
}

export default IdleModal
