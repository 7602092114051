const jsonToString = (obj = {}) => {
  const toRet = Object.keys(obj).map((key) => {
    if (!!obj[key] || obj[key]?.length || obj[key] === 0 || obj[key] === '' || obj[key] === false) {
      if (obj[key] instanceof Array) {
        return obj[key]
          .map((value: any, index: number) =>
            value !== undefined ? `${key}[${index}]=${value}` : null
          )
          .filter((_) => _)
          .join('&')
      } else if (obj[key] instanceof Object) {
        return `${key}=${JSON.stringify(obj[key]).trim()}`
      } else {
        return `${key}=${obj[key]}`
      }
    }
    return ''
  })
  return toRet.filter((_) => _?.length).join('&')
}

export default jsonToString
