import { createAsyncThunk } from '@reduxjs/toolkit'
import { ADD_CANDIDATE_TO_LIST } from '../../../../redux/modals/constants'
import { getAxios, getTokenHeaders, patchAxios, setAxios } from '../../../../utils/lib/requestAxios'

export const requestAddCandidateToList = createAsyncThunk<
  any,
  {
    listId: number
    description: string | undefined
    profilePictureFile: File | null
    userId: string
    presentationFile: File | null
    videoFile: File | null
    syndicate: string | undefined
  }
>(
  'lists/requestAddCandidateToList',
  async (
    { listId, description, profilePictureFile, syndicate, userId, presentationFile, videoFile },
    { rejectWithValue }
  ) => {
    const body = new FormData()
    body.append('description', description?.trim() || '')
    body.append('syndicate', syndicate?.trim() || '')
    body.append('userId', userId)
    if (profilePictureFile) {
      body.append('image', profilePictureFile)
    }
    if (presentationFile) {
      body.append('pdf', presentationFile)
    }
    if (videoFile) {
      body.append('video', videoFile)
    }
    const response: any = await setAxios(
      `/lists/${listId}/add-candidate_with-media`,
      body,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    return {
      data: response.data,
    }
  }
)

export const addCandidateInAList = (builder) => {
  builder.addCase(requestAddCandidateToList.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestAddCandidateToList.fulfilled, (state, { payload }) => {
    state.isLoading = false
    if (payload.data) {
      const { candidates } = payload.data
      state.candidatesById = {
        ...state.candidatesById,
        ...candidates.reduce((acc, candidate) => {
          acc[candidate.id] = candidate

          return acc
        }, {}),
      }
    }
  })

  builder.addCase(requestAddCandidateToList.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestAddCandidateToListsState = (builder) => {
  builder.addCase(requestAddCandidateToList.fulfilled, (state, { payload }) => {
    if (payload.data) {
      const list = payload.data
      state.listsById = {
        ...state.listsById,
        [list.id]: {
          ...payload.data,
          candidates: undefined,
          candidateIds: list.candidates.sort((a, b) => a.position - b.position).map(({ id }) => id),
        },
      }
    }
  })
}

export const requestAddCandidateToListsModalVersionState = (builder) => {
  builder.addCase(requestAddCandidateToList.fulfilled, (state, { payload }) => {
    state[ADD_CANDIDATE_TO_LIST].isOpen = false
    state[ADD_CANDIDATE_TO_LIST].context = {}
  })

  builder.addCase(requestAddCandidateToList.rejected, (state) => {
    // TODO: handle error, maybe we wanna display a message in or out the modal
    state[ADD_CANDIDATE_TO_LIST].isOpen = false
    state[ADD_CANDIDATE_TO_LIST].context = {}
    // state.isLoading = false
  })
}

export const requestEligibleCandidateToList = createAsyncThunk<
  any,
  {
    listId: number
  }
>('lists/requestEligibleCandidateToList', async ({ listId }, { rejectWithValue }) => {
  const response: any = await getAxios(`/candidates/eligible?listId=${listId}`, getTokenHeaders())

  if (response.error) {
    return rejectWithValue(response.error.response.status)
  }
  return {
    data: response.data,
  }
})

export const requestEligibleCandidateToListState = (builder) => {
  builder.addCase(requestEligibleCandidateToList.pending, (_state) => {})

  builder.addCase(requestEligibleCandidateToList.fulfilled, (state, { payload }) => {
    state[ADD_CANDIDATE_TO_LIST].params.eligibleCandidates = payload.data
  })

  builder.addCase(requestEligibleCandidateToList.rejected, (_state) => {})
}

export const requestEditCandidate = createAsyncThunk<
  any,
  {
    candidate: any
    candidateId: number
    description: string | undefined
    syndicate: string | undefined
    profilePictureFile: File | null
    profilePictureUrl: string | null
    userId: string
    presentationFile: File | null
    presentationUrl: string | null
    videoFile: File | null
    videoUrl: string | null
  }
>(
  'candidate/requestEditCandidate',
  async (
    {
      candidate,
      candidateId,
      description,
      syndicate,
      profilePictureFile,
      presentationFile,
      videoFile,
      profilePictureUrl,
      presentationUrl,
      videoUrl,
    },
    { rejectWithValue }
  ) => {
    const body = new FormData()
    const NULL: any = null
    body.append('description', description?.trim() || '')
    body.append('syndicate', syndicate?.trim() || '')

    // Image Check
    if (profilePictureFile) {
      body.append('image', profilePictureFile)
    } else if (!profilePictureUrl) {
      body.append('profilePictureUrl', NULL)
    }

    // PDF Check
    if (presentationFile) {
      body.append('pdf', presentationFile)
    } else if (!presentationUrl) {
      body.append('presentationUrl', NULL)
    }

    // Video Check
    if (videoFile) {
      body.append('video', videoFile)
    } else if (!videoUrl) {
      body.append('videoUrl', NULL)
    }

    const response: any = await patchAxios(
      `/candidates/${candidateId}/with-media`,
      body,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    return {
      data: response.data,
    }
  }
)

export const editCandidate = (builder) => {
  builder.addCase(requestEditCandidate.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestEditCandidate.fulfilled, (state, { payload }) => {
    state.isLoading = false
    if (payload.data) {
      const candidate = payload.data
      state.candidatesById = {
        ...state.candidatesById,
        [candidate.id]: candidate,
      }
    }
  })

  builder.addCase(requestEditCandidate.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestEditCandidateModalVersion = (builder) => {
  builder.addCase(requestEditCandidate.fulfilled, (state, { payload }) => {
    state[ADD_CANDIDATE_TO_LIST].isOpen = false
    state[ADD_CANDIDATE_TO_LIST].context = {}
  })

  builder.addCase(requestEditCandidate.rejected, (state) => {
    // TODO: handle error, maybe we wanna display a message in or out the modal
    state[ADD_CANDIDATE_TO_LIST].isOpen = false
    state[ADD_CANDIDATE_TO_LIST].context = {}
  })
}
