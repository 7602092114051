export const MAIN_ROLES = [
  'ADMIN',
  'SUPERVISOR',
  'POLLING_STATION_MEMBER',
  'ELECTOR',
  'UNION_MEMBER',
  'DIRECTION',
  'ANIMATOR',
  'HOTLINE',
]

export const CORRESPONDANCE_ROLES = {
  admin: 'ADMIN',
  pollMember: 'POLLING_STATION_MEMBER',
  elector: 'ELECTOR',
  unionMember: 'UNION_MEMBER',
  direction: 'DIRECTION',
  animator: 'ANIMATOR',
  hotline: 'HOTLINE',
  supervisor: 'SUPERVISOR',
}

export const INV_CORRESPONDANCE_ROLES = {
  ADMIN: 'admin',
  POLLING_STATION_MEMBER: 'pollMember',
  ELECTOR: 'elector',
  UNION_MEMBER: 'unionMember',
  DIRECTION: 'direction',
  ANIMATOR: 'animator',
  HOTLINE: 'hotline',
  SUPERVISOR: 'supervisor',
}

export const DEFAULT_ROLE = CORRESPONDANCE_ROLES.elector

/*export const LINK_ROLE = {
  ADMIN: '/admin',
  SUPERVISOR: '/superviseur',
  POLLING_STATION_MEMBER: '/membre_du_bureau',
  ELECTOR: '/electeur',
  UNION_MEMBER: '/membre_union',
  DIRECTION: '/direction',
  ANIMATOR: '/animateur',
  HOTLINE: '/hotline',
}

export const RESERVED_LINKS = Object.values(LINK_ROLE)*/
