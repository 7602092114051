import { createAsyncThunk } from '@reduxjs/toolkit'
import { getTokenHeaders, putAxios } from '../../utils/lib/requestAxios'

export const requestManageEstablishment = createAsyncThunk<any, any>(
  'establishments/requestManageEstablishment',
  async ({ params, establishmentId, callback, isCSE }, { dispatch, rejectWithValue }) => {
    // Establishment Params case
    if (params.name) {
      params.name = params.name.trim()
    }

    if (params.socialReason) {
      params.socialReason = params.socialReason.trim()
    }

    if (params.address1) {
      params.address1 = params.address1.trim()
    }

    if (params.address2) {
      params.address2 = params.address2.trim()
    }

    if (params.postalCode) {
      params.postalCode = params.postalCode.trim()
    }

    if (params.city) {
      params.city = params.city.trim()
    }

    if (params.siret) {
      params.siret = params.siret.trim()
    }

    if (params.previousSiret) {
      params.previousSiret = params.previousSiret.trim()
    }

    if (params.position) {
      params.position = parseInt(params.position)
    }

    if (params.categoricalUnions) {
      params.categoricalUnions = params.categoricalUnions.trim()
    }

    if (params.logoUrl) {
      params.logoUrl = params.logoUrl.trim()
    }

    if (params.byElection) {
      params.byElection = !!params.byElection
    }

    if (params.timezone) {
      params.timezone = params.timezone.trim()
    }

    if (params.secondarySirets) {
      params.secondarySirets = params.secondarySirets.trim()
    }

    if (params.previousPollDate) {
      params.previousPollDate = new Date(params.previousPollDate.trim())
    }

    if (params.contactLastName) {
      params.contactLastName = params.contactLastName.trim()
    }

    if (params.contactFirstName) {
      params.contactFirstName = params.contactFirstName.trim()
    }

    if (params.contactPosition) {
      params.contactPosition = params.contactPosition.trim()
    }

    if (params.contactPhone) {
      params.contactPhone = params.contactPhone.trim()
    }

    if (params.contactFax) {
      params.contactFax = params.contactFax.trim()
    }

    if (params.contactEmail) {
      params.contactEmail = params.contactEmail.trim()
    }

    const body: any = Object.keys(params).reduce((acc, key) => {
      if (
        (!params[key] && params[key] !== false) ||
        (typeof params[key] === 'string' && !params[key].length)
      ) {
        acc[key] = null
      } else {
        acc[key] = params[key]
      }
      return acc
    }, {})

    // Create Establishment
    let response: any = null
    if (!establishmentId) {
      if (isCSE) {
        response = await putAxios('/establishments', body, getTokenHeaders())
      } else {
        response = await putAxios('/lis/establishments', body, getTokenHeaders())
      }
    } else {
      if (isCSE) {
        response = await putAxios(`/establishments/${establishmentId}`, body, getTokenHeaders())
      } else {
        response = await putAxios(`/lis/establishments/${establishmentId}`, body, getTokenHeaders())
      }
    }

    if (response.error) {
      if (response.error?.response?.data?.message) {
        return { error: response.error.response.data.message }
      }
      return rejectWithValue(response.error.response.status)
    } else {
      callback && callback(establishmentId, response.data)
      return response.data
    }
  }
)

export const requestManageEstablishmentStates = (builder) => {
  builder.addCase(requestManageEstablishment.pending, (state) => {
    state.error = null
    state.isLoading = true
  })

  builder.addCase(requestManageEstablishment.fulfilled, (state, { payload }) => {
    state.isLoading = false
    if (!payload.error) {
      state.success = true
    } else {
      state.error = payload.error
    }
  })

  builder.addCase(requestManageEstablishment.rejected, (state, { payload }) => {
    state.isLoading = false
    if (payload.error) {
      state.error = payload.error
    }
  })
}
