import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useLocalize from '../../../locales/hook'
import { useIsSealed } from '../../../redux/sealing/hooks'
import { use2ndRound } from '../../../redux/configuration/hooks'
import { useAuthCheck, usePrivileges } from '../../../redux/auth/hooks'
import { useIsImported, usePlatformStatus } from '../../../redux/appConfig/hooks'

// Components
import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonPage,
  useIonRouter,
} from '@ionic/react'
import { Side } from '@ionic/core'
import Icon from '../../../atoms/Icon/Icon'

// Style & Config
import './ToolBar.scss'
import ROUTES from '../../constants/routes'
import IconTypes from '../../../utils/types/IconTypes'
import toolbarConfig, { ToolbarConfigType } from './config'
import { useIsCSE } from '../../../redux/configuration/hooks'

// Interfaces
interface Props {
  side: Side
}

const ToolbarItem: React.FC<ToolbarConfigType> = ({
  name,
  path,
  children,
  icon,
  isDisabled = false,
  enabled,
  isChild = false,
}) => {
  // Ref
  const dpRef = useRef<any | null>(null)

  // Hooks
  const t = useLocalize()
  const {
    push,
    routeInfo: { pathname },
  } = useIonRouter()

  // Memos
  const isChildrenSelected = useMemo(
    () => children?.some(({ path }) => pathname === path) || false,
    [children, pathname]
  )

  const isSelected = useMemo(() => {
    if (isChildrenSelected && dpRef.current) {
      dpRef.current.style.height = 'auto'
    }
    return pathname.includes(path + '')
  }, [isChildrenSelected, path, pathname])

  // States
  const [display, setDisplay] = useState(false)
  const [status, setStatus] = useState<boolean>(isChildrenSelected)

  // Effects
  useEffect(() => {
    setDisplay(false)
    const timeoutTimer = setTimeout(() => setDisplay(true), 0)

    return () => {
      if (timeoutTimer) clearTimeout(timeoutTimer)
    }
  }, [isDisabled])

  useEffect(() => {
    if (isChildrenSelected && dpRef.current) {
      dpRef.current.style.height = 'auto'
    }
  }, [isChildrenSelected])

  // Handlers
  const handleClick = useCallback(() => {
    if (isDisabled) return
    if (path) {
      push(path)
    } else if (children) {
      if (dpRef.current) {
        dpRef.current.style.height = status ? '0' : 'auto'
      }
      setStatus(!status)
    }
  }, [isDisabled, path, children, push, status])

  if (!enabled || !display) return null
  return (
    <>
      <IonItem
        className={`${isDisabled ? ' disabled' : ''} ${status ? 'parentOpen' : ''} ${
          isSelected ? ' selected' : ''
        } ${status || isChildrenSelected ? 'parentOpen' : ''}`}
        lines='none'
        detail={false}
        onClick={handleClick}
      >
        {!!isChild && <div className='childDot' />}
        {!!icon && <Icon icon={icon} />}
        <IonLabel>{t(name)}</IonLabel>
        {!!children && <Icon icon={!status ? IconTypes.CHEVRON_DOWN : IconTypes.CHEVRON_UP} />}
      </IonItem>
      {!!children && (
        <div className='dpItems' ref={dpRef}>
          {children.map((item) => (
            <ToolbarItem {...item} key={item.name} isChild />
          ))}
        </div>
      )}
    </>
  )
}

const ToolBar: React.FC<Props> = ({ side }) => {
  const t = useLocalize()
  const isSealed = useIsSealed()
  const {
    routeInfo: { pathname },
  } = useIonRouter()
  const isCSE = useIsCSE()
  const privileges = usePrivileges()
  const { is2ndRound } = use2ndRound()
  const { isImported } = useIsImported()
  const { isLogged } = useAuthCheck()
  const { ENCRYPTION_KEYS_GENERATED, COUNT_DONE } = usePlatformStatus()

  const isImportedValue = useMemo(() => isImported || is2ndRound, [is2ndRound, isImported])

  const tabConfig = useMemo(
    () =>
      toolbarConfig({
        isSealed,
        generatedKeys: ENCRYPTION_KEYS_GENERATED,
        privileges,
        isCSE,
        countDone: COUNT_DONE,
      }).filter((item) => {
        if (item.enabled === false) {
          return false
        }
        return true
      }),
    [isSealed, ENCRYPTION_KEYS_GENERATED, isCSE, COUNT_DONE, privileges]
  )

  if (!isLogged || pathname === ROUTES.DEMO_LOGIN || !tabConfig?.length) {
    return null
  }

  return (
    <IonMenu type='overlay' side={side} menuId='MenuToolbar' contentId='main' id='MenuToolbar'>
      <IonPage>
        <IonContent scrollY={false}>
          <IonItem id='CompanyLogo' lines='none' detail={false}>
            <IonLabel>{t('logo')}</IonLabel>
          </IonItem>
          <IonList>
            {tabConfig.map((item) => (
              <ToolbarItem
                {...item}
                key={item.name}
                isDisabled={!isImportedValue || item.isDisabled}
              />
            ))}
          </IonList>
        </IonContent>
      </IonPage>
    </IonMenu>
  )
}

export default ToolBar
