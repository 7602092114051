import { useCallback, useMemo } from 'react'
import useLocalize from '../../../locales/hook'
import { useUninominalCandidate } from '../../../redux/uninominal/hooks'
import { useDisplayCandidatePicture } from '../../../redux/configuration/hooks'

import Icon from '../../../atoms/Icon/Icon'
import CheckBox from '../../../atoms/CheckBox/CheckBox'
import UserDefaultPicture from '../../../components/UserDefaultPicture/UserDefaultPicture'

// Utils & Style
import './VoteElectionCandidate.scss'
import { WHITE_VOTE } from '../VoteElections'
import IconTypes from '../../../utils/types/IconTypes'
import getFullName from '../../../utils/lib/getFullName'

const VoteElectionCandidate: React.FC<{
  id: number
  selected: boolean
  disabled?: boolean
  isPreview?: boolean
  locked: boolean
  onClick: (id: number) => void
}> = ({ id, selected, isPreview, locked, disabled, onClick }) => {
  const isWhiteVote = useMemo(() => id === WHITE_VOTE, [id])

  const t = useLocalize()
  const candidate = useUninominalCandidate(id)
  const { candidatePicturesDisplayed } = useDisplayCandidatePicture()

  const { user, presentationUrl, profilePictureUrl, videoUrl, description } = candidate || {}

  // Memos
  const pageClassName = useMemo(() => {
    const classes: string[] = []
    if (isWhiteVote) classes.push('whiteVote')
    if (locked) classes.push('locked')
    if (disabled && !locked) classes.push('disabled')
    if (selected) classes.push('selected')
    if (isPreview) classes.push('isPreview')
    return classes.join(' ')
  }, [disabled, isWhiteVote, locked, isPreview, selected])

  const isAvailable = useMemo(
    () => (!disabled || selected) && !locked && !isPreview,
    [disabled, locked, selected, isPreview]
  )

  // Handlers
  const handleClick = useCallback(() => isAvailable && onClick(id), [isAvailable, onClick, id])
  const handleFile = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()
      window.open(presentationUrl, '_blank')
    },
    [presentationUrl]
  )
  const handleVideo = useCallback(
    (ev) => {
      ev.preventDefault()
      ev.stopPropagation()
      window.open(videoUrl, '_blank')
    },
    [videoUrl]
  )

  return (
    <div id='VoteElectionCandidate' className={pageClassName} onClick={handleClick}>
      {!locked && !isPreview && <CheckBox selected={selected} />}
      {locked && !selected && !isPreview && <div className='checked' />}
      {locked && selected && !isPreview && (
        <Icon icon={IconTypes.CHECK_CIRCLE} className='checked' />
      )}
      {!isWhiteVote && (
        <>
          <UserDefaultPicture
            userId={user?.id}
            className='candidatePhoto'
            src={profilePictureUrl}
            candidatePicturesDisplayed={candidatePicturesDisplayed}
          />
          <div className='info'>
            <div className='name'>{getFullName(user)}</div>
            {!!description && <div className='description'>{description}</div>}
          </div>
          <div className='rightSide'>
            {!!presentationUrl && <Icon icon={IconTypes.FILE_TEXT} onClick={handleFile} />}
            {!!videoUrl && <Icon icon={IconTypes.VIDEO} onClick={handleVideo} />}
          </div>
        </>
      )}
      {isWhiteVote && (
        <div className='info'>
          <div className='name'>{t('white_vote')}</div>
        </div>
      )}
    </div>
  )
}

export default VoteElectionCandidate
