import { createSlice } from '@reduxjs/toolkit'
import {
  requestGetUserVoteRightsStates,
  requestManageUserStates,
  requestSetUserVoteRightsStates,
} from './services'

// Services

// Utils
export interface VoteProxy {
  representativeId: string
  representative: {
    id: string
    firstName: string
    lastName: string
  }
}

export interface VoteRights {
  id?: number
  userId?: string
  pollId: string
  shares: number
  voteProxies?: VoteProxy[]
}

// Define a type for the slice state
export type UserState = {
  success?: boolean
  error?: any
  voteRights?: VoteRights[]
}

// Define the initial state using that type
const initialState: UserState = {
  voteRights: [],
}

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setSuccess: (state, { payload: value }) => {
      state.success = value
    },
    setError: (state, { payload: value }) => {
      state.error = value
    },
  },
  extraReducers: (builder) => {
    requestManageUserStates(builder)
    requestGetUserVoteRightsStates(builder)
    requestSetUserVoteRightsStates(builder)
  },
})

export const { setSuccess, setError } = user?.actions
