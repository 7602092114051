import { createAsyncThunk } from '@reduxjs/toolkit'
import { ADD_CANDIDATE_TO_UNI } from '../../../../redux/modals/constants'
import { getAxios, getTokenHeaders, patchAxios, setAxios } from '../../../../utils/lib/requestAxios'

// Adding Candidate
export const requestAddCandidateToUniPoll = createAsyncThunk<
  any,
  {
    pollId: number
    description: string | undefined
    profilePictureFile: File | null
    userId: string
    presentationFile: File | null
    videoFile: File | null
  }
>(
  'uninominal/requestAddCandidateToUniPoll',
  async (
    { pollId, description, profilePictureFile, userId, presentationFile, videoFile },
    { rejectWithValue }
  ) => {
    const body = new FormData()
    body.append('description', description?.trim() || '')
    body.append('userId', userId)
    if (profilePictureFile) {
      body.append('image', profilePictureFile)
    }
    if (presentationFile) {
      body.append('pdf', presentationFile)
    }
    if (videoFile) {
      body.append('video', videoFile)
    }
    const response: any = await setAxios(
      `/uni/polls/${pollId}/add-candidate_with-media`,
      body,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    return {
      pollId,
      data: response.data,
    }
  }
)

export const requestAddCandidateToUniPollStates = (builder) => {
  builder.addCase(requestAddCandidateToUniPoll.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(
    requestAddCandidateToUniPoll.fulfilled,
    (state, { payload: { data, pollId } }) => {
      state.isLoading = false
      const { candidates } = data
      if (!state.candidatesByUniId[pollId]) {
        state.candidatesByUniId[pollId] = []
      }
      candidates.forEach((item) => {
        if (!state.candidatesByUniId[pollId].includes(item.id)) {
          state.candidatesByUniId[pollId].push(item.id)
        }
        state.candidatesById[item.id] = item
      })
    }
  )

  builder.addCase(requestAddCandidateToUniPoll.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestAddCandidateToUniPollModalVersionState = (builder) => {
  builder.addCase(requestAddCandidateToUniPoll.fulfilled, (state, { payload }) => {
    state[ADD_CANDIDATE_TO_UNI].isOpen = false
    state[ADD_CANDIDATE_TO_UNI].context = {}
  })

  builder.addCase(requestAddCandidateToUniPoll.rejected, (state) => {
    state[ADD_CANDIDATE_TO_UNI].isOpen = false
    state[ADD_CANDIDATE_TO_UNI].context = {}
  })
}

// List of Candidates
export const requestEligibleCandidateToUniPoll = createAsyncThunk<
  any,
  {
    pollId: string
  }
>('uninominal/requestEligibleCandidateToUniPoll', async ({ pollId }, { rejectWithValue }) => {
  const response: any = await getAxios(
    `/uni/candidates/eligible?pollId=${pollId}`,
    getTokenHeaders()
  )

  if (response.error) {
    return rejectWithValue(response.error.response.status)
  }
  return {
    data: response.data,
  }
})

export const requestEligibleCandidateToUniPollState = (builder) => {
  builder.addCase(requestEligibleCandidateToUniPoll.pending, (_state) => {})

  builder.addCase(requestEligibleCandidateToUniPoll.fulfilled, (state, { payload }) => {
    state[ADD_CANDIDATE_TO_UNI].params.eligibleCandidates = payload.data
  })

  builder.addCase(requestEligibleCandidateToUniPoll.rejected, (_state) => {})
}

export const requestEditCandidate = createAsyncThunk<
  any,
  {
    candidateId: number
    description: string | undefined
    profilePictureFile: File | null
    profilePictureUrl: string | null
    userId: string
    presentationFile: File | null
    presentationUrl: string | null
    videoFile: File | null
    videoUrl: string | null
  }
>(
  'uninominal/requestEditCandidate',
  async (
    {
      candidateId,
      description,
      profilePictureFile,
      presentationFile,
      videoFile,
      profilePictureUrl,
      presentationUrl,
      videoUrl,
    },
    { rejectWithValue }
  ) => {
    const body = new FormData()
    const NULL: any = null
    body.append('description', description?.trim() || '')

    // Image Check
    if (profilePictureFile) {
      body.append('image', profilePictureFile)
    } else if (!profilePictureUrl) {
      body.append('profilePictureUrl', NULL)
    }

    // PDF Check
    if (presentationFile) {
      body.append('pdf', presentationFile)
    } else if (!presentationUrl) {
      body.append('presentationUrl', NULL)
    }

    // Video Check
    if (videoFile) {
      body.append('video', videoFile)
    } else if (!videoUrl) {
      body.append('videoUrl', NULL)
    }

    const response: any = await patchAxios(
      `/uni/candidates/${candidateId}/with-media`,
      body,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    return {
      data: response.data,
    }
  }
)

export const requestEditCandidateState = (builder) => {
  builder.addCase(requestEditCandidate.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestEditCandidate.fulfilled, (state, { payload }) => {
    state.isLoading = false
    if (payload.data) {
      const candidate = payload.data
      state.candidatesById = {
        ...state.candidatesById,
        [candidate.id]: candidate,
      }
    }
  })

  builder.addCase(requestEditCandidate.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestEditUniCandidateModalVersion = (builder) => {
  builder.addCase(requestEditCandidate.fulfilled, (state, { payload }) => {
    state[ADD_CANDIDATE_TO_UNI].isOpen = false
    state[ADD_CANDIDATE_TO_UNI].context = {}
  })

  builder.addCase(requestEditCandidate.rejected, (state) => {
    // TODO: handle error, maybe we wanna display a message in or out the modal
    state[ADD_CANDIDATE_TO_UNI].isOpen = false
    state[ADD_CANDIDATE_TO_UNI].context = {}
  })
}
