import { createSlice } from '@reduxjs/toolkit'

// // Services
import {
  requestAllResolutionBallotStates,
  requestPollResolutionStates,
  requestVoteMultiResolutionsStates,
  requestVoteResolutionStates,
} from './services'

// Utils

// Define a type for the slice state
export type ResolutionsState = {
  pollsById: { [key: string]: Array<number> }
  resolutionById: { [key: string]: any }
  ballots: { [key: number]: any }
  pollsBallots: { [key: string]: number[] }
}

// Define the initial state using that type
const initialState: ResolutionsState = {
  pollsById: {},
  resolutionById: {},
  ballots: {},
  pollsBallots: {},
}

export const userResolutions = createSlice({
  name: 'userResolutions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestPollResolutionStates(builder)
    requestVoteResolutionStates(builder)
    requestAllResolutionBallotStates(builder)
    requestVoteMultiResolutionsStates(builder)
  },
})
