import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  deleteAxios,
  getAxios,
  getTokenHeaders,
  putAxios,
  setAxios,
} from '../../utils/lib/requestAxios'

export const requestPgpKeys = createAsyncThunk<undefined, undefined>(
  'pgpKeys/requestPgpKeys',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/external-sender-encryption-keys', getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response)
    } else {
      return response.data
    }
  }
)

export const requestPgpKeysStates = (builder) => {
  builder.addCase(requestPgpKeys.fulfilled, (state, { payload: keys }) => {
    state.keys = keys
  })
}

export const requestPostPgpKey = createAsyncThunk<any, { txtFile: File; callback?: Function }>(
  'pgpKeys/requestPostPgpKeys',
  async ({ txtFile, callback }, { rejectWithValue }) => {
    const body = new FormData()

    // Txt Check
    if (txtFile) {
      body.append('text', txtFile)
    }

    const response: any = await setAxios(
      `/external-sender-encryption-keys`,
      body,
      getTokenHeaders()
    )

    if (response.error) {
      if (response.error.response.status === 403) {
        callback && callback(response.error.response.data.message)
      }
      return rejectWithValue(response.error.response)
    } else {
      return response.data
    }
  }
)

export const requestPostPgpKeyStates = (builder) => {
  builder.addCase(requestPostPgpKey.pending, (state, { meta }) => {
    state.isLoading = true
    state.file = meta.arg.txtFile
  })

  builder.addCase(requestPostPgpKey.fulfilled, (state, { payload: key }) => {
    state.isLoading = false
    state.keys = [...state.keys, key]
    state.file = null
  })

  builder.addCase(requestPostPgpKey.rejected, (state, payload) => {
    state.isLoading = false
    state.file = null
  })
}

export const requestRemovePgpKey = createAsyncThunk<any, { keyId: number; callback: Function }>(
  'pgpKeys/requestRemovePgpKeys',
  async ({ keyId, callback }, { rejectWithValue }) => {
    const response: any = await deleteAxios(
      `/external-sender-encryption-keys/${keyId}`,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response)
    } else {
      callback && callback(keyId)
      return
    }
  }
)

export const requestRemovePgpKeyStates = (builder) => {}

export const requestSetExternalSenders = createAsyncThunk<any, { data: any }>(
  'pgpKeys/setExternalSenders',
  async ({ data }, { rejectWithValue }) => {
    const responseId: any = await putAxios(
      `/external-senders/FIRST_IDENTIFIER_OR_PASSWORD`,
      data.id,
      getTokenHeaders()
    )
    const responsePwd: any = await putAxios(
      `/external-senders/PASSPORT_RESET`,
      data.pwd,
      getTokenHeaders()
    )

    if (responseId.error) {
      return rejectWithValue(responseId.error.response)
    } else if (responsePwd.error) {
      return rejectWithValue(responsePwd.error.response)
    } else {
      return {
        id: { email: responseId.data.email, encryptionKeyId: responseId.data.encryptionKeyId },
        pwd: {
          email: responsePwd.data.email,
          encryptionKeyId: responsePwd.data.encryptionKeyId,
        },
      }
    }
  }
)

export const requestSetExternalSendersStates = (builder) => {
  builder.addCase(requestSetExternalSenders.fulfilled, (state, { payload }) => {
    state.externalSenders = payload
  })
}

export const requestGetExternalSenders = createAsyncThunk<any, undefined>(
  'pgpKeys/getExternalSenders',
  async (_, { rejectWithValue }) => {
    const responseId: any = await getAxios(
      `/external-senders/FIRST_IDENTIFIER_OR_PASSWORD`,
      getTokenHeaders()
    )
    const responsePwd: any = await getAxios(`/external-senders/PASSPORT_RESET`, getTokenHeaders())

    if (responseId.error) {
      return rejectWithValue(responseId.error.response)
    } else if (responsePwd.error) {
      return rejectWithValue(responsePwd.error.response)
    } else {
      return {
        id: { email: responseId.data.email, encryptionKeyId: responseId.data.encryptionKeyId },
        pwd: {
          email: responsePwd.data.email,
          encryptionKeyId: responsePwd.data.encryptionKeyId,
        },
      }
    }
  }
)

export const requestGetExternalSendersStates = (builder) => {
  builder.addCase(requestGetExternalSenders.fulfilled, (state, { payload }) => {
    state.externalSenders = payload
  })
}
