type DesignType = {
  [key: string]: string
}

const setDesignVariables = (design: DesignType) => {
  try {
    const elem: any | null = document.querySelector(':root')
    if (!elem) return

    const datas = Object.entries(design)
    datas.forEach(([key, value]) => {
      elem.style.setProperty(key.startsWith('--ion') ? key : `--ion-color-${key}`, value)
    })
  } catch (err) {
    console.warn('Problème aves les données de design:', design)
  }
}

export const getDesignVariable = (color: string) => {
  if (!color) return ''

  const colorToSearch = color.startsWith('--ion') ? color : `--ion-color-${color}`
  try {
    const elem: any | null = document.querySelector(':root')
    if (!elem) return ''
    return getComputedStyle(elem).getPropertyValue(colorToSearch).trim()
  } catch (err) {
    return ''
  }
}

export default setDesignVariables
