import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios, getTokenHeaders } from '../../utils/lib/requestAxios'

export const requestColleges = createAsyncThunk<undefined, undefined>(
  'users/requestColleges',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/colleges', getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestCollegesStates = (builder) => {
  builder.addCase(requestColleges.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestColleges.fulfilled, (state, { payload: colleges }) => {
    state.isLoading = false
    state.colleges = colleges
  })

  builder.addCase(requestColleges.rejected, (state, payload) => {
    state.isLoading = false
  })
}
