import { createSlice } from '@reduxjs/toolkit'

const pEnv = window['env']?.REACT_APP_API_URL ? window['env'] : process.env

// Define a type for the slice state
export type AuthState = {
  env: 'DEV' | 'PREPROD' | 'PROD'
  appStarted: boolean
  isLoading?: boolean
  percentage?: null | number
  currentTime?: number
  errorState?: {
    title?: string
    message?: string
    status?: number
  }
}

// Define the initial state using that type
const initialState: AuthState = {
  env: pEnv?.REACT_APP_ENV || 'PROD',
  appStarted: false,
}

export const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppStarted: (state, { payload: value }) => {
      state.appStarted = value
    },
    setAppLoading: (state, { payload: value }) => {
      state.isLoading = value
    },
    setAppLoadingPercentage: (state, { payload: value }) => {
      state.percentage = value
    },
    setErrorStatus: (state, { payload: value }) => {
      state.errorState = value || undefined
    },
  },
})

export const { setAppStarted, setAppLoading, setAppLoadingPercentage, setErrorStatus } = app.actions
