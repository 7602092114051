import { createSlice } from '@reduxjs/toolkit'
import { requestVoteSectorsStates } from './services'

// Services

// Define a type for the slice state
export type VoteState = {
  voteStatus: {
    openingDate: any
    closingDate: any
    openingDateSimulation: any
    closingDateSimulation: any
  }
  voteSectors: string[]
}

// Define the initial state using that type
const initialState: VoteState = {
  voteStatus: {
    openingDate: null,
    closingDate: null,
    openingDateSimulation: null,
    closingDateSimulation: null,
  },
  voteSectors: [],
}

export const vote = createSlice({
  name: 'vote',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestVoteSectorsStates(builder)
  },
})
