// Hooks
import { IonIcon } from '@ionic/react'
import React, { useEffect, useMemo, useRef } from 'react'

// Style
import './Icon.scss'
import IconTypes from '../../utils/types/IconTypes'

const myIcons = Object.values(IconTypes)

interface IonIconProps {
  id?: string
  ariaLabel?: string
  color?: string
  flipRtl?: boolean
  icon?: string
  ios?: string
  lazy?: boolean
  md?: string
  mode?: 'ios' | 'md'
  name?: string
  size?: string
  src?: string
  style?: any
  onClick?: Function
  className?: string
  disabled?: boolean
  onMouseDown?: Function
  onPointerDown?: Function
  ref?: any
}

// Renderer
const Icon: React.FC<IonIconProps> = (props: any) => {
  const contentRef: any = useRef(null)

  const myProps = useMemo(
    () => ({
      ...props,
      onMouseDown: props.disabled ? undefined : props.onMouseDown,
      onPointerDown: props.disabled ? undefined : props.onPointerDown,
      onClick: props.disabled ? undefined : props.onClick,
      className: `${props.className || ''} ${props.disabled ? 'disabled' : 'enabled'}`,
    }),
    [props]
  )

  useEffect(() => {
    setTimeout(() => {
      contentRef.current?.shadowRoot?.querySelector('title')?.remove()
    }, 1000)
  }, [])

  if (myIcons.includes(myProps.icon)) {
    return (
      <IonIcon
        ref={contentRef}
        icon={undefined}
        src={`/assets/icons/${myProps.icon}.svg`}
        {...myProps}
      />
    )
  }

  return <IonIcon ref={contentRef} {...myProps} />
}

export default Icon
