import { setAppLoading, setErrorStatus } from '../app/redux'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { requestGetConfigInfos } from '../configuration/services'
import { getAxios, getTokenHeaders, setAxios } from '../../utils/lib/requestAxios'
import { downloadFile } from '../../utils/lib/downloadFile'

// Start Vote (normal vote or Simulation)
export const requestStartVote = createAsyncThunk<any, { isDemo: boolean }>(
  'vote/requestStartVote',
  async ({ isDemo }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const path = isDemo ? '/simulation/start-vote' : ''
    const response: any = await setAxios(path, {}, getTokenHeaders())

    if (response.error) {
      dispatch(setAppLoading(false))
      return rejectWithValue(response.data)
    }
    setTimeout(() => dispatch(requestGetConfigInfos()), 500)
    return {
      isDemo,
      data: response.data,
    }
  }
)

// Stop Vote (normal vote or Simulation)
export const requestStopVote = createAsyncThunk<any, { isDemo: boolean }>(
  'vote/requestStopVote',
  async ({ isDemo }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const path = isDemo ? '/simulation/stop-vote' : ''
    const response: any = await setAxios(path, {}, getTokenHeaders())

    if (response.error) {
      dispatch(setAppLoading(false))
      return rejectWithValue(response.data)
    }
    setTimeout(() => dispatch(requestGetConfigInfos()), 500)
    return {
      isDemo,
      data: response.data,
    }
  }
)

// Request Vote Sectors
export const requestVoteSectors = createAsyncThunk<any, undefined>(
  'vote/requestVoteSectors',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/vote-sectors', getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)

export const requestVoteSectorsStates = (builder) => {
  builder.addCase(requestVoteSectors.fulfilled, (state, { payload }) => {
    state.voteSectors = payload || []
  })
}

export const requestDownloadRepresentativeness = createAsyncThunk<any, undefined>(
  'vote/requestVoteSectors',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))

    const headers: any = {
      responseType: 'blob',
      ...getTokenHeaders(),
    }

    const response: any = await getAxios('/cse/r16s/download', headers)
    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus(response.error))
      return rejectWithValue(response.error.statusCode)
    }
    downloadFile(response)
    dispatch(setAppLoading(false))
  }
)
