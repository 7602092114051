export const ALPHA_REGEX = '^[A-Za-zÀ-ÿ? ?-]+$'

export const isOnlyText = (name: string) => {
  let regExp = new RegExp(ALPHA_REGEX)

  if (!regExp.test(name)) {
    return false
  }
  return true
}
