// Hooks
import React, { useCallback, useEffect } from 'react'
import useLocalize from '../../locales/hook'
import { usePollHelpFiles } from '../../redux/helpFiles/hooks'

// Components
import Icon from '../../atoms/Icon/Icon'
import Tooltip from '../../atoms/Tooltip/Tooltip'

// Style & Utils
import './HelpItem.scss'
import IconTypes from '../../utils/types/IconTypes'

interface HelpItemProps {
  pollId: string | number
}

const HelpItem: React.FC<HelpItemProps> = ({ pollId }) => {
  const t = useLocalize()
  const { pdfFile, videoFile, getPollHelpFiles } = usePollHelpFiles(pollId)

  useEffect(() => {
    getPollHelpFiles()
  }, [getPollHelpFiles])

  const handleClickHelpPdf = useCallback(() => {
    window.open(pdfFile, '_blank')
  }, [pdfFile])

  const handleClickHelpVideo = useCallback(() => {
    window.open(videoFile, '_blank')
  }, [videoFile])

  return (
    <div id='HelpItem'>
      {(!!pdfFile || !!videoFile) && (
        <div className='help'>
          <div>{t('need_help')}</div>
          {!!pdfFile && (
            <Tooltip label={t('scrutin_help_file')} horizontal='left' vertical='bottom'>
              <Icon icon={IconTypes.HELP_CIRCLE} onClick={handleClickHelpPdf} />
            </Tooltip>
          )}
          {!!videoFile && (
            <Tooltip label={t('scrutin_help_video')} horizontal='left' vertical='bottom'>
              <Icon icon={IconTypes.PLAY_CIRCLE} onClick={handleClickHelpVideo} />
            </Tooltip>
          )}
        </div>
      )}
    </div>
  )
}

export default React.memo(HelpItem)
