import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  deleteAxios,
  getAxios,
  getTokenHeaders,
  patchAxios,
  setAxios,
} from '../../utils/lib/requestAxios'
import { setErrorStatus } from '../app/redux'

export const requestEditResolutionPoll = createAsyncThunk<
  any,
  {
    pollId: string
    params: any
  }
>(
  'resolutions/requestEditResolutionPoll',
  async ({ pollId, params }, { rejectWithValue, dispatch }) => {
    const response: any = await patchAxios(`/res/polls/${pollId}`, params, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return { data: response.data, pollId }
    }
  }
)

export const requestEditResolutionPollStates = (builder) => {
  // Used At scrutins/redux
  builder.addCase(requestEditResolutionPoll.fulfilled, (state, { payload: { pollId, data } }) => {
    state.scrutinsById[pollId] = {
      ...(state.scrutinsById[pollId] || {}),
      ...(data || {}),
    }
  })
}

export const requestPollResolution = createAsyncThunk<
  any,
  {
    pollId: string
  }
>('resolutions/requestPollResolution', async ({ pollId }, { rejectWithValue }) => {
  const response: any = await getAxios(`/res/resolutions?pollId=${pollId}`, getTokenHeaders())
  if (response.error) {
    return rejectWithValue(response.error.response.status)
  } else {
    return { data: response.data, pollId }
  }
})

export const requestPollResolutionStates = (builder) => {
  builder.addCase(requestPollResolution.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestPollResolution.fulfilled, (state, { payload }) => {
    state.isLoading = false
    state.resolutionByPollId[payload.pollId] = []
    payload.data?.forEach((item) => {
      if (!state.resolutionByPollId[payload.pollId].includes(item.id)) {
        state.resolutionByPollId[payload.pollId].push(item.id)
      }
      state.resolutionById[item.id] = item
      try {
        state.resolutionById[item.id].description = JSON.parse(item.description)
      } catch (errr) {}
    })
  })

  builder.addCase(requestPollResolution.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestEditResolution = createAsyncThunk<
  any,
  {
    resId?: number | boolean
    params: any
    pollId: string
  }
>(
  'resolutions/requestEditResolution',
  async ({ resId, params, pollId }, { rejectWithValue, dispatch }) => {
    let response: any = undefined
    let headers: any = getTokenHeaders()

    const bodyFormData = new FormData()

    bodyFormData.append('pollId', pollId)
    bodyFormData.append('label', params.label)
    try {
      bodyFormData.append(
        'description',
        params.description ? JSON.stringify(params.description) : ''
      )
    } catch (err) {
      console.error('Stringifying Description:', err)
    }

    if (params.documentUrl) {
      bodyFormData.append(
        typeof params.documentUrl === 'string' ? 'documentUrl' : 'pdf',
        params.documentUrl
      )
    }
    if (params.position) {
      bodyFormData.append('position', params.position)
    }

    headers.headers = {
      ...headers.headers,
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
      'Access-Control-Allow-Origin': '*',
    }

    if (resId) {
      response = await patchAxios(`/res/resolutions/${resId}`, bodyFormData, headers)
    } else {
      response = await setAxios('/res/resolutions', bodyFormData, headers)
    }

    if (response.error) {
      dispatch(
        setErrorStatus({
          message: response.error.response.data.message,
        })
      )
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        pollId,
        data: response.data,
      }
    }
  }
)

export const requestEditResolutionStates = (builder) => {
  builder.addCase(requestEditResolution.fulfilled, (state, { payload }) => {
    state.resolutionByPollId[payload.pollId] = []
    payload.data?.forEach((item) => {
      if (!state.resolutionByPollId[payload.pollId].includes(item.id)) {
        state.resolutionByPollId[payload.pollId].push(item.id)
      }
      state.resolutionById[item.id] = item
      try {
        state.resolutionById[item.id].description = JSON.parse(item.description)
      } catch (errr) {}
    })
  })
}

export const requestReorderResolutions = createAsyncThunk<
  any,
  {
    pollId: string
    orderedResolutionIds: Array<number>
  }
>(
  'resolutions/requestReorderResolutions',
  async ({ pollId, orderedResolutionIds }, { rejectWithValue }) => {
    const response: any = await setAxios(
      `/res/polls/${pollId}/resolution-positions`,
      { orderedResolutionIds },
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        pollId,
        data: response.data,
      }
    }
  }
)

export const requestReorderResolutionsStates = (builder) => {
  builder.addCase(requestReorderResolutions.pending, (state, { meta: { arg } }) => {
    state.resolutionByPollId[arg.pollId] = arg.orderedResolutionIds
  })
  builder.addCase(requestReorderResolutions.fulfilled, (state, { meta: { arg } }) => {
    arg.orderedResolutionIds.forEach((id, index) => {
      state.resolutionById[id].position = index + 1
    })
  })
}

export const requestDeleteResolution = createAsyncThunk<
  undefined,
  {
    pollId: string
    resId: number
  }
>('resolutions/requestDeleteResolution', async ({ resId }, { rejectWithValue }) => {
  const response: any = await deleteAxios(`/res/resolutions/${resId}`, getTokenHeaders())
  if (response.error) {
    return rejectWithValue(response.error.response.status)
  } else {
    return response.data
  }
})

export const requestDeleteResolutionStates = (builder) => {
  builder.addCase(requestDeleteResolution.pending, (state, { meta: { arg } }) => {
    state.resolutionByPollId[arg.pollId] = state.resolutionByPollId[arg.pollId].filter(
      (id) => id !== arg.resId
    )
  })
}
