import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios, getTokenHeaders } from '../../utils/lib/requestAxios'
import { requestScrutin } from '../scrutins/services'
import { requestEditUniPoll } from '../uninominal/services'
import { requestEditListPoll } from '../listPolls/services'
import { requestEditResolutionPoll, requestPollResolution } from '../resolutions/services'

// Request my polls
export const requestMePolls = createAsyncThunk<unknown, undefined>(
  'scrutins/requestMePolls',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/polls/me', getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        data: response.data,
      }
    }
  }
)

export const requestMePollsStates = (builder) => {
  builder.addCase(requestMePolls.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestMePolls.fulfilled, (state, { payload }) => {
    const { data } = payload
    state.isLoading = false

    data?.forEach((poll) => {
      if (!state.pollIds.includes(poll.id)) {
        state.pollIds.push(poll.id)
      }
      state.pollById[poll.id] = poll
    })
  })

  builder.addCase(requestMePolls.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestScrutinPollsStates = (builder) => {
  builder.addCase(requestScrutin.fulfilled, (state, { payload }) => {
    if (payload.data) {
      if (payload.data.pollInfo) {
        state.pollById[payload.data.pollInfo.id] = {
          ...(state.pollById[payload.data.pollInfo.id] || {}),
          ...payload.data.pollInfo,
          aggregatedResolutions: payload.data.aggregatedResolutions,
          proxies: payload.proxies,
        }
      } else {
        state.pollById[payload.data.id] = {
          ...(state.pollById[payload.data.id] || {}),
          ...payload.data,
        }
      }
    }
  })
}

export const requestMePollResolutionStates = (builder) => {
  builder.addCase(requestPollResolution.fulfilled, (state, { payload }) => {
    if (payload.data) {
      if (payload.data.pollInfo) {
        state.pollById[payload.data.pollInfo.id] = {
          ...(state.pollById[payload.data.pollInfo.id] || {}),
          ...payload.data.pollInfo,
          aggregatedResolutions: payload.data.aggregatedResolutions,
          proxies: payload.proxies,
        }
      } else if (payload.data.id) {
        state.pollById[payload.data.id] = {
          ...(state.pollById[payload.data.id] || {}),
          ...payload.data,
        }
      } else if (payload.data.pollId) {
        state.pollById[payload.data.pollId] = {
          ...(state.pollById[payload.data.pollId] || {}),
          ...payload.data,
        }
      } else if (payload.pollId) {
        state.pollById[payload.pollId] = {
          ...(state.pollById[payload.pollId] || {}),
          ...payload,
        }
      }
    }
  })
}

export const requestEditResolutionPollStates = (builder) => {
  builder.addCase(requestEditResolutionPoll.fulfilled, (state, { payload: { pollId, data } }) => {
    state.pollById[pollId] = {
      ...(state.pollById[pollId] || {}),
      ...(data || {}),
    }
  })
}

export const requestEditMeListPollStates = (builder) => {
  builder.addCase(requestEditListPoll.fulfilled, (state, { payload: { pollId, data } }) => {
    state.pollById[pollId] = {
      ...(state.pollById[pollId] || {}),
      ...(data || {}),
    }
  })
}

export const requestEditMeUniPollStates = (builder) => {
  builder.addCase(requestEditUniPoll.fulfilled, (state, { payload: { pollId, data } }) => {
    state.pollById[pollId] = {
      ...(state.pollById[pollId] || {}),
      ...(data || {}),
    }
  })
}
