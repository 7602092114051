import { IonHeader, IonModal } from '@ionic/react'
import { useCallback } from 'react'
import useLocalize from '../../locales/hook'

import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import './LogoutModal.scss'
import IconTypes from '../../utils/types/IconTypes'
import { useAllowQuit, useIdle } from '../../redux/app/hooks'
import useAppLogout from '../../redux/app/logoutHook'
import Image from '../Image/Image'

interface Props {
  visible: boolean
  setVisible: (value: boolean) => void
}

const LogoutModal: React.FC<Props> = ({ visible, setVisible }) => {
  const t = useLocalize()
  const appLogout = useAppLogout()
  const { sendMessage } = useIdle()
  const { shouldDisplayQuitModal } = useAllowQuit()

  const handleCloseModal = useCallback(
    (e) => {
      e.stopPropagation()
      setVisible(false)
    },
    [setVisible]
  )

  const confirmLogout = useCallback(() => {
    sendMessage('reload')
    setTimeout(appLogout, 100)
  }, [appLogout, sendMessage])

  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation()
      confirmLogout()
      setVisible(false)
    },
    [confirmLogout, setVisible]
  )

  if (!shouldDisplayQuitModal) {
    return (
      <IonModal id='LogoutModal' isOpen={visible} onWillDismiss={handleCloseModal}>
        <div className='title'>{t('logout_confirm_title')}</div>
        <div className='message'>{t('logout_confirm_message')}</div>
        <div className='buttonsBox'>
          <Button label={t('cancel')} fill onClick={handleCloseModal} />
          <Button label={t('confirm')} onClick={handleConfirm} />
        </div>
      </IonModal>
    )
  }

  return (
    <IonModal className='LogoutModal' isOpen={visible} onWillDismiss={handleCloseModal}>
      <IonHeader class='ion-no-border' className='logoutHeader'>
        <div className='title'>{t('confirm_logout_modal_title')}</div>
        <Icon className='icon' icon={IconTypes.X} onClick={handleCloseModal} />
      </IonHeader>
      <div className='logoutModalContentContainer'>
        <div className='message'>
          <div className='iconSide'>
            <Icon icon={IconTypes.INFO} />
          </div>
          <div className='info'>
            <div className='desc'>{t('confirm_logout_modal_desc')}</div>
          </div>
        </div>
        <Image src='confirm-logout' />
      </div>
      <div className='buttonsBox'>
        <Button label={t('cancel')} nothing onClick={handleCloseModal} />
        <Button label={t('confirm_logout_button')} danger onClick={handleConfirm} />
      </div>
    </IonModal>
  )
}

export default LogoutModal
