import { createSlice } from '@reduxjs/toolkit'
import {
  requestStartDemoStates,
  requestModesState,
  requestRandomRoleStates,
  requestSelectUserStates,
  requestStopDemoStates,
  requestUsersStates,
  requestCheckUsersStates,
} from './services'

// Define a type for the slice state
export type DemoState = {
  isDemo: boolean
  currentUserId: number | null
  userById: { [userId: string | number]: any }
  selectedUsers: { [roleId: string | number]: Array<number> }
  userIds: Array<number | string>
  userIdsByRole: { [roleId: number | string]: Array<number | string> }
  userIdsByRoleSelection: { [roleId: number | string]: Array<number | string> }
  isLoading: boolean
}

// Define the initial state using that type
export const initialState: DemoState = {
  isDemo: false,
  userById: {},
  selectedUsers: {},
  userIds: [],
  userIdsByRole: {},
  currentUserId: null,
  userIdsByRoleSelection: {},
  isLoading: false,
}

export const demo = createSlice({
  name: 'demo',
  initialState,
  reducers: {
    // Users Management
    setUserId: (state, { payload: id }) => {
      state.currentUserId = id
    },
    setUserIds: (state, { payload: ids }) => {
      console.info(ids)
    },
    setOverrideUsers: (state, { payload: data }) => {
      state.userIdsByRoleSelection = data || {}
    },
  },
  extraReducers: (builder) => {
    requestModesState(builder)
    requestUsersStates(builder)
    requestStartDemoStates(builder)
    requestRandomRoleStates(builder)
    requestSelectUserStates(builder)
    requestStopDemoStates(builder)
    requestCheckUsersStates(builder)
  },
})

export const { setUserId, setUserIds, setOverrideUsers } = demo.actions
