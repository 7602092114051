import { createAsyncThunk } from '@reduxjs/toolkit'
import { requestScrutin } from '../scrutins/services'
import { deleteAxios, getTokenHeaders, patchAxios, setAxios } from '../../utils/lib/requestAxios'

export const requestEditListPoll = createAsyncThunk<
  any,
  {
    pollId: string
    params: any
  }
>('listPolls/requestEditListPoll', async ({ pollId, params }, { rejectWithValue, dispatch }) => {
  const response: any = await patchAxios(`/lis/polls/${pollId}`, params, getTokenHeaders())
  if (response.error) {
    return rejectWithValue(response.error.response.status)
  } else {
    return { data: response.data, pollId }
  }
})

export const requestEditListPollStates = (builder) => {
  // Used At scrutins/redux
  builder.addCase(requestEditListPoll.fulfilled, (state, { payload: { pollId, data } }) => {
    state.scrutinsById[pollId] = {
      ...(state.scrutinsById[pollId] || {}),
      ...(data || {}),
    }
  })
}

// Create or Change List
export const requestUpdatePollList = createAsyncThunk<
  any,
  { listId?: number; scrutinId?: string; info: any }
>('listPolls/requestUpdatePollList', async ({ listId, scrutinId, info }) => {
  const body = new FormData()
  body.append('title', info.title)
  const NULL: any = null

  if (!(info.video instanceof String)) {
    if (info.video === null) {
      body.append('videoUrl', NULL)
    } else {
      body.append('video', info.video)
    }
  }
  if (!(info.professionOfFaith instanceof String)) {
    if (info.professionOfFaith === null) {
      body.append('professionOfFaithUrl', NULL)
    } else {
      body.append('pdf', info.professionOfFaith)
    }
  }
  if (!(info.logo instanceof String)) {
    if (info.logo === null) {
      body.append('logoUrl', NULL)
    } else {
      body.append('image', info.logo)
    }
  }

  let response: any
  if (!listId) {
    response = await setAxios(
      `/lis/polls/${scrutinId}/add-list_with-media`,
      body,
      getTokenHeaders()
    )
  } else {
    response = await patchAxios(`/lis/lists/${listId}/with-media`, body, getTokenHeaders())
  }

  return {
    data: {
      id: listId,
      pollId: scrutinId,
      ...response.data,
    },
  }
})

export const requestUpdatePollListStates = (builder) => {
  builder.addCase(requestUpdatePollList.fulfilled, (state, { payload: { data } }) => {
    if (data) {
      const { id, pollId, candidates } = data
      if (!state.listsByPollId[pollId]) {
        state.listsByPollId[pollId] = [id]
      } else if (!state.listsByPollId[pollId].includes(id)) {
        state.listsByPollId[pollId] = [...state.listsByPollId[pollId], id]
      }
      state.listsById[id] = {
        ...(state.listsById[id] || {}),
        ...data,
        candidates: undefined,
        candidateIds: candidates?.map(({ id }) => id) || state.listsById[id]?.candidateIds,
      }
      candidates?.forEach((candidate) => {
        state.candidatesById[candidate.id] = {
          ...(state.candidatesById[candidate.id] || {}),
          ...candidate,
        }
      })
      state.listsByPollId[pollId] = state.listsByPollId[pollId]?.sort(
        (a, b) => state.listsById[a]?.position - state.listsById[b]?.position
      )
    }
  })
}

// Builder for requested list
export const requestListScrutinStates = (builder) => {
  builder.addCase(requestScrutin.fulfilled, (state, { payload: { type, data } }) => {
    if (type === 'list' || type === 'lis') {
      state.listsByPollId[data.id] = data.lists.map((list) => {
        state.listsById[list.id] = {
          ...(state.listsById[list.id] || {}),
          ...list,
          candidates: undefined,
        }
        state.listsById[list.id].candidateIds = list.candidates.map((candidate) => {
          state.candidatesById[candidate.id] = {
            ...(state.candidatesById[candidate.id] || {}),
            ...candidate,
          }
          return candidate.id
        })
        return list.id
      })
    }
  })
}

// Delete list from poll
export const requestDeleteListFromPoll = createAsyncThunk<
  any,
  {
    pollId: string
    listId: number
  }
>('listPolls/requestDeleteListFromPoll', async ({ pollId, listId }, { rejectWithValue }) => {
  const response: any = await deleteAxios(`/lis/lists/${listId}`, getTokenHeaders())
  if (response.error) {
    return rejectWithValue(response.error.response.status)
  } else {
    return { listId, pollId }
  }
})

export const requestDeleteListFromPollStates = (builder) => {
  builder.addCase(requestDeleteListFromPoll.fulfilled, (state, { payload: { pollId, listId } }) => {
    state.listsByPollId[pollId] = state.listsByPollId[pollId].filter((id) => id !== listId)
  })
}

// Reorder the lists from poll
export const requestListReorder = createAsyncThunk<
  any,
  { orderedListIds: number[]; pollId: string }
>('listPolls/requestListReorder', async ({ orderedListIds, pollId }, { rejectWithValue }) => {
  const response: any = await setAxios(
    `/lis/polls/${pollId}/list-positions`,
    { orderedListIds },
    getTokenHeaders()
  )

  if (response.error) {
    return rejectWithValue(response.error.response.status)
  }
  return {
    data: {
      orderedListIds,
      pollId,
    },
  }
})

export const requestListReorderStates = (builder) => {
  builder.addCase(
    requestListReorder.fulfilled,
    (
      state,
      {
        payload: {
          data: { pollId, orderedListIds },
        },
      }
    ) => {
      state.listsByPollId[pollId] = orderedListIds
    }
  )
}
