import { IonHeader, IonModal } from '@ionic/react'
import { useCallback, useRef } from 'react'
import useLocalize from '../../locales/hook'

import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import './WarningModal.scss'
import IconTypes from '../../utils/types/IconTypes'
import Image from '../Image/Image'

const uniqId = require('shortid')

interface Message {
  title?: string
  description?: string
  paragraphs?: string[]
  descriptionType?: string
}

interface Props {
  visible: boolean
  setVisible: (value: boolean) => void
  onConfirm?: () => void
  title?: string
  message: Message
  buttonCommit?: string
  buttonCancel?: string
  icon?: string
  hideCancel?: boolean
  hideConfirm?: boolean
  className?: string
}

const WarningModal: React.FC<Props> = ({
  visible,
  setVisible,
  onConfirm,
  title,
  message,
  buttonCommit = 'confirm',
  buttonCancel = 'modal_cancel_button',
  className = '',
  icon,
  hideCancel = false,
  hideConfirm = false,
}) => {
  const t = useLocalize()
  const id = useRef('modal_' + uniqId.generate())

  const handleCloseModal = useCallback(
    (e) => {
      e.stopPropagation()
      setVisible(false)
    },
    [setVisible]
  )

  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation()
      onConfirm && onConfirm()
      setVisible(false)
    },
    [onConfirm, setVisible]
  )

  return (
    <IonModal
      id={id.current}
      className={`WarningModal ${className}`}
      isOpen={visible}
      onWillDismiss={handleCloseModal}
    >
      <IonHeader class='ion-no-border' className='warningHeader'>
        <div className='title'>{title}</div>
        <Icon className='icon' icon={IconTypes.X} onClick={handleCloseModal} />
      </IonHeader>
      <div className='warningContent'>
        <Image src='warning' />
        {!!message.title?.length && <div className='title'>{message.title}</div>}
        {!!message.description?.length && (
          <div className={`message ${message.descriptionType || ''}`}>{message.description}</div>
        )}
        {!!message.paragraphs?.length &&
          message.paragraphs.map((paragraph, index) => (
            <div className='paragraph' key={index}>{`•    ${paragraph}`}</div>
          ))}
      </div>
      <div className='buttonsBox'>
        {!hideCancel && <Button label={t(buttonCancel)} nothing onClick={handleCloseModal} />}
        {!hideConfirm && (
          <Button label={t(buttonCommit)} danger onClick={handleConfirm} icon={icon} />
        )}
      </div>
    </IonModal>
  )
}

export default WarningModal
