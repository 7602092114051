import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios, getTokenHeaders } from '../../utils/lib/requestAxios'

// Users request by search or/and by roleIds
export const requestProfile = createAsyncThunk<undefined, undefined>(
  'auth/requestProfile',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios(`/users/profile`, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestProfileStates = (builder) => {
  builder.addCase(requestProfile.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestProfile.fulfilled, (state, { payload }) => {
    state.userId = payload.id
    state.username = payload.login
    state.isLoading = false
  })

  builder.addCase(requestProfile.rejected, (state, payload) => {
    state.isLoading = false
  })
}
