import { setSessionStorage } from '../security/localStorage'

const cacheKey = 'RG8gbm90IGRlY29kZSB0aGUgdmFsdWU='
const cacheValue =
  'TmV2ZXIgZ29ubmEgZ2l2ZSB5b3UgdXANCk5ldmVyIGdvbm5hIGxldCB5b3UgZG93bg0KTmV2ZXIgZ29ubmEgcnVuIGFyb3VuZCBhbmQgZGVzZXJ0IHlvdQ0KTmV2ZXIgZ29ubmEgbWFrZSB5b3UgY3J5DQpOZXZlciBnb25uYSBzYXkgZ29vZGJ5ZQ0KTmV2ZXIgZ29ubmEgdGVsbCBhIGxpZSBhbmQgaHVydCB5b3U='

const clearCache = () => {
  setSessionStorage(cacheKey, cacheValue, false)
  if ('caches' in window) {
    caches.keys().then((names) => {
      // Delete all the cache files
      for (let i = 0; i < names?.length; i++) {
        caches.delete(names[i])
      }
    })
  }
}

export default clearCache
