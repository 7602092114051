import { createSlice } from '@reduxjs/toolkit'

// // Services
import {
  requestDeleteResolutionStates,
  requestEditResolutionStates,
  requestPollResolutionStates,
  requestReorderResolutionsStates,
} from './services'

// Utils

// Define a type for the slice state
export type ResolutionsState = {
  resolutionByPollId: {
    [id: string]: Array<string>
  }
  resolutionById: {
    [id: string]: any
  }
  meSignatures: { [id: string]: any }
}

// Define the initial state using that type
const initialState: ResolutionsState = {
  resolutionByPollId: {},
  resolutionById: {},
  meSignatures: {},
}

export const resolutions = createSlice({
  name: 'resolutions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestPollResolutionStates(builder)
    requestEditResolutionStates(builder)
    requestDeleteResolutionStates(builder)
    requestReorderResolutionsStates(builder)
  },
})
