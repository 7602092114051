import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios, getTokenHeaders, setAxios } from '../../utils/lib/requestAxios'

// GENERAL
// Import General Help Files
export const requestGetGeneralHelpFiles = createAsyncThunk<any>(
  'helpFiles/requestGetGeneralHelpFiles',
  async (_, { rejectWithValue, fulfillWithValue }) => {
    const response: any = await getAxios('/help/global-documents', getTokenHeaders())

    if (response.error) {
      if (response.error?.response?.data?.message) {
        return { error: response.error?.response?.data?.message }
      } else {
        return rejectWithValue(response.error.response.status)
      }
    }
    return response.data
  }
)

export const requestGetGeneralHelpFilesStates = (builder) => {
  builder.addCase(requestGetGeneralHelpFiles.fulfilled, (state, { payload }) => {
    state.general = payload
  })
}

// Export General Help Files
export const requestSetGeneralHelpFiles = createAsyncThunk<any, any>(
  'helpFiles/requestSetGeneralHelpFiles',
  async ({ globalHelpPdfUrl, globalHelpMp4Url }, { rejectWithValue, fulfillWithValue }) => {
    const body = new FormData()
    const NULL: any = null

    // PDF Check
    if (globalHelpPdfUrl) {
      body.append('pdf', globalHelpPdfUrl[0])
    } else if (!globalHelpPdfUrl && globalHelpPdfUrl !== undefined) {
      body.append('globalHelpPdfUrl', NULL)
    }

    // Video Check
    if (globalHelpMp4Url) {
      body.append('video', globalHelpMp4Url[0])
    } else if (!globalHelpMp4Url && globalHelpMp4Url !== undefined) {
      body.append('globalHelpMp4Url', NULL)
    }

    const response: any = await setAxios('/help/global-documents/upload', body, getTokenHeaders())

    if (response.error) {
      if (response.error?.response?.data?.message) {
        return { error: response.error?.response?.data?.message }
      } else {
        return rejectWithValue(response.error.response.status)
      }
    } else {
      return response.data
    }
  }
)

export const requestSetGeneralHelpFilesStates = (builder) => {
  builder.addCase(requestSetGeneralHelpFiles.fulfilled, (state, { payload }) => {
    state.general = {
      ...state.general,
      ...payload,
    }
  })
}

// ROLE
// Import Role Help Files
export const requestGetRoleHelpFiles = createAsyncThunk<string, any>(
  'helpFiles/requestGetRoleHelpFiles',
  async (role, { rejectWithValue, fulfillWithValue }) => {
    const response: any = await getAxios(`/help/role-documents/${role}`, getTokenHeaders())

    if (response.error) {
      if (response.error?.response?.data?.message) {
        return { error: response.error?.response?.data?.message }
      } else {
        return rejectWithValue(response.error.response.status)
      }
    }
    return response.data
  }
)

export const requestGetRoleHelpFilesStates = (builder) => {
  builder.addCase(requestGetRoleHelpFiles.fulfilled, (state, { payload }) => {
    state.role[payload.role] = payload
  })
}

// Export Role Help Files
export const requestSetRoleHelpFiles = createAsyncThunk<any, any>(
  'helpFiles/requestSetRoleHelpFiles',
  async ({ helpPdfUrl, helpMp4Url, role }, { rejectWithValue, fulfillWithValue }) => {
    const body = new FormData()
    const NULL: any = null

    // PDF Check
    if (helpPdfUrl) {
      body.append('pdf', helpPdfUrl[0])
    } else if (!helpPdfUrl && helpPdfUrl !== undefined) {
      body.append('helpPdfUrl', NULL)
    }

    // Video Check
    if (helpMp4Url) {
      body.append('video', helpMp4Url[0])
    } else if (!helpMp4Url && helpMp4Url !== undefined) {
      body.append('helpMp4Url', NULL)
    }

    const response: any = await setAxios(
      `/help/role-documents/${role}/upload`,
      body,
      getTokenHeaders()
    )

    if (response.error) {
      if (response.error?.response?.data?.message) {
        return { error: response.error?.response?.data?.message }
      } else {
        return rejectWithValue(response.error.response.status)
      }
    } else {
      return response.data
    }
  }
)

export const requestSetRoleHelpFilesStates = (builder) => {
  builder.addCase(requestSetRoleHelpFiles.fulfilled, (state, { payload }) => {
    state.role[payload.role] = {
      ...state.role[payload.role],
      ...payload,
    }
  })
}

// POLL
// Import Poll Help Files
export const requestGetPollHelpFiles = createAsyncThunk<string, any>(
  'helpFiles/requestGetPollHelpFiles',
  async (pollId, { rejectWithValue, fulfillWithValue }) => {
    const response: any = await getAxios(`/help/poll-documents/${pollId}`, getTokenHeaders())

    if (response.error) {
      if (response.error?.response?.data?.message) {
        return { error: response.error?.response?.data?.message }
      } else {
        return rejectWithValue(response.error.response.status)
      }
    }
    return response.data
  }
)

export const requestGetPollHelpFilesStates = (builder) => {
  builder.addCase(requestGetPollHelpFiles.fulfilled, (state, { payload }) => {
    state.poll[payload.pollId] = payload
  })
}

// Export Poll Help Files
export const requestSetPollHelpFiles = createAsyncThunk<any, any>(
  'helpFiles/requestSetPollHelpFiles',
  async ({ helpPdfUrl, helpMp4Url, pollId }, { rejectWithValue, fulfillWithValue }) => {
    const body = new FormData()
    const NULL: any = null

    // PDF Check
    if (helpPdfUrl) {
      body.append('pdf', helpPdfUrl[0])
    } else if (!helpPdfUrl && helpPdfUrl !== undefined) {
      body.append('helpPdfUrl', NULL)
    }

    // Video Check
    if (helpMp4Url) {
      body.append('video', helpMp4Url[0])
    } else if (!helpMp4Url && helpMp4Url !== undefined) {
      body.append('helpMp4Url', NULL)
    }

    const response: any = await setAxios(
      `/help/poll-documents/${pollId}/upload`,
      body,
      getTokenHeaders()
    )

    if (response.error) {
      if (response.error?.response?.data?.message) {
        return { error: response.error?.response?.data?.message }
      } else {
        return rejectWithValue(response.error.response.status)
      }
    } else {
      return response.data
    }
  }
)

export const requestSetPollHelpFilesStates = (builder) => {
  builder.addCase(requestSetPollHelpFiles.fulfilled, (state, { payload }) => {
    state.poll[payload.pollId] = {
      ...state.poll[payload.pollId],
      ...payload,
    }
  })
}
