import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getTokenHeaders,
  setAxios,
  deleteAxios,
  getAxios,
  patchAxios,
} from '../../utils/lib/requestAxios'
import { SEX_VALUE, CIVILITY_VALUE } from '../../locales/constants'
import { setAppLoading, setErrorStatus } from '../app/redux'
import { requestPasswordStatus } from '../sendCodes/services'
import { MAIN_ROLES } from '../roles/constants'
import { requestUsers } from '../demo/services'
import { ONE_USER_PURPOSE_ENDPOINTS } from '../sendCodes/constants'

/*const GEO_URL =
  'https://data.opendatasoft.com/api/records/1.0/search/?dataset=geonames-postal-code%40public&q='*/

const addUserPicture = async (userId, pic) => {
  try {
    if (pic === 'delete') {
      await deleteAxios(`/users/${userId}/avatar`, getTokenHeaders())
    } else if (typeof pic !== 'string') {
      const body = new FormData()
      body.append('image', pic)
      await setAxios(`/users/${userId}/avatar`, body, getTokenHeaders())
    }
  } catch (err) {
    console.error(err)
  }
  return null
}

export const requestManageUser = createAsyncThunk<any, any>(
  'users/requestManageUser',
  async ({ params, userId, callback }, { dispatch, rejectWithValue }) => {
    // Check Postal Matches Country & City if they exist
    // if (params.postalCode || params.city || params.country) {
    //   if (params.country?.toLowerCase() === 'france') {
    //     const addressResponse: any = await axios.get(
    //       `${GEO_URL}${params.postalCode}, +${params.city}, +${params.country}`
    //     )
    //     if (!addressResponse.data || !addressResponse.data.records.length) {
    //       return rejectWithValue({ error: 'mismatching_country_city_postalCode' })
    //     }
    //   } else {
    //     const addressResponse: any = await axios.get(`${GEO_URL}${params.city}`)
    //     if (!addressResponse.data || !addressResponse.data.records.length) {
    //       return rejectWithValue({ error: 'mismatching_country_city_postalCode' })
    //     }
    //   }
    // }

    // User Params case
    if (params.sex) {
      params.sex = SEX_VALUE[params.sex]
    }
    if (params.civility) {
      params.civility = CIVILITY_VALUE[params.civility]
    }
    if (params.firstName) {
      params.firstName = params.firstName.trim()
    }
    if (params.lastName) {
      params.lastName = params.lastName.trim()
    }
    if (params.birthDate) {
      params.birthDate = new Date(params.birthDate)
    }
    if (params.hiringDate) {
      params.hiringDate = new Date(params.hiringDate)
    }
    if (params.password) {
      delete params.password
    }

    const body: any = Object.keys(params).reduce((acc, key) => {
      if (
        !params[key] ||
        (typeof params[key] === 'string' && !params[key].length) ||
        key === 'pic'
      ) {
        acc[key] = null
      } else {
        acc[key] = params[key]
      }
      return acc
    }, {})

    if (!params.establishmentId) {
      body.establishmentId = null
    }
    if (!params.collegeId) {
      body.collegeId = null
    }

    params.mfaActive = !!params.mfaActive

    // Create User
    let response: any = null
    if (!userId) {
      response = await setAxios('/users', body, getTokenHeaders())
    } else {
      response = await patchAxios(`/users/${userId}/details`, body, getTokenHeaders())
    }

    if (params.pic && (userId || response.data?.id)) {
      await addUserPicture(userId || response.data?.id, params.pic)
    }

    if (response.error) {
      if (response.error?.response?.data?.message) {
        return { error: response.error.response.data.message }
      }
      return rejectWithValue(response.error.response.status)
    } else {
      callback && callback(userId, response.data)

      let firstLoop = true
      MAIN_ROLES.forEach((value) => {
        if (value !== 'ADMIN') {
          dispatch(requestUsers({ search: '', roles: [value], params: {}, clear: firstLoop }))
          firstLoop = false
        }
      })
      return response.data
    }
  }
)

export const requestManageUserStates = (builder) => {
  builder.addCase(requestManageUser.pending, (state) => {
    state.error = null
    state.isLoading = true
  })

  builder.addCase(requestManageUser.fulfilled, (state, { payload }) => {
    state.isLoading = false
    if (!payload.error) {
      state.success = true
    } else {
      state.error = payload.error
    }
  })

  builder.addCase(requestManageUser.rejected, (state, { payload }) => {
    state.isLoading = false
    if (payload.error) {
      state.error = payload.error
    }
  })
}

// Ask for password
export const requestNewPassword = createAsyncThunk<any, any>(
  'user/requestNewPassword',
  async ({ params, callback }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const body = {
      messageChannel: params.email ? 'EMAIL' : params.phoneNumber ? 'SMS' : 'EXTERNAL_SENDER',
      phoneNumber: params.phoneNumber || undefined,
      phoneCode: params.phoneCode || undefined,
      email: params.email || undefined,
      firstName: params.firstName,
      lastName: params.lastName,
      personalInformation: params.personalInformation,
      birthDate: new Date(params.birthDate),
      address1: params.address1 || undefined,
      address2: params.address2 || undefined,
      address3: params.address3 || undefined,
      address4: params.address4 || undefined,
      city: params.city || undefined,
      postalCode: params.postalCode || undefined,
      country: params.country || undefined,
    }
    const response: any = await setAxios('/auth/ask-password-reset-link', body, getTokenHeaders())

    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: response.error?.response?.data?.message }))
      return rejectWithValue(response.error.response.status)
    }
    callback?.()
    dispatch(setAppLoading(false))
  }
)

// Send
const TABS_CORRESPONDANCE = {
  email: 'EMAIL',
  sms: 'SMS',
  postal: 'EXTERNAL_SENDER',
}
export const requestSendAccess = createAsyncThunk<any, any>(
  'user/requestSendAccess',
  async ({ userId, params, selectedTab, purpose, callback }, { rejectWithValue, dispatch }) => {
    let body: any = {
      channelType: TABS_CORRESPONDANCE[selectedTab],
      userId,
      email: selectedTab === 'email' && params.email ? params.email : undefined,
      phoneNumber: selectedTab === 'sms' && params.phoneNumber ? params.phoneNumber : undefined,
      phoneCode: selectedTab === 'sms' && params.phoneCode ? params.phoneCode : undefined,
    }

    dispatch(setAppLoading(true))

    const response: any = await setAxios(
      ONE_USER_PURPOSE_ENDPOINTS[purpose],
      body,
      getTokenHeaders()
    )

    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: response.error?.response?.data?.message }))
      return rejectWithValue(response.error.response.status)
    }
    callback?.()
    dispatch(setAppLoading(false))
    dispatch(requestPasswordStatus({ type: TABS_CORRESPONDANCE[selectedTab] }))
  }
)

export const requestSendAccessExternal = createAsyncThunk<any, any>(
  'user/requestSendAccessExternal',
  async ({ userId, params, selectedTab, purpose, callback }, { rejectWithValue, dispatch }) => {
    let body: any = {
      userId,
      address1: params.address1 || undefined,
      address2: params.address2 || undefined,
      address3: params.address3 || undefined,
      address4: params.address4 || undefined,
      city: params.city || undefined,
      postalCode: params.postalCode || undefined,
      country: params.country || undefined,
      purpose,
    }

    dispatch(setAppLoading(true))
    const response: any = await setAxios('/auth/queue-user-access-reset', body, getTokenHeaders())

    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: response.error?.response?.data?.message }))
      return rejectWithValue(response.error.response.status)
    }
    callback?.()
    dispatch(setAppLoading(false))
    dispatch(requestPasswordStatus({ type: TABS_CORRESPONDANCE[selectedTab] }))
  }
)

// User Vote Rights
export const requestGetUserVoteRights = createAsyncThunk<any, { userId: string }>(
  'user/getUserVoteRights',
  async ({ userId }, { rejectWithValue }) => {
    const response: any = await getAxios(`/poll-electors?userId=${userId}`, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestGetUserVoteRightsStates = (builder) => {
  builder.addCase(requestGetUserVoteRights.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestGetUserVoteRights.fulfilled, (state, { payload }) => {
    state.voteRights = payload
    state.isLoading = false
  })

  builder.addCase(requestGetUserVoteRights.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestSetUserVoteRights = createAsyncThunk<any, { userId: string; body: any }>(
  'user/setUserVoteRights',
  async ({ userId, body }, { rejectWithValue }) => {
    const response: any = await setAxios(
      `/poll-electors/set-all?userId=${userId}`,
      { pollElectors: body },
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestSetUserVoteRightsStates = (builder) => {
  builder.addCase(requestSetUserVoteRights.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestSetUserVoteRights.fulfilled, (state, { payload }) => {
    state.voteRights = payload
    state.isLoading = false
  })

  builder.addCase(requestSetUserVoteRights.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestResetMFA = createAsyncThunk<any, any>(
  'user/resetMFA',
  async ({ userId, callback }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const response: any = await setAxios(`/users/${userId}/resetMFA`, null, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      callback(userId, response.data)
      dispatch(setAppLoading(false))
    }
  }
)
