import { createSlice } from '@reduxjs/toolkit'

// Services
import {
  requestDeleteRuleRecordStates,
  requestPollRecordStates,
  requestUpdateFieldsStates,
  requestUpdatePollRecordStates,
} from './services'

// Utils

// Define a type for the slice state
export type ControlStates = {
  rules: { [ruleId: number | string]: any }
  rulesByPollId: { [pollId: string]: any }
  presenceByPollId: { [pollId: string]: any }
  resultatByPollId: { [pollId: string]: any }
  usedResolutionIds: { [pollId: string]: Array<number> }
}

// Define the initial state using that type
const initialState: ControlStates = {
  rules: {},
  rulesByPollId: {},
  presenceByPollId: {},
  resultatByPollId: {},
  usedResolutionIds: {},
}

export const records = createSlice({
  name: 'records',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestPollRecordStates(builder)
    requestUpdateFieldsStates(builder)
    requestUpdatePollRecordStates(builder)
    requestDeleteRuleRecordStates(builder)
  },
})
