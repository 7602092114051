import { createSlice } from '@reduxjs/toolkit'

// // Services
import {
  addCandidateInAList,
  editCandidate,
} from '../../pages/AdminPoll/partials/AddCandidateModal/services'
import { requestListsStates } from './services'

// Utils

// Define a type for the slice state
export type CandidatesState = {
  candidates: Array<any>
  candidatesById: { [id: string]: any }
  isLoading: boolean
}

// Define the initial state using that type
const initialState: CandidatesState = {
  candidates: [],
  candidatesById: {},
  isLoading: false,
}

export const candidates = createSlice({
  name: 'candidates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestListsStates(builder)
    addCandidateInAList(builder)
    editCandidate(builder)
  },
})
