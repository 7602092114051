import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../init/store'
import { useOpenCandidateEditionModal } from '../../pages/CandidatesElection/partials/AddCandidateScrutinModal/hooks'
import {
  requestDeleteUniCandidate,
  requestEditUniPoll,
  requestReorderCandidates,
  requestVoteUninominal,
} from './services'

// Poll Edition
export const useEditUninonimalPoll = (pollId: string) => {
  const dispatch = useAppDispatch()
  const editPoll = useCallback(
    (params) => dispatch(requestEditUniPoll({ pollId, params })),
    [dispatch, pollId]
  )

  return editPoll
}

// Uninominal candidates
export const useUninominalCandidates = (pollId: string) => {
  const candidateIds = useAppSelector((state) => state.uninominal.candidatesByUniId[pollId])
  return candidateIds
}

export const useUninominalCandidate = (candidateId: number) => {
  const candidate = useAppSelector((state) => state.uninominal.candidatesById[candidateId])
  return candidate
}

export const useCandidateItemActions = (pollId: string, pollTitle: string, candidateId: number) => {
  const dispatch = useAppDispatch()

  const remove = useCallback(() => {
    dispatch(requestDeleteUniCandidate({ pollId, candidateId }))
  }, [dispatch, candidateId, pollId])

  const { openModal } = useOpenCandidateEditionModal(pollId, pollTitle, candidateId)

  return { edit: openModal, remove }
}

export const useReorderUniCandidates = (pollId: string) => {
  const dispatch = useAppDispatch()

  const reorderResolutions = useCallback(
    (orderedCandidateIds) => dispatch(requestReorderCandidates({ pollId, orderedCandidateIds })),
    [dispatch, pollId]
  )

  return reorderResolutions
}

export const useUserVote = (pollId: string) => {
  const dispatch = useAppDispatch()
  const voteForUni = useCallback(
    (candidateIds, additionalParams = {}, callback) => {
      dispatch(requestVoteUninominal({ pollId, candidateIds, additionalParams, callback }))
    },
    [dispatch, pollId]
  )
  return voteForUni
}
