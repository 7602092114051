import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios, getTokenHeaders, patchAxios, setAxios } from '../../utils/lib/requestAxios'
import setDesignVariables from '../../utils/lib/setDesignVariables'
import { setCookie } from '../../utils/security/cookies'
import { setAppLoading, setErrorStatus } from '../app/redux'
import { THEME_KEY } from './redux'

const formatTheme = (theme) => ({
  ...theme,
  colors:
    theme.colors?.reduce(
      (acc, item) => ({
        ...acc,
        [item.label]: item.value,
      }),
      {}
    ) || {},
})

const formatState = (state, theme) => {
  state.colors = theme.colors
  state.logoUrl = theme.logoUrl
  state.backgroundUrl = theme.backgroundUrl
  state.enableKeyVoteLogo = theme.enableKeyVoteLogo
}

const generalStateBuilder = (state, { payload }) => {
  const theme = formatTheme(payload)
  try {
    setCookie(THEME_KEY, JSON.stringify(theme))
  } catch (err) {
    console.warn('Problem setting theme', err)
  }

  formatState(state, theme)
  setDesignVariables(theme.colors)
}

// Request Theme
export const requestTheme = createAsyncThunk<any, undefined>(
  'theme/requestTheme',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/theme', getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    return response.data
  }
)

export const requestThemeStates = (builder) => {
  builder.addCase(requestTheme.fulfilled, generalStateBuilder)
}

// Edit Theme
export const requestEditTheme = createAsyncThunk<any, any>(
  'theme/requestEditTheme',
  async (params, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    let headers: any = getTokenHeaders()

    const bodyFormData = new FormData()
    try {
      bodyFormData.append(
        'colors',
        JSON.stringify(Object.entries(params.colors).map(([key, value]) => ({ label: key, value })))
      )
    } catch (err) {
      console.warn('Error setting colors', err)
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ title: 'color_theme_error', message: 'color_theme_error_parse' }))
      return rejectWithValue(404)
    }

    if (params.logo) {
      bodyFormData.append(typeof params.logo === 'string' ? 'logoUrl' : 'logo', params.logo)
    } else {
      bodyFormData.append('logoUrl', '')
    }
    if (params.background) {
      bodyFormData.append(
        typeof params.background === 'string' ? 'backgroundUrl' : 'background',
        params.background
      )
    } else {
      bodyFormData.append('backgroundUrl', '')
    }

    bodyFormData.append('enableKeyVoteLogo', `${!!params.enableKeyVoteLogo}`)

    headers.headers = {
      ...headers.headers,
      'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>',
      'Access-Control-Allow-Origin': '*',
    }

    const response: any = await patchAxios('/theme', bodyFormData, headers)

    dispatch(setAppLoading(false))
    if (response.error) {
      dispatch(
        setErrorStatus({
          title: 'color_theme_error',
          message: response.error.response.data.message,
        })
      )
      return rejectWithValue(response.error.response.status)
    }
    return response.data
  }
)

export const requestEditThemeStates = (builder) => {
  builder.addCase(requestEditTheme.fulfilled, generalStateBuilder)
}

// Reset Colors
export const requestResetColors = createAsyncThunk<any, undefined>(
  'theme/requestResetColors',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const response: any = await setAxios('/theme/reset-colors', {}, getTokenHeaders())

    dispatch(setAppLoading(false))
    if (response.error) {
      dispatch(
        setErrorStatus({
          title: 'color_theme_error',
          message: response.error.response.data.message,
        })
      )
      return rejectWithValue(response.error.response.status)
    }
    return response.data
  }
)

export const requestResetColorsStates = (builder) => {
  builder.addCase(requestResetColors.fulfilled, generalStateBuilder)
}
