import { createSlice } from '@reduxjs/toolkit'
import { requestUploadMixedPollState } from './services'

// Services

// Utils

// Define a type for the slice state
export type MixedPollState = {
  isLoading: boolean
}

// Define the initial state using that type
const initialState: MixedPollState = {
  isLoading: false,
}

export const mixedPoll = createSlice({
  name: 'mixedPoll',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestUploadMixedPollState(builder)
  },
})

// export const { } = mixedPoll.actions
