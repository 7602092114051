import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios, getTokenHeaders } from '../../utils/lib/requestAxios'

// Get Animators
export const requestAnimators = createAsyncThunk<any, undefined>(
  'animators/requestAnimators',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/res/animators', getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestAnimatorsState = (builder) => {
  builder.addCase(requestAnimators.fulfilled, (state, { payload }) => {
    state.userIds = payload.map((user) => {
      state.usersById[user?.userId] = user
      return user?.userId
    })
  })
}
