/*
 ** All the main Routes;
 ** Please use this when redirecting.
 ** Example
 **   ❌ push('/path');
 **   ❌ push(`path/${id}`);
 **   ❌ window.location.href = '/path'
 **   ✅ push(ROUTES.PATH);
 **   ✅ push(`${ROUTES.PATH}/${id}`);
 **   ✅ window.location.href = ROUTES.PATH
 */
const ROUTES = {
  // Admin Only
  RESOLUTIONS_ADMIN: `/admin-résolutions`,
  ADMIN_LIST: `/admin-liste`,

  // General
  CONFIG_POLL: '/configuration-scrutin',
  HOME: '/accueil',
  LOGIN: '/connexion',
  RESET_PWD: '/reinitialisation-mot-de-passe',
  DEMO_LOGIN: '/demo/connexion',
  PARTICIPATION: '/participation',
  PARTICIPATION_SIGNATURE: '/participation-signature',
  SIGNATURE: '/only-signature',
  VOTE_SCRUTIN: '/scrutin',
  VOTE_LIST: '/liste',
  VOTE_ELECTION: '/uninominal',
  BALLOT_RECEIPT: '/reçu-de-vote',
  RESOLUTIONS: '/résolutions',
  SYSTEM_STATUS: `/système`,
  EDIT_ROLE: `/edition-role`,
  CELL_MEMBERS: `/bureau_central`,
  SEALING: `/scellement`,
  PV_LIST: `/procès-verbaux`,
  USERS_LIST: `/utilisateurs`,
  ESTABLISHMENTS: `/etablissements`,
  CREATE_UPDATE_ESTABLISHMENT: `/etablissement`,
  CREATE_UPDATE_USER: `/utilisateur`,
  VOTING_LOGS: `/journaux-de-vote`,
  COLOR_THEME: `/thème`,
  ELECTIONS: `/scrutins`,
  PREVIEW_CODES: `/envoi_des_codes`,
  FOLLOW_PASSWORDS: `/suivi-mot-de-passe`,
  HELP_FILES_MANAGEMENT: `/gestion_des_fichiers_d_aide`,
  FORMAT_MANAGEMENT: `/gestion_des_formats`,
  PGP_KEYS_MANAGEMENT: `/gestion_des_clés_pgp`,
  SCRUTIN_EDITION: `/modifier_scrutin`,
  POLL: `/scrutin`,
  CONTROL_VOTE: `/contrôle_de_vote`,
  SEND_CODES: `/effectuer_envoi`,
  VOTES_RIGHTS: `/droits_de_votes`,
  VERIFICATION: `/verification`,

  // Animator
  ANIMATE_VOTE: `/animate-vote`,
}

const notLoggedRoutes = [ROUTES.LOGIN, ROUTES.RESET_PWD]
export const isLoggedRoutes = (path?: string) =>
  !notLoggedRoutes.includes(path || window.location.pathname)

export default ROUTES
