import { createAsyncThunk } from '@reduxjs/toolkit'
import { getTokenHeaders, setAxios } from '../../utils/lib/requestAxios'

// Delete Candidate from a list
export const requestDeleteListCandidate = createAsyncThunk<
  any,
  {
    listId: number
    candidateId: number
  }
>('listPolls/requestDeleteListCandidate', async ({ listId, candidateId }, { rejectWithValue }) => {
  const response: any = await setAxios(
    `/lis/lists/${listId}/remove-candidate`,
    { candidateId },
    getTokenHeaders()
  )
  if (response.error) {
    return rejectWithValue(response.error.response.status)
  } else {
    return { candidateId, listId }
  }
})

export const requestDeleteListCandidateStates = (builder) => {
  builder.addCase(
    requestDeleteListCandidate.fulfilled,
    (state, { payload: { listId, candidateId } }) => {
      state.listsById[listId].candidateIds = state.listsById[listId].candidateIds.filter(
        (id) => id !== candidateId
      )
    }
  )
}

// Reorder the candidates of a list
export const requestListReorderCandidates = createAsyncThunk<
  any,
  { orderedCandidateIds: number[]; listId: number }
>(
  'listPolls/requestListReorderCandidates',
  async ({ orderedCandidateIds, listId }, { rejectWithValue }) => {
    const response: any = await setAxios(
      `/lis/lists/${listId}/candidate-positions`,
      { orderedCandidateIds },
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    return {
      orderedCandidateIds,
      listId,
    }
  }
)

export const requestListReorderCandidatesStates = (builder) => {
  builder.addCase(
    requestListReorderCandidates.fulfilled,
    (state, { payload: { listId, orderedCandidateIds } }) => {
      state.listsById[listId].candidateIds = orderedCandidateIds
    }
  )
}
