import { createAsyncThunk } from '@reduxjs/toolkit'
import ROUTES from '../../routes/constants/routes'
import { getAxios, getTokenHeaders, patchAxios, setAxios } from '../../utils/lib/requestAxios'
import { setErrorStatus } from '../app/redux'

// Get my info if I'm Animator
export const requestMeAnimator = createAsyncThunk<any, undefined>(
  'animator/requestMeAnimator',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/res/animators/me', getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestMeAnimatorState = (builder) => {
  builder.addCase(requestMeAnimator.fulfilled, (state, { payload }) => {
    state.me = payload
  })
}

// Set my information if I'm Animator
export const requestSetAnimatorInformation = createAsyncThunk<
  any,
  { personalPhonePrefix?: string; personalPhoneNumber?: string; personalEmail?: string }
>(
  'animator/requestSetAnimatorInformation',
  async ({ personalPhonePrefix, personalPhoneNumber, personalEmail }, { rejectWithValue }) => {
    const response: any = await patchAxios(
      '/res/animators/me',
      {
        personalPhoneNumber: personalPhoneNumber?.length ? personalPhoneNumber : undefined,
        personalEmail: personalEmail?.length ? personalEmail : undefined,
        personalPhonePrefix:
          personalPhoneNumber?.length && personalPhonePrefix?.length
            ? personalPhonePrefix
            : undefined,
      },
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestSetAnimatorInformationState = (builder) => {
  builder.addCase(requestSetAnimatorInformation.fulfilled, (state, { payload }) => {
    state.me = { ...state.me, ...payload }
  })
}

// Request Polls Resolutions
export const requestAnimatorPollResolutions = createAsyncThunk<any, undefined>(
  'animator/requestAnimatorPollResolutions',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/polls', getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestAnimatorPollResolutionsStates = (builder) => {
  builder.addCase(requestAnimatorPollResolutions.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestAnimatorPollResolutions.fulfilled, (state, { payload: data }) => {
    state.isLoading = false

    data?.forEach((item) => {
      if (!state.pollIds.includes(item.id)) {
        state.pollIds.push(item.id)
      }
      state.pollById[item.id] = item
    })
  })

  builder.addCase(requestAnimatorPollResolutions.rejected, (state) => {
    state.isLoading = false
  })
}

export const requestAnimatorPollResolution = createAsyncThunk<any, { id: string }>(
  'animator/requestAnimatorPollResolution',
  async ({ id }, { rejectWithValue }) => {
    const response: any = await getAxios(`/res/aggregated/polls/${id}`, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        id,
        data: response.data?.pollInfo,
        resolutions: response.data?.aggregatedResolutions,
      }
    }
  }
)

export const requestAnimatorPollResolutionStates = (builder) => {
  builder.addCase(
    requestAnimatorPollResolution.fulfilled,
    (state, { payload: { id, data, resolutions } }) => {
      state.isLoadingItem = false

      if (data) {
        state.pollById[id] = {
          ...(state.pollById[id] || {}),
          ...data,
        }
        state.pollById[id].resolutions = {}
        state.pollById[id].resolutionIds = resolutions?.map((item) => {
          state.resolutions[item.id] = {
            ...(state.resolutions[item.id] || {}),
            ...item,
          }
          try {
            state.resolutions[item.id].description = JSON.parse(item.description)
          } catch (errr) {}
          state.pollById[id].resolutions[item.id] = state.resolutions[item.id]
          return item.id
        })
      }
    }
  )
}

export const requestResolutionStats = createAsyncThunk<any, { resId: number; pollId: string }>(
  'animator/requestResolutionStats',
  async ({ resId, pollId }, { rejectWithValue }) => {
    const response: any = await getAxios(`/res/resolutions/${resId}/stats`, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        pollId,
        resId,
        data: response.data,
      }
    }
  }
)

export const requestResolutionStatsStates = (builder) => {
  builder.addCase(
    requestResolutionStats.fulfilled,
    (state, { payload: { resId, pollId, data } }) => {
      state.isLoadingItem = false
      if (data) {
        if (!state.resolutions[resId]) state.resolutions[resId] = {}
        state.resolutions[resId].stats = data
        state.pollById[pollId].resolutions[resId] = state.resolutions[resId]
      }
    }
  )
}

// Start Animating Resolution
export const requestStartAnimate = createAsyncThunk<any, { resId: number; pollId: string }>(
  'animator/requestStartAnimate',
  async ({ resId, pollId }, { rejectWithValue, dispatch }) => {
    const response: any = await patchAxios(
      `/res/resolutions/${resId}/status`,
      { status: 'OPEN' },
      getTokenHeaders()
    )

    dispatch(requestResolutionStats({ resId, pollId }))
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    window.location.href = `${ROUTES.ANIMATE_VOTE}/${pollId}`
    return { resId, data: response.data }
  }
)

// Stop Resolution
export const requestStopAnimate = createAsyncThunk<
  any,
  { resId: number; password: string; pollId: string }
>(
  'animator/requestStopAnimate',
  async ({ resId, password, pollId }, { rejectWithValue, dispatch }) => {
    const response: any = await setAxios(
      `/res/resolutions/${resId}/count`,
      { password },
      getTokenHeaders()
    )

    if (response.error) {
      dispatch(
        setErrorStatus({
          title: 'error.invalidPassword',
          message: 'error.invalidPassword',
        })
      )
      return rejectWithValue(response.error.response.status)
    }
    dispatch(requestResultsAnimate({ resId, pollId }))
    window.location.href = `${ROUTES.ANIMATE_VOTE}/${pollId}/résultats`
    return {
      resId,
      data: response.data,
    }
  }
)

// Get results
export const requestResultsAnimate = createAsyncThunk<any, { resId: number; pollId: string }>(
  'animator/requestResultsAnimate',
  async ({ resId, pollId }, { rejectWithValue }) => {
    const response: any = await getAxios(`/res/resolutions/${resId}/result`, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    return {
      pollId,
      resId,
      data: response.data,
    }
  }
)

export const requestResultsAnimateStates = (builder) => {
  builder.addCase(requestResultsAnimate.pending, (state, { meta }) => {
    state.errors[meta.arg.resId] = null
  })

  builder.addCase(requestResultsAnimate.fulfilled, (state, { payload: { resId, data } }) => {
    state.results[resId] = data
  })

  builder.addCase(requestResultsAnimate.rejected, (state, { meta }) => {
    state.errors[meta.arg.resId] = true
  })
}

// Go next
export const requestStartNextAnimate = createAsyncThunk<
  any,
  { resId: number; nextResId?: number; pollId: string }
>(
  'animator/requestStartAnimate',
  async ({ resId, nextResId, pollId }, { rejectWithValue, dispatch }) => {
    const response: any = await patchAxios(
      `/res/resolutions/${resId}/status`,
      { status: 'CLOSED' },
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    if (nextResId) dispatch(requestStartAnimate({ resId: nextResId, pollId }))
    else window.location.href = ROUTES.HOME
  }
)
