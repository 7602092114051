import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios, getTokenHeaders, putAxios, setAxios } from '../../utils/lib/requestAxios'
import { getTokenInfo } from '../auth/utils'
import { encryptAnyBallot } from '../../utils/security/ballot.encrypt'
import { getCookie } from '../../utils/security/cookies'
import { setAppLoading, setErrorStatus } from '../app/redux'

const WHITE_VOTE = 'vote_blanc'

// Users request by search or/and by roleIds
export const requestElections = createAsyncThunk<unknown, { canUseAdmin?: boolean }>(
  'scrutins/requestElections',
  async ({ canUseAdmin }, { rejectWithValue }) => {
    const isCSE: boolean = getCookie('TYPE') === 'CSE'

    if (!isCSE) return { data: null }

    const response: any = await getAxios(
      canUseAdmin ? `/aggregated/elections` : '/aggregated/my-polls',
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      // Filtrer les données pour enlever les informations de participation et d'émargement
      const filteredData = response.data.establishments.map((establishment) => ({
        ...establishment,
        colleges: establishment.colleges.map((college) => ({
          ...college,
          polls: college.polls.map((poll) => ({
            ...poll,
            participation: undefined,
            presenceMinuteApproved: undefined,
            resultMinuteApproved: undefined,
          })),
        })),
      }))

      return {
        data: { establishments: filteredData },
      }
    }
  }
)

export const requestElectionsStates = (builder) => {
  builder.addCase(requestElections.pending, (state) => {
    state.isLoading = true
    state.shouldReload = false
  })

  builder.addCase(requestElections.fulfilled, (state, { payload }) => {
    state.isLoading = false
    state.shouldReload = false

    if (!payload.data) {
      state.shouldReload = true
      return
    }

    state.elections = payload.data.establishments
    state.collegesById = payload.data.establishments?.reduce(
      (acc, item) => ({
        ...acc,
        ...(item.colleges?.reduce((acc2, college) => ({ ...acc2, [college.id]: college }), {}) ||
          {}),
      }),
      state.collegesById || {}
    )
  })

  builder.addCase(requestElections.rejected, (state) => {
    state.isLoading = false
    state.shouldReload = false
  })
}

export const requestScrutinsElections = createAsyncThunk<unknown, { canUseAdmin?: boolean }>(
  'scrutins/requestScrutinsElections',
  async ({ canUseAdmin }, { rejectWithValue }) => {
    const response: any = await getAxios(
      canUseAdmin ? '/aggregated/elections' : '/aggregated/my-readable-polls',
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        data: response.data,
      }
    }
  }
)

export const requestScrutinsElectionsStates = (builder) => {
  builder.addCase(requestScrutinsElections.pending, (state) => {
    state.isLoading = true
    state.shouldReload = false
  })

  builder.addCase(requestScrutinsElections.fulfilled, (state, { payload }) => {
    state.isLoading = false
    state.shouldReload = false
    if (!payload.data) {
      state.shouldReload = true
      return
    }
    state.scrutinsElections = payload.data.establishments
    state.collegesById = payload.data.establishments?.reduce(
      (acc, item) => ({
        ...acc,
        ...(item.colleges?.reduce((acc2, college) => ({ ...acc2, [college.id]: college }), {}) ||
          {}),
      }),
      state.collegesById || {}
    )
  })

  builder.addCase(requestScrutinsElections.rejected, (state) => {
    state.isLoading = false
    state.shouldReload = false
  })
}

// Request all scrutins
export const requestScrutins = createAsyncThunk<unknown, undefined>(
  'scrutins/requestScrutins',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios(`/polls`, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        data: response.data,
      }
    }
  }
)

export const requestScrutinsStates = (builder) => {
  builder.addCase(requestScrutins.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestScrutins.fulfilled, (state, { payload }) => {
    state.scrutins = payload.data
    payload.data?.forEach((item) => {
      state.scrutinsById[item.id] = {
        ...(state.scrutinsById[item.id] || {}),
        ...item,
      }
    })
    state.isLoading = false
  })

  builder.addCase(requestScrutins.rejected, (state) => {
    state.isLoading = false
  })
}

// Edit a poll
export const requestEditForbiddenPoll = createAsyncThunk<any, { forbiddenPoll: any }>(
  'scrutins/requestEditForbiddenPoll',

  async ({ forbiddenPoll }, { rejectWithValue }) => {
    const response: any = await putAxios(
      `/cse/polls/forbiddenPoll/edit/${forbiddenPoll}`,
      forbiddenPoll,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        data: response.data,
      }
    }
  }
)

export const requestEditForbiddenPollStates = (builder) => {
  builder.addCase(requestEditForbiddenPoll.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestEditForbiddenPoll.fulfilled, (state, { payload }) => {
    state.forbiddenPoll = payload.data
    state.isLoading = false
  })

  builder.addCase(requestEditForbiddenPoll.rejected, (state) => {
    state.isLoading = false
  })
}
// Request all scrutins
export const requestForbiddenPoll = createAsyncThunk<unknown, undefined>(
  'scrutins/requestForbiddenPoll',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios(`/cse/polls/forbiddenPoll`, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        data: response.data,
      }
    }
  }
)

export const requestForbiddenPollStates = (builder) => {
  builder.addCase(requestForbiddenPoll.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestForbiddenPoll.fulfilled, (state, { payload }) => {
    state.forbiddenPoll = payload.data
    state.isLoading = false
  })

  builder.addCase(requestForbiddenPoll.rejected, (state) => {
    state.isLoading = false
  })
}
const pathTypes = {
  cse: '/cse/polls/:id/detailed',
  res: '/res/aggregated/polls/:id',
  uni: '/uni/polls/:id',
  list: '/lis/polls/:id/detailed',
  lis: '/lis/polls/:id/detailed',
}

const sharesPath = {
  cse: '/cse/poll-electors/me',
  res: '/res/poll-electors/me',
  uni: '/uni/poll-electors/me',
  list: '/lis/poll-electors/me',
  lis: '/lis/poll-electors/me',
}

// Request one CSE poll
export const requestScrutin = createAsyncThunk<any, { id: string; type?: string }>(
  'scrutins/requestScrutin',
  async ({ id, type = 'cse' }, { rejectWithValue }) => {
    const path = pathTypes[type].replace(':id', id)
    const response: any = await getAxios(path, getTokenHeaders())

    // Get proxies for resolution polls
    let proxies: any = null
    if (type === 'res') {
      const response: any = await getAxios(`/res/vote-proxies/me?pollId=${id}`, getTokenHeaders())
      if (response.error) {
        proxies = null
      } else {
        proxies = response.data.map((proxy) => ({
          ...proxy,
          proxiedPollUserId: proxy.proxiedPollElector.userId,
        }))
      }
    }

    // Get my shares weight for this poll
    let myShares: any = 1
    if (type !== 'cse') {
      const response: any = await getAxios(`${sharesPath[type]}?pollId=${id}`, getTokenHeaders())
      myShares = response?.data?.shares || 1
    }

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        type,
        data: response.data,
        proxies,
        myShares,
      }
    }
  }
)

export const requestScrutinStates = (builder) => {
  builder.addCase(requestScrutin.pending, (state, { meta }) => {
    state.errorsById[meta.arg.id] = false
    state.isLoading = true
  })

  builder.addCase(requestScrutin.fulfilled, (state, { payload }) => {
    if (payload.data) {
      if (payload.data.pollInfo) {
        state.scrutinsById[payload.data.pollInfo.id] = {
          ...(state.scrutinsById[payload.data.pollInfo.id] || {}),
          ...payload.data.pollInfo,
          aggregatedResolutions: payload.data.aggregatedResolutions,
          proxies: payload.proxies,
          myShares: payload.myShares,
        }
      } else {
        state.scrutinsById[payload.data.id] = {
          ...(state.scrutinsById[payload.data.id] || {}),
          ...payload.data,
          proxies: payload.proxies,
          myShares: payload.myShares,
        }
      }
    }
    state.isLoading = false
  })

  builder.addCase(requestScrutin.rejected, (state, { meta }) => {
    state.errorsById[meta.arg.id] = true
  })
}

// Edit a poll
export const requestEditScrutin = createAsyncThunk<any, { id: string; data: any }>(
  'scrutins/requestEditScrutin',
  async ({ id, data }, { rejectWithValue }) => {
    const response: any = await putAxios(`/cse/polls/${id}`, data, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      requestScrutin({ id })
      return {
        data: response.data,
      }
    }
  }
)

export const requestEditScrutinStates = (builder) => {
  builder.addCase(requestEditScrutin.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestEditScrutin.fulfilled, (state) => {
    state.isLoading = false
  })

  builder.addCase(requestEditScrutin.rejected, (state) => {
    state.isLoading = false
  })
}

// Get Scrutins lists
export const requestScrutinsLists = createAsyncThunk<any, { id: string }>(
  'scrutins/requestScrutinsLists',
  async ({ id }, { rejectWithValue }) => {
    const response: any = await getAxios(`/lists?pollId=${id}`, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        id,
        lists: response.data,
      }
    }
  }
)

export const requestScrutinsListsState = (builder) => {
  builder.addCase(requestScrutinsLists.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestScrutinsLists.fulfilled, (state, { payload }) => {
    state.scrutinsById[payload.id] = {
      ...(state.scrutinsById[payload.id] || {}),
      lists: payload.lists,
    }
    payload.lists?.forEach(
      (list) =>
        (state.listsById[list.id] = {
          ...(state.listsById[list.id] || {}),
          ...list,
        })
    )
    state.isLoading = false
  })

  builder.addCase(requestScrutinsLists.rejected, (state) => {
    state.isLoading = false
  })
}

// Get Candidates
export const requestCandidates = createAsyncThunk<any, { id: number }>(
  'scrutins/requestCandidates',
  async ({ id }, { rejectWithValue }) => {
    const response: any = await getAxios(`/candidates?listId=${id}`, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return {
        id,
        data: response.data,
      }
    }
  }
)

export const requestCandidatesState = (builder) => {
  builder.addCase(requestCandidates.fulfilled, (state, { payload }) => {
    const { data, id } = payload
    state.listsById[id] = {
      ...(state.listsById[id] || {}),
      candidates: data.map(({ user: { id } }) => id),
      candidateIds: data.map(({ id }) => id),
    }
  })
}

// Set Ballot
const VOTE_TYPES = {
  cse: '/cse/ballots',
  res: '/res/ballots',
  uni: '/uni/ballots',
  list: '/lis/ballots',
  lis: '/lis/ballots',
}

const votePending = {}

export const requestSetBallot = createAsyncThunk<
  any,
  {
    pollId: string
    print: string
    encryptedBallot: string
    additionalBallotParam: any
    callback: Function
    type?: string
  }
>(
  'scrutins/requestSetBallot',
  async (
    { pollId, print, encryptedBallot, additionalBallotParam, type = 'cse', callback },
    { rejectWithValue, dispatch }
  ) => {
    if (votePending[pollId]) return null
    votePending[pollId] = true
    dispatch(setAppLoading(true))
    const response: any = await setAxios(
      VOTE_TYPES[type],
      { pollId, encryptedBallotContent: encryptedBallot, print, ...additionalBallotParam },
      getTokenHeaders()
    )

    votePending[pollId] = false
    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: response.error.response.data.message }))
      return rejectWithValue(response.error.response.data.message)
    } else {
      callback?.()
      setTimeout(() => {
        dispatch(requestScrutin({ id: pollId, type }))
        dispatch(requestElections({ canUseAdmin: false }))
      }, 250)
      dispatch(setAppLoading(false))
      return { ...response.data, pollId, type }
    }
  }
)

export const requestSetBallotState = (builder) => {
  builder.addCase(requestSetBallot.rejected, (state, { payload }) => {
    const mandatory: { [key: string]: boolean } = {
      password: payload.includes('Password'),
      personalInformation: payload.includes('PersonalInformation'),
    }
    state.mandatoryVerifications = mandatory
  })
  builder.addCase(requestSetBallot.fulfilled, (state, { payload }) => {
    if (!payload) return
    state.shouldReload = true
    state.ballot = payload.data || payload
  })
}

// Ballot encryption key
export const requestGetEncryptedKeysBallot = createAsyncThunk<any, { pollId: string }>(
  'scrutins/requestGetEncryptedKeysBallot',
  async ({ pollId }, { rejectWithValue }) => {
    const response: any = await getAxios(`/encryption?pollId=${pollId}`, getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestGetEncryptedKeysBallotState = (builder) => {
  builder.addCase(requestGetEncryptedKeysBallot.fulfilled, (state, { payload }) => {
    state.encryptionKey = payload
  })
}

// Reorder Scrutins
export const requestReorderScrutins = createAsyncThunk<any, { orderedPollIds: Array<string> }>(
  'scrutins/requestReorderScrutins',
  async ({ orderedPollIds }, { rejectWithValue, dispatch }) => {
    const response: any = await setAxios('/polls/positions', { orderedPollIds }, getTokenHeaders())

    if (response.error) {
      dispatch(setErrorStatus({ message: response.error.response.data.message }))
      return rejectWithValue(response.error.response.status)
    }
  }
)

// Encrypted Ballot
export const requestGetEncryptedBallot = createAsyncThunk<
  any,
  { ballotContent: any; encryptionKey: string }
>('scrutins/requestGetEncryptedBallot', async ({ ballotContent, encryptionKey }, { dispatch }) => {
  dispatch(setAppLoading(true))
  try {
    const tokenInfo = getTokenInfo()

    ballotContent.voteSector = tokenInfo?.voteSector
    ballotContent.listId = ballotContent.listId === WHITE_VOTE ? null : ballotContent.listId

    const encryptedBallotContent = await encryptAnyBallot(ballotContent, encryptionKey)

    dispatch(setAppLoading(false))
    return { data: encryptedBallotContent }
  } catch (err) {
    console.warn('ERROR:', err)
  }
  dispatch(setAppLoading(false))
  return {}
})

export const requestGetEncryptedBallotState = (builder) => {
  builder.addCase(requestGetEncryptedBallot.fulfilled, (state, { payload }) => {
    state.encryptedBallot = payload?.data
  })
}
