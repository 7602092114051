import { useMemo } from 'react'
import { useRouteMatch } from 'react-router'

const useParams = (pathMatch?: string): { [key: string]: any } => {
  const match = useRouteMatch()
  let { url, path } = match
  /**
   * Commenté car cause d'important bug vis à vis du cycle de vie des composants via Ionic quand on quitte une page.
   * Le window.location.pathname n'est plus du tout coréllé avec le composant.
   * De fait, les retour du contenu des parametre de l'url sont donc abhérent du point de vue du composant
   *
   * A supprimer définitivement si la désactivation de ce bout de code n'entraine pas d'effet de bord
   * */
  /*if (pathMatch) {
    const location = window.location.pathname
    url = location
  }*/

  const { pathKeys, pathValues } = useMemo(
    () => ({
      pathKeys: pathMatch
        ? pathMatch.split('/').filter((_) => !!_)
        : path.split('/').filter((_) => !!_),
      pathValues: url.split('/').filter((_) => !!_),
    }),
    [path, pathMatch, url]
  )

  const params = useMemo(
    () =>
      pathKeys.reduce((acc, key, index) => {
        if (key.startsWith(':')) {
          acc[key.split(':')[1]] = pathValues[index]
            ? decodeURIComponent(pathValues[index])
            : undefined
        }
        return acc
      }, {}),
    [pathKeys, pathValues]
  )
  return params
}

export default useParams
