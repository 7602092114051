import { createAsyncThunk } from '@reduxjs/toolkit'
import jsonToString from '../../utils/lib/jsonToString'
import { getAxios, getTokenHeaders } from '../../utils/lib/requestAxios'

// Users request by search or/and by roleIds
export const requestCommonUsers = createAsyncThunk<any, any>(
  'proxies/requestCommonUsers',
  async ({ pageName, search, params = {}, clear, path = '/users' }, { rejectWithValue }) => {
    const response: any = await getAxios(
      `${path}?${jsonToString({ search, ...params })}`,
      getTokenHeaders()
    )

    if (response.error) {
    } else {
      return {
        clear,
        pageName,
        data: response.data,
      }
    }
  }
)

export const requestCommonUsersStates = (builder) => {
  builder.addCase(requestCommonUsers.pending, (state, { meta: { arg } }) => {
    state.isLoading[arg.pageName] = true
  })

  builder.addCase(requestCommonUsers.fulfilled, (state, { meta: { arg }, payload }) => {
    state.isLoading[arg.pageName] = true

    if (!payload) return
    const { data, pageName, clear } = payload

    let { users, count } = data || { users: [], count: 0 }

    if (data.users === undefined) {
      users = data
      count = data.length
    }

    state.totalByPage[pageName] = count

    if (clear) {
      state.usersByPage[pageName] = users?.map(({ id }) => id)
    } else {
      if (!state.usersByPage[pageName]) state.usersByPage[pageName] = []
      users?.forEach(({ id }) => {
        if (!state.usersByPage[pageName].includes(id)) {
          state.usersByPage[pageName].push(id)
        }
      })
    }
  })

  builder.addCase(requestCommonUsers.rejected, (state, { meta: { arg } }) => {
    state.isLoading[arg.pageName] = false
  })
}
