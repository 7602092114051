import { createAsyncThunk } from '@reduxjs/toolkit'
import { getTokenHeaders, setAxios, getAxios } from '../../utils/lib/requestAxios'
import { setCookie } from '../../utils/security/cookies'
import { setAppLoading, setErrorStatus } from '../app/redux'
import { getTokenInfo, roleKey, tokenKey, tokenRefreshKey } from './utils'
import { requestMeSealing } from '../sealing/services'
import { AppTypes } from '../../utils/types/AppStatus'

type LoginType = {
  username: string
  password: string
  personalInformation?: string
  mfaCode?: string
  today: Date
}
type TokenType = {
  access_token: string
  refresh_token: string
}

const MISSING_INFORMATION_STATUS = 202
const IN_ADDITIONAL_TIME_OR_CLOSED = 203
const MISSING_MFA_VALIDATION = 300
const MISSING_MFA_CODE = 301

export const requestLogin = createAsyncThunk<any, LoginType>(
  'auth/requestLogin',
  async (
    { username, password, personalInformation, mfaCode, today },
    { dispatch, rejectWithValue }
  ) => {
    const response: any = await setAxios('/auth/login', {
      username,
      password,
      personalInformation,
      mfaCode,
    })

    if (response.error) {
      return rejectWithValue({
        error: response.error.response.status,
        errorBody: response.error.response.data,
        isPersonal: !!personalInformation?.length,
      })
    } else {
      setCookie('logged_at', new Date(today).toISOString())

      const needsPersonal = response.data?.code === MISSING_INFORMATION_STATUS
      const closeVotingProcess = response.data?.code === IN_ADDITIONAL_TIME_OR_CLOSED
      const needMFAValidation = response.data?.code === MISSING_MFA_VALIDATION
      const needMFACode = response.data?.code === MISSING_MFA_CODE

      if (!needsPersonal && !closeVotingProcess && !needMFAValidation && !needMFACode) {
        const { access_token } = response.data

        const data = getTokenInfo(access_token)
        setCookie(roleKey, 'ROLE_' + data.role)
        setCookie(tokenKey, access_token)

        // Dispatch requestMeSealing to retrieve cellMemberId
        dispatch(requestMeSealing())

        return {
          needsPersonal,
          closeVotingProcess,
          needMFAValidation,
          needMFACode,
          dataInfo: response.data,
        }
      } else {
        return {
          needsPersonal,
          closeVotingProcess,
          needMFAValidation,
          needMFACode,
          dataInfo: response.data,
        }
      }
    }
  }
)

export const requestLoginStates = (builder) => {
  builder.addCase(requestLogin.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(
    requestLogin.fulfilled,
    (
      state,
      { payload: { closeVotingProcess, needsPersonal, needMFAValidation, needMFACode, dataInfo } }
    ) => {
      state.error = false
      state.closeVotingProcess = false
      state.needsPersonal = false
      state.needMFAValidation = false
      state.needMFACode = false
      state.mfaValidationInfo = null

      if (closeVotingProcess) {
        state.isLoading = false
        state.closeVotingProcess = true
        return
      }

      if (needsPersonal) {
        state.isLoading = false
        state.needsPersonal = true
        return
      }

      if (needMFAValidation) {
        state.isLoading = false
        state.needMFAValidation = true
        state.mfaValidationInfo = dataInfo?.mfaValidationInfo
        return
      }

      if (needMFACode) {
        state.isLoading = false
        state.needMFACode = true
        return
      }

      const { access_token, refresh_token } = dataInfo

      // Set on Session Storage
      const data = getTokenInfo(access_token)
      setCookie(roleKey, 'ROLE_' + data.role)
      setCookie(tokenKey, access_token)
      setCookie(tokenRefreshKey, refresh_token)
      // Saving info
      state.exp = data.exp
      state.isLoading = false
      state.token = data.token
    }
  )

  builder.addCase(requestLogin.rejected, (state, { payload }) => {
    state.error = payload.error || true
    state.errorBody = payload.errorBody
    state.needsPersonal = payload.isPersonal
    state.closeVotingProcess = false
    state.isLoading = false
  })
}

export const requestResetPassword = createAsyncThunk<
  any,
  { params: any; callback?: any; token: string }
>(
  'auth/requestResetPassword',
  async ({ params, token, callback }, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const body = {
      token,
      messageChannel: params.email ? 'EMAIL' : 'SMS',
      personalInformation: params.personalInformation,
      phoneNumber: params.phoneNumber || undefined,
      phoneCode: params.phoneCode || undefined,
      email: params.email || undefined,
      login: params.username,
      birthDate: new Date(params.birthDate),
    }

    const response: any = await setAxios('/auth/reset-password', body, getTokenHeaders())

    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus({ message: response.error?.response?.data?.message }))
      return rejectWithValue(response.error.response.status)
    }
    callback?.()
    dispatch(setAppLoading(false))
  }
)

export const requestRenewToken = createAsyncThunk<any, TokenType>(
  'auth/requestRenewToken',
  async (tokens, { rejectWithValue }) => {
    try {
      const mode: any = await getAxios('/modes', getTokenHeaders())

      if (mode?.data?.mode === AppTypes.DEMO) return

      const response: any = await setAxios(
        '/auth/renewToken',
        tokens,
        getTokenHeaders(mode?.data?.mode === AppTypes.DEMO)
      )

      if (response.error) {
        return rejectWithValue({
          error: response.error.response.status,
          errorBody: response.error.response.data,
        })
      } else {
        return {
          dataToken: response.data || {},
        }
      }
    } catch (error: any) {
      return rejectWithValue({
        error: error.response?.status || 'unknown',
        errorBody: error.response?.data || 'unknown error',
      })
    }
  }
)

export const requestRenewTokenStates = (builder) => {
  builder.addCase(requestRenewToken.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestRenewToken.fulfilled, (state, { payload }) => {
    state.error = false
    const { dataToken } = payload || {}

    if (dataToken) {
      const { access_token } = dataToken

      // Set on Session Storage
      const data = getTokenInfo(access_token)
      setCookie(roleKey, 'ROLE_' + data.role)
      setCookie(tokenKey, access_token)

      // Saving info
      state.exp = data.exp
      state.isLoading = false
      state.token = data.token
    } else {
      state.error = true
      state.errorBody = 'dataToken is undefined'
    }
  })

  builder.addCase(requestRenewToken.rejected, (state, { payload }) => {
    state.error = payload?.error || true
    state.errorBody = payload?.errorBody
    state.isLoading = false
  })
}
