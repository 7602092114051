/*
  Here you'll have to add all of the logout calls
  mandatory to clean the store and session storage

  Since the page is gonna reload, most on the store is
  gonna be eraser, but the keys left on cache + sessionStorage
  need to be clean on its reducers.

  Here you can call those reducers to clean the store
  before the reload.
*/

// Hooks
import { useCallback } from 'react'
import { useAppDispatch } from '../../init/store'
import ROUTES from '../../routes/constants/routes'

// Logout Actions
import { setAppStarted } from './redux'
import { authLogout } from '../auth/redux'

const useAppLogout = () => {
  const dispatch = useAppDispatch()
  const requestLogout = useCallback(() => {
    dispatch(setAppStarted(false))

    dispatch(authLogout())

    setTimeout(() => {
      window.location.href = ROUTES.LOGIN
    }, 1000)
  }, [dispatch])
  return requestLogout
}

export default useAppLogout
