import { IonItem } from '@ionic/react'
import { useCallback } from 'react'
import Icon from '../../../../atoms/Icon/Icon'
import Tooltip from '../../../../atoms/Tooltip/Tooltip'
import useLocalize from '../../../../locales/hook'
import { useContactInformations } from '../../../../redux/sealing/hooks'
import IconTypes from '../../../../utils/types/IconTypes'

const CellMemberButton: React.FC<any> = () => {
  const t = useLocalize()
  const { setShouldSendInformations, isCellMember, isEmpty } = useContactInformations()

  const handleClick = useCallback(
    () => setShouldSendInformations(true),
    [setShouldSendInformations]
  )

  if (!isCellMember) return null
  return (
    <IonItem lines='none' detail={false} className='headerToggle toggle' onClick={handleClick}>
      <Tooltip
        vertical='bottom'
        horizontal='left'
        label={t(
          isEmpty ? 'head_contact_information_title' : 'head_contact_information_title_change'
        )}
      >
        <Icon icon={IconTypes.USER_KEY} />
      </Tooltip>
    </IonItem>
  )
}

export default CellMemberButton
