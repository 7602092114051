import { createSlice } from '@reduxjs/toolkit'

// Services
import {
  requestEditMeListPollStates,
  requestEditMeUniPollStates,
  requestEditResolutionPollStates,
  requestMePollResolutionStates,
  requestMePollsStates,
  requestScrutinPollsStates,
} from './services'

// Utils

// Define a type for the slice state
export type MePollsState = {
  pollIds: Array<string>
  pollById: { [key: string]: any }
  isLoading: boolean
}

// Define the initial state using that type
const initialState: MePollsState = {
  pollIds: [],
  pollById: {},
  isLoading: false,
}

export const mePolls = createSlice({
  name: 'mePolls',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestMePollsStates(builder)
    requestScrutinPollsStates(builder)
    requestEditMeUniPollStates(builder)
    requestEditMeListPollStates(builder)
    requestMePollResolutionStates(builder)
    requestEditResolutionPollStates(builder)
  },
})
