import React, { useCallback, useMemo, useState } from 'react'

// Hooks
import useLocalize from '../../../locales/hook'
import { useUser } from '../../../redux/user/hooks'
import { useTheme } from '../../../redux/theme/hooks'
import { useUserId } from '../../../redux/profile/hooks'
import { useIsSealed } from '../../../redux/sealing/hooks'
import { useRoleHelpFiles } from '../../../redux/helpFiles/hooks'
import { usePlatformStatus } from '../../../redux/appConfig/hooks'
import { useAuthCheck, useAuthPrivileges, usePrivileges } from '../../../redux/auth/hooks'

// Components
import Icon from '../../../atoms/Icon/Icon'
import Image from '../../../atoms/Image/Image'
import Tooltip from '../../../atoms/Tooltip/Tooltip'
import CellMemberButton from './partials/CellMemberButton'
import AnimatorInfoButton from './partials/AnimatorInfoButton'
import LogoutModal from '../../../atoms/LogoutModal/LogoutModal'
import UserDefaultPicture from '../../../components/UserDefaultPicture/UserDefaultPicture'
import { IonHeader, IonMenuToggle, IonLabel, useIonRouter, IonItem } from '@ionic/react'

// Style & Configs
import './MainHeader.scss'
import { hideHeader } from '../..'
import ROUTES, { isLoggedRoutes } from '../../constants/routes'
import toolbarConfig from '../ToolBar/config'
import IconTypes from '../../../utils/types/IconTypes'
import getFullName from '../../../utils/lib/getFullName'
import headerConfig, { HeaderConfigType } from './config'
import { useIsCSE } from '../../../redux/configuration/hooks'

const useHasToolbar = () => {
  const isSealed = useIsSealed()
  const privileges = usePrivileges()
  const isCSE = useIsCSE()
  const { isLogged } = useAuthCheck()
  const { ENCRYPTION_KEYS_GENERATED, COUNT_DONE } = usePlatformStatus()

  const hasToolbar = useMemo(
    () =>
      isLogged &&
      isLoggedRoutes() &&
      !!toolbarConfig({
        isSealed,
        countDone: COUNT_DONE,
        generatedKeys: ENCRYPTION_KEYS_GENERATED,
        privileges,
        isCSE,
      }).filter((item) => {
        if (item.enabled === false) {
          return false
        }
        return true
      })?.length,
    [isLogged, isSealed, COUNT_DONE, ENCRYPTION_KEYS_GENERATED, isCSE, privileges]
  )
  return hasToolbar
}

const HeaderItem: React.FC<HeaderConfigType> = React.memo(
  ({ name, path, icon, rights, AdminOnly, voteStarted }) => {
    // Hooks
    const t = useLocalize()
    const { push } = useIonRouter()
    const { isAdmin } = useAuthCheck()
    const { authPrivileges } = useAuthPrivileges()
    const { PLATFORM_SEALED } = usePlatformStatus()

    const isHidden = useMemo(() => {
      if (AdminOnly && !isAdmin) return true
      if (voteStarted && PLATFORM_SEALED) return false
      if (voteStarted && !PLATFORM_SEALED) return true
      if (!authPrivileges) return false
      if (!rights || !authPrivileges) return false
      return Object.keys(rights)?.some((key) => rights[key] !== authPrivileges[key]?.isEnabled)
    }, [AdminOnly, authPrivileges, isAdmin, rights, voteStarted, PLATFORM_SEALED])

    // Handlers
    const handleClick = useCallback(() => {
      !!path && (isAdmin ? push(path) : (window.location.href = path))
    }, [path, isAdmin, push])

    if (isHidden) return null
    return (
      <>
        <IonItem className='headerItem' lines='none' detail={false} onClick={handleClick}>
          {!!icon && <Icon icon={icon} />}
          <IonLabel className='labelIon'>{t(name)}</IonLabel>
        </IonItem>
      </>
    )
  }
)

const MainHeader: React.FC<any> = () => {
  const t = useLocalize()
  const userId = useUserId()
  const { theme } = useTheme()
  const { user } = useUser(userId)
  const hasToolbar = useHasToolbar()
  const { isAdmin } = useAuthCheck()
  const { routeInfo, push } = useIonRouter()
  const { pdfFile, videoFile } = useRoleHelpFiles()
  const [isModalQuitVisible, setIsModalQuitVisible] = useState(false)

  // Memos
  const userText = useMemo(
    () => (user ? `${getFullName(user)} - ${t(`role_${user?.role?.toLowerCase()}`)}` : ''),
    [t, user]
  )

  // Callbacks
  const handleUser = useCallback(() => {}, [])
  const handleHome = useCallback(() => {
    const elem = document.getElementsByTagName('body')[0]
    if (!elem) return

    const size = parseInt(getComputedStyle(elem).getPropertyValue('--sm'))
    if (window.innerWidth <= size) return

    if (isAdmin) {
      push(ROUTES.HOME)
    } else {
      window.location.href = ROUTES.HOME
    }
  }, [isAdmin, push])

  const handleOpenModal = useCallback(() => setIsModalQuitVisible(true), [])

  const handleClickHelpPdf = useCallback(() => {
    window.open(pdfFile, '_blank')
  }, [pdfFile])

  const handleClickHelpVideo = useCallback(() => {
    window.open(videoFile, '_blank')
  }, [videoFile])

  if (hideHeader.includes(routeInfo.pathname)) {
    return null
  }

  return (
    <IonHeader id='MainHeader'>
      <div className='headerToolbar'>
        {!!theme.logoUrl && (
          <div className={`startHeader ${!hasToolbar ? 'no-space' : ''}`}>
            <IonMenuToggle
              class='company'
              autoHide={false}
              className='headerToggle'
              menu='start'
              onClick={handleHome}
            >
              <Image src={theme.logoUrl} />
            </IonMenuToggle>
          </div>
        )}
        <div className={`middleHeader ${!theme.logoUrl ? 'space' : ''}`}>
          {headerConfig.map((item) => (
            <HeaderItem {...item} key={item.name} />
          ))}
        </div>
        <div className='endHeader'>
          {!!pdfFile && (
            <IonItem
              lines='none'
              detail={false}
              className='headerToggle toggle'
              onClick={handleClickHelpPdf}
            >
              <Tooltip label={t('role_help_file')} horizontal='left' vertical='bottom'>
                <Icon icon={IconTypes.HELP_CIRCLE} />
              </Tooltip>
            </IonItem>
          )}
          {!!videoFile && (
            <IonItem
              lines='none'
              detail={false}
              className='headerToggle toggle'
              onClick={handleClickHelpVideo}
            >
              <Tooltip label={t('role_help_video')} horizontal='left' vertical='bottom'>
                <Icon icon={IconTypes.PLAY_CIRCLE} />
              </Tooltip>
            </IonItem>
          )}
          <CellMemberButton />
          <AnimatorInfoButton />
          <IonItem lines='none' detail={false} className='headerToggle toggle' onClick={handleUser}>
            <Tooltip label={userText} horizontal='left' vertical='bottom'>
              <UserDefaultPicture />
            </Tooltip>
          </IonItem>
          {/* Logout button even on iFrame */}
          <IonItem
            lines='none'
            detail={false}
            className='headerToggle toggle'
            onClick={handleOpenModal}
          >
            <Tooltip label={t('logout')} horizontal='left' vertical='bottom'>
              <Icon icon={IconTypes.POWER} />
            </Tooltip>
          </IonItem>
        </div>
      </div>
      <LogoutModal visible={isModalQuitVisible} setVisible={setIsModalQuitVisible} />
    </IonHeader>
  )
}

export default MainHeader
