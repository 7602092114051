// Hooks
import { useCallback } from 'react'
import useLocalize from '../../../../locales/hook'
import { useAnimatorInformations } from '../../../../redux/animator/hooks'

// Components
import { IonItem } from '@ionic/react'
import Icon from '../../../../atoms/Icon/Icon'
import Tooltip from '../../../../atoms/Tooltip/Tooltip'
import IconTypes from '../../../../utils/types/IconTypes'

const AnimatorInfoButton: React.FC<any> = () => {
  const t = useLocalize()
  const { setSendInformations, isEmpty, isAnimator } = useAnimatorInformations()

  const handleClick = useCallback(() => setSendInformations(true), [setSendInformations])

  if (!isAnimator) return null
  return (
    <IonItem lines='none' detail={false} className='headerToggle toggle' onClick={handleClick}>
      <Tooltip
        vertical='bottom'
        horizontal='left'
        label={t(
          isEmpty ? 'head_contact_information_title' : 'head_contact_information_title_change'
        )}
      >
        <Icon icon={IconTypes.USER_KEY} />
      </Tooltip>
    </IonItem>
  )
}

export default AnimatorInfoButton
