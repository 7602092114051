import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useAppMode } from '../../redux/appConfig/hooks'
import { useAppStatus, useInitApp, useIdle } from '../../redux/app/hooks'
import { useAuthCheck, useAuthPrivileges } from '../../redux/auth/hooks'
import { useUserPresentResolution } from '../../redux/app/resolutionHook'

// Components
import Loader from '../../atoms/Loader/Loader'
import ToolBar from '../components/ToolBar/ToolBar'
import MainHeader from '../components/MainHeader/MainHeader'
import { IonRouterOutlet, IonSplitPane, useIonRouter } from '@ionic/react'
import AssistanceFooter from '../components/AssistanceFooter/AssistanceFooter'

// Modals
import DemoModal from '../components/DemoModal/DemoModal'
import DemoFrame from '../components/DemoFrame/DemoFrame'
import IdleModal from '../components/IdleModal/IdleModal'
import ErrorModal from '../components/ErrorModal/ErrorModal'
import CellMemberContactInformationModal from '../../pages/Home/user/partials/CellMemberContactInformationModal/CellMemberContactInformationModal'
import {
  CellMemberSealingModal,
  CellMemberUnsealingModal,
} from '../../pages/Home/user/partials/CellMemberSendPasswordModal/CellMemberSendPasswordModal'

// Style & Config
import './Router.scss'
import ROUTES from '../constants/routes'
import clearCache from '../../utils/lib/clearCache'
import config, { hideAssistanceFooter } from '../index'

const AppLoader: React.FC<any> = React.memo(() => {
  const { appStarted, isLoading } = useAppStatus()
  return <div>{(!appStarted || isLoading) && <Loader fullscreen className='MainAppLoader' />}</div>
})

const Router = () => {
  const { isDemo } = useAppMode()

  useUserPresentResolution()

  const initApp = useInitApp()
  const { cellMemberId, isAdmin } = useAuthCheck()
  const { routeInfo } = useIonRouter()
  const { authPrivileges } = useAuthPrivileges()
  const { handleToken } = useIdle()

  useEffect(() => {
    initApp()
    clearCache()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    handleToken(isDemo)
  }, [isDemo])

  return (
    <>
      <AppLoader />
      <IdleModal />
      <ErrorModal />
      {cellMemberId && (
        <>
          <CellMemberContactInformationModal />
          <CellMemberSealingModal />
          <CellMemberUnsealingModal />
        </>
      )}
      {isAdmin && (
        <>
          <DemoModal />
          <DemoFrame />
        </>
      )}
      <MainHeader />

      <IonSplitPane when='sm' contentId='main' className='splitPane'>
        <ToolBar side='start' />
        <div id='mainPage' className='pageClass'>
          <IonRouterOutlet
            id='main'
            animated={false}
            className={hideAssistanceFooter.includes(routeInfo.pathname) ? '' : 'addBottomSpace'}
          >
            {config
              .filter(({ requiredPrivileges }) => {
                return (
                  !requiredPrivileges?.length ||
                  (authPrivileges &&
                    !!requiredPrivileges.filter((key) => authPrivileges[key]?.isEnabled).length)
                )
              })
              .map(({ component, exact, path }, key) => (
                <Route key={key} exact={exact} path={path} component={component} />
              ))}
            <Redirect to={ROUTES.HOME} />
          </IonRouterOutlet>
          <AssistanceFooter />
        </div>
      </IonSplitPane>
    </>
  )
}

export default Router
