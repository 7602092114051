import { createSlice } from '@reduxjs/toolkit'
import {
  addCandidateInAListPoll,
  editCandidateListPoll,
} from '../../pages/AdminList/partials/AddCandidateModal/services'
import {
  requestDeleteListCandidateStates,
  requestListReorderCandidatesStates,
} from './candidateServices'
import {
  requestDeleteListFromPollStates,
  requestListReorderStates,
  requestListScrutinStates,
  requestUpdatePollListStates,
} from './services'

// Define a type for the slice state
export type ListsPollState = {
  listsByPollId: { [id: string]: Array<number> }
  listsById: { [id: number]: any }
  candidatesById: { [id: number]: any }
  meSignatures: { [id: string]: any }
  ballots: { [id: number]: any }
}

// Define the initial state using that type
const initialState: ListsPollState = {
  meSignatures: {},
  listsByPollId: {},
  listsById: {},
  candidatesById: {},
  ballots: {},
}

export const listPolls = createSlice({
  name: 'listPolls',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestListScrutinStates(builder)
    requestUpdatePollListStates(builder)

    // Lists
    requestListReorderStates(builder)
    requestDeleteListFromPollStates(builder)

    // Candidates
    requestDeleteListCandidateStates(builder)
    requestListReorderCandidatesStates(builder)

    // Modal
    addCandidateInAListPoll(builder)
    editCandidateListPoll(builder)
  },
})
