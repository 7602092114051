import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios, getTokenHeaders } from '../../utils/lib/requestAxios'

export const requestEstablishments = createAsyncThunk<undefined, { type: string }>(
  'establishments/requestEstablishments',
  async ({ type }, { rejectWithValue }) => {
    let response: any = undefined
    switch (type) {
      case 'cse':
        response = await getAxios('/establishments', getTokenHeaders())
        break
      case 'lis':
        response = await getAxios('/lis/establishments', getTokenHeaders())
        break
      default:
        return rejectWithValue('Unknow type ' + type)
    }

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestEstablishmentsStates = (builder) => {
  builder.addCase(requestEstablishments.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestEstablishments.fulfilled, (state, { payload: establishments }) => {
    state.isLoading = false
    state.establishmentIds = []

    state.establishmentById = []
    establishments.forEach((establishment) => {
      const { id: establishmentId } = establishment
      state.establishmentIds.push(establishmentId)
      state.establishmentById[establishmentId] = establishment
    })
  })

  builder.addCase(requestEstablishments.rejected, (state, payload) => {
    state.isLoading = false
  })
}

// Request 1 Establishment
export const requestEstablishment = createAsyncThunk<any, any>(
  'establishments/requestEstablishment',
  async ({ type, establishmentId }, { rejectWithValue }) => {
    let response: any = undefined
    switch (type) {
      case 'cse':
        response = await getAxios(`/establishments/${establishmentId}`, getTokenHeaders())
        break
      case 'lis':
        response = await getAxios(`/lis/establishments/${establishmentId}`, getTokenHeaders())
        break
      default:
        return rejectWithValue('Unknow type ' + type)
    }
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestEstablishmentStates = (builder) => {
  builder.addCase(requestEstablishment.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestEstablishment.fulfilled, (state, { payload: establishment }) => {
    state.isLoading = false
    state.establishmentById[establishment.id] = establishment
  })

  builder.addCase(requestEstablishment.rejected, (state) => {
    state.isLoading = false
  })
}
