import { createSlice } from '@reduxjs/toolkit'

// Services
import {
  requestGetUsersBySelectedPollsStates,
  requestPostProxiesStates,
  requestProxiesAllStates,
  requestProxyUsersStates,
} from './services'

// Define a type for the slice state
export type UsersState = {
  userIds: Array<number | string>
  isLoading: boolean
  proxiesAll: any[]
  availableProxiesElectors: any[]
}

// Define the initial state using that type
const initialState: UsersState = {
  userIds: [],
  isLoading: false,
  proxiesAll: [],
  availableProxiesElectors: [],
}

export const proxies = createSlice({
  name: 'proxies',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestProxyUsersStates(builder)
    requestProxiesAllStates(builder)
    requestPostProxiesStates(builder)
    requestGetUsersBySelectedPollsStates(builder)
  },
})
