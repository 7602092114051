import { createAsyncThunk } from '@reduxjs/toolkit'
import { DEFAULT_TIMEZONE, toStringDate } from '../../utils/date-time.utils'
import { downloadFile } from '../../utils/lib/downloadFile'
import { getAxios, getTokenHeaders, setAxios } from '../../utils/lib/requestAxios'
import { AppTypes } from '../../utils/types/AppStatus'
import { setAppLoading, setErrorStatus } from '../app/redux'

export const requestEstablishmentsData = createAsyncThunk<undefined, undefined>(
  'appConfig/requestEstablishmentsData',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/aggregated/home')
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

const getFormatedEstablishments = (establishments) => {
  const collegeIds = {}
  const formattedEstablishments: any[] = establishments.map(({ id, name, colleges }, index) => {
    return {
      id: index,
      establishmentId: id,
      label: name,
      status: 'ENABLED',
      datas: colleges.map(({ id, type }, index) => {
        collegeIds[id] = 'ENABLED'
        return {
          collegeId: id,
          value: index + '',
          label: 'college_' + type,
          status: 'ENABLED',
        }
      }),
    }
  })

  return { formattedEstablishments, collegeIds }
}

export const requestEstablishmentsDataState = (builder) => {
  builder.addCase(requestEstablishmentsData.pending, (state) => {
    state.loaders.establishments = true
  })
  builder.addCase(requestEstablishmentsData.fulfilled, (state, { payload }) => {
    const { formattedEstablishments, collegeIds } = getFormatedEstablishments(
      payload.establishments
    )
    state.loaders.establishments = false
    state.requested.establishments = true
    state.establishments = payload.establishments || []
    state.formattedEstablishments = formattedEstablishments
    state.collegeIds = collegeIds
  })
  builder.addCase(requestEstablishmentsData.rejected, (state) => {
    state.loaders.establishments = false
  })
}

export const requestImportStatus = createAsyncThunk<undefined, undefined>(
  'appConfig/requestImportStatus',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/imports/status', getTokenHeaders())
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestImportStatusState = (builder) => {
  builder.addCase(requestImportStatus.pending, (state) => {
    state.loaders.import = true
  })
  builder.addCase(requestImportStatus.fulfilled, (state, { payload }) => {
    state.loaders.import = false
    state.isImported = payload?.stepName === 'import.step.success'
  })
  builder.addCase(requestImportStatus.rejected, (state) => {
    state.loaders.import = false
  })
}

// Managing Apps Modes
export const requestModes = createAsyncThunk<undefined, undefined>(
  'appConfig/requestModes',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/modes', getTokenHeaders())
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestModesState = (builder) => {
  builder.addCase(requestModes.fulfilled, (state, { payload }) => {
    state.isProd = payload.mode === AppTypes.PROD
    state.isDemo = payload.mode === AppTypes.DEMO
  })
}

export const requestSetProd = createAsyncThunk<undefined, { errorCallback: Function }>(
  'appConfig/requestSetProd',
  async ({ errorCallback }, { rejectWithValue }) => {
    const response: any = await setAxios('/modes/production', undefined, getTokenHeaders())
    if (response.error) {
      if (response.error.response.status === 400) {
        errorCallback(response.error.response.data.message)
      }
      return rejectWithValue(response.error.response.status)
    }
  }
)

export const requestSetConfig = createAsyncThunk<undefined, undefined>(
  'appConfig/requestSetConfig',
  async (_, { rejectWithValue }) => {
    const response: any = await setAxios('/modes/configuration', undefined, getTokenHeaders())
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
  }
)

export const requestSetProdState = (builder) => {
  builder.addCase(requestSetProd.rejected, (state) => {
    state.isProd = false
  })
  builder.addCase(requestSetProd.fulfilled, (state) => {
    state.isProd = true
  })
}

export const requestSetConfigState = (builder) => {
  builder.addCase(requestSetConfig.fulfilled, (state) => {
    state.isProd = false
  })
}

// Request All App Status
export const requestStatus = createAsyncThunk<any, undefined>(
  'appConfig/requestStatus',
  async (_, { rejectWithValue }) => {
    //const response: any = await getAxios('/status' /*, getTokenHeaders()*/)
    const response: any = await getAxios('/status', getTokenHeaders())
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestStatusState = (builder) => {
  builder.addCase(requestStatus.fulfilled, (state, { payload }) => {
    state.status = payload
  })
}

// Set app status PHYSICAL_RESULTS_IMPORTED to true
export const requestSetStatus = createAsyncThunk<any, undefined>(
  'appConfig/requestSetStatus',
  async (_, { rejectWithValue }) => {
    const response: any = await setAxios(
      '/status/set-physical-results-imported',
      {},
      getTokenHeaders()
    )
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestSetStatusState = (builder) => {
  builder.addCase(requestSetStatus.fulfilled, (state, { payload }) => {
    state.status.PHYSICAL_RESULTS_IMPORTED = true
  })
}

// Hashes Method
const sortHashesOut = (data: Array<any>) => {
  const values = data
    .sort((a, b) => {
      if (!!a.date) a.nbDate = Number(new Date(a.date))
      if (!!b.date) b.nbDate = Number(new Date(b.date))
      return a.nbDate - b.nbDate
    })
    .reduce((acc, item) => {
      if (!acc[item.type]) {
        const modifiedItem = {
          ...item,
          displayDate: `${toStringDate(
            new Date(item.date),
            'DD/MM/YYYY - HH[h]mm',
            DEFAULT_TIMEZONE,
            true
          )}`,
        }
        acc[item.type] = { old: modifiedItem, current: modifiedItem }
      } else if (item.nbDate > acc[item.type].current.nbDate) {
        const modifiedItem = {
          ...item,
          displayDate: `${toStringDate(
            new Date(item.date),
            'DD/MM/YYYY - HH[h]mm',
            DEFAULT_TIMEZONE,
            true
          )}`,
        }
        acc[item.type].current = modifiedItem
      }
      return acc
    }, {})
  return Object.entries(values)
}

// Request Hashes
export const requestPlatformHashes = createAsyncThunk<any, undefined>(
  'appConfig/requestPlatformHashes',
  async (_, { rejectWithValue }) => {
    const response: any = await getAxios('/hashes/platform', getTokenHeaders())

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestPlatformHashesState = (builder) => {
  builder.addCase(requestPlatformHashes.fulfilled, (state, { payload }) => {
    state.hashes = sortHashesOut(payload)
  })
}

// Request new Hashes
export const requestNewHashes = createAsyncThunk<any, undefined>(
  'appConfig/requestNewHashes',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const response: any = await setAxios('/hashes/platform', {}, getTokenHeaders())
    dispatch(setAppLoading(false))
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestNewHashesState = (builder) => {
  builder.addCase(requestNewHashes.fulfilled, (state, { payload }) => {
    state.hashes = sortHashesOut(payload)
  })
}

export const requestDownloadHashes = createAsyncThunk<any, undefined>(
  'appConfig/requestDownloadHashes',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setAppLoading(true))
    const headers: any = {
      responseType: 'blob',
      ...getTokenHeaders(),
    }

    const response: any = await getAxios(`/hashes/platform/excel`, headers)
    if (response.error) {
      dispatch(setAppLoading(false))
      dispatch(setErrorStatus(response.error))
      return rejectWithValue(response.error.statusCode)
    }
    downloadFile(response)
    dispatch(setAppLoading(false))
  }
)

export const requestSetDownload1Round = createAsyncThunk<any, undefined>(
  'appConfig/requestSetDownload1Round',
  async (_, { rejectWithValue }) => {
    const response: any = await setAxios('/status/set-download-1-round', {}, getTokenHeaders())
    if (response.error) {
      return rejectWithValue(response.error.response.status)
    } else {
      return response.data
    }
  }
)

export const requestSetDownload1RoundState = (builder) => {
  builder.addCase(requestSetDownload1Round.fulfilled, (state, { payload }) => {
    state.status = payload
  })
}
