import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  deleteAxios,
  getAxios,
  getTokenHeaders,
  setAxios,
  patchAxios,
} from '../../utils/lib/requestAxios'
import { setAppLoading, setErrorStatus } from '../app/redux'
import { CORRESPONDANCE, INV_CORRESPONDANCE, PURPOSE_ENDPOINTS, TABS } from './constants'

export const requestSendStatus = createAsyncThunk<any, { purpose: string }>(
  'sendCodes/requestSendStatus',
  async ({ purpose }) => {
    const response: any = await getAxios(
      `/aggregated/message-statuses?purpose=${purpose}`,
      getTokenHeaders()
    )

    return {
      datas: response.data,
      tabs: TABS,
    }
  }
)

export const requestSendStatusState = (builder) => {
  builder.addCase(requestSendStatus.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestSendStatus.fulfilled, (state, { payload: { tabs, datas = [] } }) => {
    state.isLoading = false

    state.typesData = datas.map((item: any = {}) => ({
      type: CORRESPONDANCE[item.channelType],
      startDate: item.createdAt,
      endDate: item.updatedAt,
      status: item.status,
      nbSent: item.succededNb,
      nbTotalSent: item.usersNb,
      plannedSendTime: item.plannedSendTime,
      planSending: item.planSending,
      id: item.id,
    }))

    state.tabs = Object.entries(tabs || {}).map(([key, value]: any) => {
      if (value.disabled && !state.disabledTabs.includes(value)) {
        state.disabledTabs.push(key)
      }
      if (value.isInit) {
        state.initTab = key
      }
      return key
    })
  })

  builder.addCase(requestSendStatus.rejected, (state, { payload }) => {
    state.isLoading = false
  })
}

export const requestInitData = createAsyncThunk<any, { purpose: string }>(
  'sendCodes/requestInitData',
  async ({ purpose = 'IDENTIFIER_AND_PASSWORD' }) => {
    const response: any = await getAxios(`/message-templates?purpose=${purpose}`, getTokenHeaders())
    return {
      purpose,
      datas: response.data,
    }
  }
)

export const requestInitDataState = (builder) => {
  builder.addCase(requestInitData.pending, (state) => {
    state.isLoading = true
  })

  builder.addCase(requestInitData.fulfilled, (state, { payload: { purpose, datas = [] } }) => {
    state.isLoading = false

    state.datas[purpose] = datas.reduce((acc, item: any) => {
      acc[CORRESPONDANCE[item.channelType]] = {
        ...(item.content || {}),
        id: item.id,
      }
      return acc
    }, {})
  })

  builder.addCase(requestInitData.rejected, (state, { payload }) => {
    state.isLoading = false
  })
}

// Update Template
export const requestChangeTypeTemplate = createAsyncThunk<
  any,
  { type: string; id: number; content: any; purpose: string }
>('sendCodes/requestChangeTypeTemplate', async ({ type, id, content, purpose }, { dispatch }) => {
  await setAxios(
    `/message-templates/${id || 0}/content`,
    {
      content,
      purpose,
      channelType: INV_CORRESPONDANCE[type],
    },
    getTokenHeaders()
  )
  if (!id) dispatch(requestInitData({ purpose }))
  return {
    purpose,
    type,
    content,
  }
})

export const requestChangeTypeTemplateState = (builder) => {
  builder.addCase(
    requestChangeTypeTemplate.pending,
    (
      state,
      {
        meta: {
          arg: { type },
        },
      }
    ) => {
      state.isLoading = true
    }
  )

  builder.addCase(
    requestChangeTypeTemplate.fulfilled,
    (state, { payload: { type, content, purpose } }) => {
      state.isLoading = false
      if (!state.datas[purpose]) {
        state.datas[purpose] = {}
      }
      state.datas[purpose][type] = { ...(state.datas[purpose]?.[type] || {}), ...content }
    }
  )
}

// Request send Identifiers / Password
export const requestSendCodes = createAsyncThunk<
  any,
  {
    type: string
    onlyNotVoting: boolean
    generateIdentifiers: boolean
    content: any
    purpose: string
    isTest: boolean
    testContact?: any
    planSending: boolean
    plannedSendTime: Date
    callback: (success: boolean) => void
  }
>(
  'sendCodes/requestSendCodes',
  async (
    {
      type,
      content,
      purpose,
      onlyNotVoting = false,
      generateIdentifiers = false,
      isTest = false,
      testContact,
      callback,
      planSending,
      plannedSendTime,
    },
    { dispatch }
  ) => {
    dispatch(setAppLoading(true))

    const channelType = INV_CORRESPONDANCE[type]

    const response: any = await setAxios(
      PURPOSE_ENDPOINTS[purpose],
      {
        channelType,
        onlyNotVoting,
        generateIdentifiers,
        purpose,
        template: content,
        planSending,
        plannedSendTime,
        testContact: isTest
          ? {
              email: channelType === INV_CORRESPONDANCE.email ? testContact.email : undefined,
              phoneCode: channelType === INV_CORRESPONDANCE.sms ? testContact.phoneCode : undefined,
              phoneNumber:
                channelType === INV_CORRESPONDANCE.sms ? testContact.phoneNumber : undefined,
            }
          : undefined,
      },
      getTokenHeaders()
    )

    if (response.error) {
      callback(false)
      dispatch(setAppLoading(false))
      dispatch(
        setErrorStatus({
          message: response.error.response.data.message,
          status: response.error.response.status,
        })
      )
    } else {
      callback(true)
      dispatch(setAppLoading(false))
    }
  }
)

export const requestSendCodesState = (builder) => {
  builder.addCase(requestSendCodes.pending, (state) => {
    state.sendingLoader = true
  })

  builder.addCase(requestSendCodes.fulfilled, (state) => {
    state.sendingLoader = false
  })
}

export const requestTypeUsers = createAsyncThunk<any, { type: string }>(
  'sendCodes/requestTypeUsers',
  async ({ type }) => {
    const response: any = await getAxios(
      `/aggregated/nb-electors-with-channel?channel=${INV_CORRESPONDANCE[type]}`,
      getTokenHeaders()
    )
    return {
      type,
      data: response.data || { nbWithChannel: 0, totalNb: 0 },
    }
  }
)

export const requestTypeUsersState = (builder) => {
  builder.addCase(requestTypeUsers.fulfilled, (state, { payload: { type, data } }) => {
    state.usersType[type] = { total: data.totalNb, nb: data.nbWithChannel }
  })
}

// Follow passwords
export const requestPasswordStatus = createAsyncThunk<
  any,
  { type?: 'EMAIL' | 'SMS' | 'EXTERNAL_SENDER' }
>('sendCodes/requestPasswordStatus', async ({ type }) => {
  const response: any = await getAxios(
    `/message-statuses?${type ? `&channelType=${type}` : ''}`,
    getTokenHeaders()
  )
  return {
    type,
    datas: response.data,
  }
})

export const requestPasswordStatusState = (builder) => {
  builder.addCase(requestPasswordStatus.fulfilled, (state, { payload: { type, datas } }) => {
    if (!state.datas.PASSWORD_RESET) {
      state.datas.PASSWORD_RESET = {}
    }

    state.datas.PASSWORD_RESET[type] = datas
  })
}

export const requestDeletePasswordLog = createAsyncThunk<
  any,
  { id: number; type?: 'EMAIL' | 'SMS' | 'EXTERNAL_SENDER' }
>('sendCodes/requestDeletePasswordLog', async ({ id }) => {
  await deleteAxios(`/message-statuses/${id}`, getTokenHeaders())
})

export const requestDeletePlannedSendLog = createAsyncThunk<
  any,
  { id: number; type?: 'EMAIL' | 'SMS' | 'EXTERNAL_SENDER' }
>('sendCodes/requestDeletePlannedSendLog', async ({ id }) => {
  await deleteAxios(`/message-statuses/planned/${id}`, getTokenHeaders())
})

export const requestUpdateMessageStatusLog = createAsyncThunk<
  any,
  { id: number; type?: 'EMAIL' | 'SMS' | 'EXTERNAL_SENDER' },
  { dispatch }
>('sendCodes/requestUpdateMessageStatusLog', async ({ id }) => {
  const response: any = await patchAxios(
    '/message-statuses/update',
    {
      id: id,
    },
    getTokenHeaders()
  )

  return {
    datas: response.data,
  }
})

export const requestUpdateMessageStatusLogState = (builder) => {
  builder.addCase(requestUpdateMessageStatusLog.pending, (state) => {
    state.isLoading = true
  })
  builder.addCase(requestUpdateMessageStatusLog.fulfilled, (state, { payload: { datas } }) => {
    state.isLoading = false

    if (!state.datas.PASSWORD_RESET) {
      state.datas.PASSWORD_RESET = {}
    }

    state.datas.PASSWORD_RESET['EXTERNAL_SENDER'] = datas
  })
}

export const requestDeletePasswordLogState = (builder) => {
  builder.addCase(requestDeletePasswordLog.pending, (state, { meta }) => {
    const { type, id } = meta.arg
    if (type) {
      state.datas.PASSWORD_RESET[type] =
        state.datas.PASSWORD_RESET[type].filter(({ id: mId }) => mId !== id) || []
    } else {
      if (state.datas.PASSWORD_RESET.EMAIL?.some(({ mId }) => mId === id)) {
        state.datas.PASSWORD_RESET.EMAIL =
          state.datas.PASSWORD_RESET.EMAIL?.filter(({ id: mId }) => mId !== id) || []
      }
      if (state.datas.PASSWORD_RESET.SMS?.some(({ mId }) => mId === id)) {
        state.datas.PASSWORD_RESET.SMS = state.datas.PASSWORD_RESET.SMS?.filter(
          ({ id: mId }) => mId !== id || []
        )
      }
    }
  })
}

export const requestDeletePlannedState = (builder) => {
  builder.addCase(requestDeletePlannedSendLog.fulfilled, (state, { meta }) => {
    const { id } = meta.arg

    state.typesData = state?.typesData.filter((item) => item.id !== id)
  })
}
