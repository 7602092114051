import { setAnimatorInformations } from './redux'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { CORRESPONDANCE_ROLES } from '../roles/constants'
import { useAppDispatch, useAppSelector } from '../../init/store'
import {
  requestAnimatorPollResolution,
  requestAnimatorPollResolutions,
  requestMeAnimator,
  requestResolutionStats,
  requestResultsAnimate,
  requestSetAnimatorInformation,
  requestStartAnimate,
  requestStartNextAnimate,
  requestStopAnimate,
} from './services'
import { createSelector } from '@reduxjs/toolkit'

// Animator Infos
export const useAnimatorInformations = () => {
  const dispatch = useAppDispatch()

  const me = useAppSelector((state) => state.animator.me)
  const isAnimator = useAppSelector((state) => state.auth.role === CORRESPONDANCE_ROLES.animator)
  const shouldSendInformations = useAppSelector((state) => state.animator.needsAnimatorInformation)

  const setSendInformations = useCallback(
    (value: boolean) => dispatch(setAnimatorInformations(value)),
    [dispatch]
  )
  const getAnimatorInformation = useCallback(() => dispatch(requestMeAnimator()), [dispatch])
  const sendAnimatorInformations = useCallback(
    (contactInformations: {
      personalPhonePrefix?: string
      personalPhoneNumber?: string
      personalEmail?: string
    }) => {
      setSendInformations(false)
      dispatch(requestSetAnimatorInformation(contactInformations))
    },
    [dispatch, setSendInformations]
  )

  // This timer is to wait for all this info before setting the modal
  const timer = useRef<NodeJS.Timeout | null>(null)
  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => {
      setSendInformations(
        !!isAnimator && !!me && !me.personalEmail?.length && !me.personalPhoneNumber?.length
      )
    }, 500)
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [setSendInformations, me, isAnimator])

  useEffect(() => {
    if (isAnimator) {
      getAnimatorInformation()
    }
  }, [isAnimator, getAnimatorInformation])

  return {
    isEmpty: !me?.personalEmail?.length || !me?.personalPhoneNumber?.length,
    pEmail: me?.personalEmail,
    pPhoneNumber: me?.personalPhoneNumber,
    isAnimator,
    shouldSendInformations,
    setSendInformations,
    sendAnimatorInformations,
  }
}

// Animator Polls
export const useAnimatorPollResolutions = () => {
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector((state) => state.animator.isLoading)
  const resolutionIds = useAppSelector((state) => state.animator.pollIds)

  useEffect(() => {
    dispatch(requestAnimatorPollResolutions())
  }, [dispatch])
  return { resolutionIds, isLoading }
}

export const useAnimatorPollResolution = (pollId: string) => {
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector((state) => state.animator.isLoadingItem)
  const resolution = useAppSelector((state) => state.animator.pollById[pollId])

  useEffect(() => {
    dispatch(requestAnimatorPollResolution({ id: pollId }))
  }, [pollId, dispatch])

  return { ...(resolution || {}), loading: isLoading }
}

// Animate Resolution
export const useAnimateResolution = (pollId: string, resId?: number) => {
  const dispatch = useAppDispatch()

  const startVote = useCallback(
    (overId?: number) => dispatch(requestStartAnimate({ resId: resId || overId || 0, pollId })),
    [dispatch, pollId, resId]
  )
  const stopVotes = useCallback(
    (password: string, overId?: number) =>
      dispatch(requestStopAnimate({ resId: resId || overId || 0, password, pollId })),
    [dispatch, resId, pollId]
  )
  const getResults = useCallback(
    (overId?: number) => dispatch(requestResultsAnimate({ resId: resId || overId || 0, pollId })),
    [dispatch, pollId, resId]
  )

  return {
    startVote,
    stopVotes,
    getResults,
  }
}

export const useAnimatorResolution = (resId: number) => {
  const resolution = useAppSelector((state) => state.animator.resolutions[resId])
  return resolution
}

// Current Resolution in a Poll
const timersRef = {}
export const useCurrentPollResolution = (pollId: string, isProgressive?: boolean) => {
  const dispatch = useAppDispatch()

  const resolutions = useAppSelector((state) => state.animator.pollById[pollId]?.resolutions)
  const resolutionIds = useAppSelector((state) => state.animator.pollById[pollId]?.resolutionIds)

  const currentResolutionId = useMemo<number | null>(
    () =>
      isProgressive
        ? resolutionIds?.find(
            (id) =>
              !resolutions[id].openingDate ||
              (!!resolutions[id].openingDate && !resolutions[id].closingDate) ||
              (!!resolutions[id].openingDate &&
                !!resolutions[id].closingDate &&
                resolutions[id].status === 'OPEN')
          )
        : null,
    [isProgressive, resolutionIds, resolutions]
  )

  const nextResolutionId = useMemo<number | null>(
    () =>
      currentResolutionId
        ? resolutionIds[resolutionIds.findIndex((id) => id === currentResolutionId) + 1]
        : null,
    [currentResolutionId, resolutionIds]
  )

  const allClosed = useMemo<boolean>(
    () =>
      !!resolutionIds &&
      !resolutionIds.some(
        (id) => !resolutions[id].closingDate || resolutions[id].status === 'OPEN'
      ),
    [resolutionIds, resolutions]
  )

  const currentResolution = useAppSelector((state) =>
    currentResolutionId ? state.animator.resolutions[currentResolutionId] : null
  )

  const getStats = useCallback(
    (resId) => dispatch(requestResolutionStats({ resId, pollId })),
    [dispatch, pollId]
  )

  // Get Info and Stats
  useEffect(() => {
    if (currentResolutionId && isProgressive) {
      getStats(currentResolutionId)
      if (timersRef[currentResolutionId]) {
        clearInterval(timersRef[currentResolutionId])
        timersRef[currentResolutionId] = null
      }
      timersRef[currentResolutionId] = setInterval(() => getStats(currentResolutionId), 2000)
    }
    return () => {
      if (currentResolutionId && isProgressive && timersRef[currentResolutionId]) {
        clearInterval(timersRef[currentResolutionId])
        timersRef[currentResolutionId] = null
      }
    }
  }, [currentResolutionId, getStats, isProgressive])

  return {
    currentResolution,
    currentResolutionId,
    nextResolutionId,
    allClosed,
    isEmpty: resolutionIds?.length,
  }
}

export const useResolutionResults = (resId: number | null, pollId: string) => {
  const dispatch = useAppDispatch()
  const results = useAppSelector((state) => resId && state.animator.results[resId])

  const getResults = useCallback(
    () => resId && dispatch(requestResultsAnimate({ resId, pollId })),
    [dispatch, pollId, resId]
  )

  const goNext = useCallback(
    (nextResId) => resId && dispatch(requestStartNextAnimate({ resId, nextResId, pollId })),
    [dispatch, pollId, resId]
  )

  useEffect(() => {
    getResults()
  }, [getResults])

  return { results, goNext }
}
