import { useEffect, useRef } from 'react'
import Loader from '../../../atoms/Loader/Loader'
import { useDemo } from '../../../redux/demo/hooks'

import './DemoFrame.scss'
import ROUTES from '../../constants/routes'

const DemoFrame = () => {
  const { currentUserId, isLoading } = useDemo()

  const frameRef = useRef<any>()
  const timer = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (frameRef.current) {
      frameRef.current.src = window.location.origin + ROUTES.DEMO_LOGIN
    }
    if (timer.current) clearTimeout(timer.current)
    timer.current = setTimeout(() => {
      if (frameRef.current) {
        frameRef.current.contentWindow.location.reload()
      }
    }, 450)
    return () => {
      if (timer.current) clearTimeout(timer.current)
    }
  }, [currentUserId])

  if (isLoading && !currentUserId) return <Loader fullscreen />
  if (!currentUserId) return null
  return (
    <iframe
      id='DemoFrame'
      title='Démonstration'
      ref={frameRef}
      src={window.location.origin + ROUTES.DEMO_LOGIN}
    />
  )
}

export default DemoFrame
