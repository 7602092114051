import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../init/store'
import { ADD_CANDIDATE_TO_UNI } from '../../../../redux/modals/constants'
import {
  useModalsClose,
  useModalsEditContext,
  useModalsOpen,
  useModalsResetContext,
} from '../../../../redux/modals/hooks'
import {
  requestAddCandidateToUniPoll,
  requestEditCandidate,
  requestEligibleCandidateToUniPoll,
} from './services'

type contextKeys =
  | 'description'
  | 'profilePictureFile'
  | 'userId'
  | 'presentationFile'
  | 'videoFile'
  | 'profilePictureUrl'
  | 'presentationUrl'
  | 'videoUrl'

function useContextKeySelector(key: 'description'): string
function useContextKeySelector(key: 'profilePictureFile'): File | null
function useContextKeySelector(key: 'profilePictureUrl'): string | null
function useContextKeySelector(key: 'userId'): string
function useContextKeySelector(key: 'presentationFile'): File | null
function useContextKeySelector(key: 'presentationUrl'): string | null
function useContextKeySelector(key: 'videoFile'): File | null
function useContextKeySelector(key: 'videoUrl'): string | null
function useContextKeySelector(key: contextKeys) {
  return useAppSelector((state) => state.modals[ADD_CANDIDATE_TO_UNI].context[key])
}

type User = {
  id: string
  firstName: string
  lastName: string
}

type paramKeys = 'candidateId' | 'eligibleCandidates' | 'pollId' | 'pollTitle'

function useParamsKeySelector(key: 'pollTitle'): string
function useParamsKeySelector(key: 'candidateId'): number
function useParamsKeySelector(key: 'eligibleCandidates'): User[]
function useParamsKeySelector(key: paramKeys) {
  return useAppSelector((state) => state.modals[ADD_CANDIDATE_TO_UNI].params[key])
}

export const useAddCandidateModal = () => {
  const isLoading = useAppSelector((state) => state.candidates.isLoading)
  const isOpen = useAppSelector((state) => state.modals[ADD_CANDIDATE_TO_UNI].isOpen)
  const candidateId = useParamsKeySelector('candidateId')
  const pollTitle = useParamsKeySelector('pollTitle')
  const { closeModal } = useModalsClose(ADD_CANDIDATE_TO_UNI)

  return { isOpen, isLoading, closeModal, candidateId, pollTitle }
}

export const useAddCandidateDescription = () => {
  const { editContext } = useModalsEditContext(ADD_CANDIDATE_TO_UNI)
  const description: string = useContextKeySelector('description')
  const handleDescriptionChange = useCallback(
    (text) => {
      editContext({ description: text })
    },
    [editContext]
  )
  return {
    handleDescriptionChange,
    description,
  }
}

export const useAddCandidateValidationButton = () => {
  const dispatch = useAppDispatch()
  const context = useAppSelector((state) => state.modals[ADD_CANDIDATE_TO_UNI].context)
  const params = useAppSelector((state) => state.modals[ADD_CANDIDATE_TO_UNI].params)
  const candidate = useAppSelector((state) => state.candidates.candidatesById[params?.candidateId])

  const handleConfirm = useCallback(() => {
    if (params.candidateId) {
      dispatch(
        requestEditCandidate({
          candidate,
          candidateId: params.candidateId,
          ...context,
        })
      )
    } else {
      dispatch(
        requestAddCandidateToUniPoll({
          pollId: params.pollId,
          ...context,
        })
      )
    }
  }, [params.candidateId, params.pollId, dispatch, candidate, context])
  const { resetContext } = useModalsResetContext(ADD_CANDIDATE_TO_UNI)
  return {
    handleConfirm,
    resetContext,
  }
}

export const useAddCandidateProfilePicture = () => {
  const { editContext } = useModalsEditContext(ADD_CANDIDATE_TO_UNI)
  const profilePictureFile: File | null = useContextKeySelector('profilePictureFile')
  const profilePictureFileUrl: string | null = useContextKeySelector('profilePictureUrl')

  const handleProfilePictureChange = useCallback(
    (files: File[] | string | null) => {
      editContext({
        profilePictureFile: files?.[0] instanceof File ? files?.[0] : null,
        profilePictureUrl: files?.[0] instanceof File ? null : files,
      })
    },
    [editContext]
  )

  return {
    handleProfilePictureChange,
    file: profilePictureFile || profilePictureFileUrl,
  }
}

export const useAddCandidateUser = () => {
  const { editContext } = useModalsEditContext(ADD_CANDIDATE_TO_UNI)
  const eligibleCandidates = useParamsKeySelector('eligibleCandidates')
  const userId: string = useContextKeySelector('userId')

  const handlePickerChange = useCallback(
    (e) => {
      editContext({ userId: e.value })
    },
    [editContext]
  )

  return {
    handlePickerChange,
    userId,
    eligibleCandidates,
  }
}

export const useAddCandidatePresentation = () => {
  const { editContext } = useModalsEditContext(ADD_CANDIDATE_TO_UNI)
  const presentationFile: File | null = useContextKeySelector('presentationFile')
  const presentationUrl: string | null = useContextKeySelector('presentationUrl')

  const handlePresentationChange = useCallback(
    (files: File[] | string | null) => {
      editContext({
        presentationFile: files?.[0] instanceof File ? files?.[0] : null,
        presentationUrl: files?.[0] instanceof File ? null : files,
      })
    },
    [editContext]
  )

  return {
    handlePresentationChange,
    file: presentationFile || presentationUrl,
  }
}

export const useAddCandidateVideo = () => {
  const { editContext } = useModalsEditContext(ADD_CANDIDATE_TO_UNI)
  const videoFile: File | null = useContextKeySelector('videoFile')
  const videoUrl: string | null = useContextKeySelector('videoUrl')

  const handleVideoChange = useCallback(
    (files: File[] | string | null) => {
      editContext({
        videoFile: files?.[0] instanceof File ? files?.[0] : null,
        videoUrl: files?.[0] instanceof File ? null : files,
      })
    },
    [editContext]
  )

  return {
    handleVideoChange,
    file: videoFile || videoUrl,
  }
}

export const useOpenCandidateEditionModal = (pollId, pollTitle, candidateId?) => {
  const dispatch = useAppDispatch()
  const { openModal: oldOpenModal } = useModalsOpen(ADD_CANDIDATE_TO_UNI)
  const openModal = useCallback(() => {
    if (!candidateId) {
      dispatch(requestEligibleCandidateToUniPoll({ pollId }))
    }
    oldOpenModal({
      params: { pollId, candidateId, pollTitle },
      compareKeys: ['pollId'],
    })
  }, [dispatch, pollId, pollTitle, candidateId, oldOpenModal])
  return {
    openModal,
  }
}

export const useOpenAddCandidateToListModal = (pollId, pollTitle) => {
  const { openModal: oldOpenModal } = useOpenCandidateEditionModal(pollId, pollTitle)
  const openModal = useCallback(() => {
    oldOpenModal()
  }, [oldOpenModal])
  return {
    openModal,
  }
}
