import { createSlice } from '@reduxjs/toolkit'
import { ADD_CANDIDATE_TO_LIST, ADD_CANDIDATE_TO_UNI } from './constants'

// Services
import {
  requestEditCandidateModalVersion,
  requestEligibleCandidateToListState,
  requestAddCandidateToListsModalVersionState,
} from '../../pages/AdminPoll/partials/AddCandidateModal/services'
import {
  requestEditUniCandidateModalVersion,
  requestEligibleCandidateToUniPollState,
  requestAddCandidateToUniPollModalVersionState,
} from '../../pages/CandidatesElection/partials/AddCandidateScrutinModal/services'
import {
  requestEligibleCandidateToListPollState,
  requestAddCandidateToListPollModalVersionState,
  requestEditCandidateListPollModalVersion,
} from '../../pages/AdminList/partials/AddCandidateModal/services'

// Utils

// Define a type for the slice state

export type ListsState = {
  [key: string]: {
    isOpen: boolean
    params?: object
    context?: object
  }
}

// Define the initial state using that type
const initialState: ListsState = {
  [ADD_CANDIDATE_TO_LIST]: {
    isOpen: false,
    params: {},
    context: {},
  },
  [ADD_CANDIDATE_TO_UNI]: {
    isOpen: false,
    params: {},
    context: {},
  },
}

export const modals = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, { payload }) => {
      const { modalName, compareKeys, params } = payload
      const currentParams = state[modalName].params

      if (
        compareKeys &&
        currentParams &&
        compareKeys.some((key) => params[key] !== currentParams[key])
      ) {
        state[modalName].context = {}
      }
      state[modalName].isOpen = true
      state[modalName].params = params
    },
    closeModal: (state, { payload: { modalName } }) => {
      state[modalName].isOpen = false
    },
    editContext: (state, { payload: { modalName, context } }) => {
      state[modalName].context = { ...state[modalName].context, ...context }
    },
    resetContext: (state, { payload: { modalName } }) => {
      state[modalName].context = {}
    },
  },
  extraReducers: (builder) => {
    // Lists
    requestEditCandidateModalVersion(builder)
    requestEligibleCandidateToListState(builder)
    requestAddCandidateToListsModalVersionState(builder)

    // Uni
    requestEditUniCandidateModalVersion(builder)
    requestEligibleCandidateToUniPollState(builder)
    requestAddCandidateToUniPollModalVersionState(builder)

    // Polls Lists
    requestEligibleCandidateToListPollState(builder)
    requestEditCandidateListPollModalVersion(builder)
    requestAddCandidateToListPollModalVersionState(builder)
  },
})

export const { openModal, closeModal, editContext, resetContext } = modals.actions
