import translations from './fr.json'

const getTranslateFunction = () => {
  return (
    value: string,
    params?: { [key: string | number]: string | number | null | undefined }
  ) => {
    let toRet: string = translations[value] || value
    if (!!params && !!translations[value]) {
      try {
        Object.entries(params).forEach(([key, item]) => {
          toRet = toRet.replace(`{{${key}}}`, item?.toString() || '')
        })
      } catch (err) {
        console.error(err)
      }
    }
    return toRet
  }
}

const useLocalize = () => {
  const t = getTranslateFunction()
  return t
}

export default useLocalize
