import Icon from '../../atoms/Icon/Icon'
import IconTypes from '../../utils/types/IconTypes'
import './InfoBox.scss'

export enum InfoType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

export type ButtonProp = {
  text: string
  onClick: any
}

interface Props {
  id?: string
  title?: string
  description?: string
  type?: InfoType
  button?: ButtonProp
}

const InfoBox = ({ id, title, description, type = InfoType.INFO, button }: Props) => {
  return (
    <div id={id} className={`InfoBox ${type}`}>
      {type === InfoType.INFO && (
        <div className='iconSide'>
          <Icon icon={IconTypes.INFO} />
        </div>
      )}

      {type === InfoType.SUCCESS && (
        <div className='iconSide'>
          <Icon icon={IconTypes.CHECK_CIRCLE} />
        </div>
      )}

      {type === InfoType.WARNING && (
        <div className='iconSide'>
          <Icon icon={IconTypes.ALERT_OCTAGON} />
        </div>
      )}

      {type === InfoType.DANGER && (
        <div className='iconSide'>
          <Icon icon={IconTypes.ALERT_CIRCLE} />
        </div>
      )}

      <div className='info-container'>
        {!!title && <div className='info-title'>{title}</div>}
        {!!description && <div className='info-description'>{description}</div>}
      </div>

      {button && (
        <div className={`button filled ${type}`} onClick={button.onClick}>
          {button.text}
        </div>
      )}
    </div>
  )
}

export default InfoBox
