import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAxios, getTokenHeaders, patchAxios } from '../../utils/lib/requestAxios'

const GetPaths = {
  res: '/res/poll-controls',
  uni: '/uni/poll-controls',
  list: '/lis/poll-controls',
  lis: '/lis/poll-controls',
  cse: '/cse/poll-controls',
}

// Request Poll controls
export const requestControlPoll = createAsyncThunk<any, { pollId: string; type: string }>(
  'control/requestControlPoll',
  async ({ pollId, type }, { rejectWithValue }) => {
    const response: any = await getAxios(
      `${GetPaths[type]}?pollId=${pollId}`,
      getTokenHeaders(true)
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    return {
      pollId,
      data: response.data,
    }
  }
)

export const requestControlPollStates = (builder) => {
  builder.addCase(requestControlPoll.fulfilled, (state, { payload: { pollId, data } }) => {
    state.controlByPollId[pollId] = data
  })
}

// Set poll controls
export const requestEditControlPoll = createAsyncThunk<
  any,
  { pollId: string; type: string; params: any }
>(
  'control/requestEditControlPoll',
  async ({ pollId, type, params = {} }, { rejectWithValue, dispatch }) => {
    const response: any = await patchAxios(
      `${GetPaths[type]}/${params.id}`,
      params,
      getTokenHeaders()
    )

    if (response.error) {
      return rejectWithValue(response.error.response.status)
    }
    dispatch(requestControlPoll({ pollId, type }))
    return {
      pollId,
      data: response.data,
    }
  }
)

export const requestEditControlPollStates = (builder) => {
  builder.addCase(requestEditControlPoll.fulfilled, (state, { payload: { pollId, data } }) => {
    state.controlByPollId[pollId] = data
  })
}
