import useLocalize from '../../../locales/hook'
import { useRoles } from '../../../redux/roles/hooks'
import { useAuthCheck } from '../../../redux/auth/hooks'
import { useLoginData } from '../../../redux/appConfig/hooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDemo, useRandomOverride } from '../../../redux/demo/hooks'
import { useCellingMembers } from '../../../redux/sealing/hooks'

// Components
import RoleItem from './partials/RoleItem'
import Icon from '../../../atoms/Icon/Icon'
import Button from '../../../atoms/Button/Button'
import WarningModal from '../../../atoms/WarningModal/WarningModal'
import DropdownPicker from '../../../atoms/DropdownPicker/DropdownPicker'

// Uutils & Style
import './DemoModal.scss'
import './partials/styles.scss'
import IconTypes from '../../../utils/types/IconTypes'
import getFullName from '../../../utils/lib/getFullName'
import CellMemberItem from './partials/CellMemberItem'

const DemoModal = () => {
  // Hooks
  const t = useLocalize()
  const { isLogged, isAdmin } = useAuthCheck()

  const { establishments } = useLoginData()
  const { overrideUsers, getAllRandom, setUsersOverride } = useRandomOverride()
  const { isDemo, currentUser, currentUserId, selectUser, stopDemo } = useDemo()
  const cellingMembersIds = useCellingMembers()

  // States
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [visible, setVisible] = useState<boolean>(false)
  const [filters, setFilters] = useState<any>({ skip: 0 })
  let { roles } = useRoles()
  roles = roles.filter((e) => e !== 'ADMIN' && e !== 'SUPERVISOR')
  // Memos
  const name = useMemo(
    () => (currentUser ? t('connected_as', { name: getFullName(currentUser) }) : t('select_user')),
    [currentUser, t]
  )
  const noEstablishment = useMemo(() => ({ label: t('establishments'), value: undefined }), [t])
  const establishmentsData = useMemo(() => {
    if (!establishments) return []

    return [noEstablishment, ...establishments?.map(({ name, id }) => ({ label: name, value: id }))]
  }, [establishments, noEstablishment])
  const collegesData = useMemo(() => {
    const establishment = establishments?.filter(({ id }) => filters.establishmentId === id)?.[0]
    if (!establishment) return []
    return establishment.colleges.map(({ type, id }) => ({
      label: t(`college_${type}`),
      value: id,
    }))
  }, [establishments, filters.establishmentId, t])

  // Handlers
  const handleOpenStatus = useCallback(() => setIsOpen((prev) => !prev), [])
  const handlePickerChange = useCallback(
    ({ value }, valueKey) => {
      if (valueKey === 'collegeId') {
        const establishment = establishments?.filter(
          ({ id }) => filters.establishmentId === id
        )?.[0]
        setFilters({
          ...filters,
          [valueKey]: value,
          collegeType: establishment.colleges.find(({ id }) => id === value)?.type,
        })
      } else {
        setFilters({ ...filters, [valueKey]: value, collegeId: undefined, collegeType: undefined })
      }
    },
    [establishments, filters]
  )

  const handleUsersChange = useCallback(
    (role: string, userId: string) => {
      let cpSelection = JSON.parse(JSON.stringify(overrideUsers))

      if (!role) return
      if (!cpSelection[role]) cpSelection[role] = [userId]
      else if (!cpSelection[role].includes(userId)) {
        cpSelection[role] = [userId, ...cpSelection[role]]
      } else {
        cpSelection[role] = cpSelection[role].filter((val) => val !== userId)
        if (!cpSelection[role].length) delete cpSelection[role]
      }
      setUsersOverride(cpSelection)
    },
    [overrideUsers, setUsersOverride]
  )

  const handleUserSelect = useCallback((userId) => selectUser(userId), [selectUser])
  const handleBackToAdmin = useCallback(() => selectUser(), [selectUser])
  const handleTryStopDemo = useCallback(() => setVisible(true), [])
  const handleStopDemo = useCallback(() => {
    stopDemo()
    setVisible(false)
  }, [stopDemo])

  // Effects
  useEffect(() => {
    if (isDemo)
      getAllRandom({ establishmentId: filters.establishmentId, collegeId: filters.collegeId })
  }, [getAllRandom, isDemo, filters])

  if (!isDemo || !isLogged || !isAdmin) return null
  return (
    <div id='DemoModal'>
      <div className='header' onClick={handleOpenStatus}>
        <div>{name}</div>
        <Icon icon={isOpen ? IconTypes.CHEVRON_DOWN : IconTypes.CHEVRON_UP} />
      </div>
      <div className={`info ${isOpen}`}>
        {!!establishmentsData?.length && (
          <div className='filters'>
            <DropdownPicker
              initValue={
                filters.establishmentId
                  ? establishmentsData.find(({ value }) => value === filters.establishmentId)
                  : noEstablishment
              }
              datas={establishmentsData}
              placeholder={t('establishments')}
              valueKey='establishmentId'
              onChange={handlePickerChange}
            />
            <DropdownPicker
              initValue={
                filters.establishmentId
                  ? collegesData.find(({ value }) => value === filters.collegeId)
                  : null
              }
              datas={collegesData || []}
              disabled={!collegesData?.length}
              placeholder={t('colleges')}
              valueKey='collegeId'
              onChange={handlePickerChange}
            />
          </div>
        )}
        <div className='body'>
          <CellMemberItem
            key='cell_member'
            filters={filters}
            currentUserId={currentUserId}
            members={cellingMembersIds}
            onUserSelect={handleUserSelect}
          />
          {roles.map((role) => (
            <RoleItem
              key={role}
              role={role}
              filters={filters}
              currentUserId={currentUserId}
              selection={overrideUsers[role]}
              members={cellingMembersIds}
              onUsersAdd={handleUsersChange}
              onUserSelect={handleUserSelect}
            />
          ))}
        </div>
        <div className='footer'>
          {!currentUserId?.length && (
            <Button label={t('finish')} fill onClick={handleTryStopDemo} />
          )}
          {!!currentUserId?.length && (
            <Button label={t('back_to_admin')} onClick={handleBackToAdmin} />
          )}
        </div>
      </div>
      <WarningModal
        title={t('stop_demo_title')}
        className='DemoModalWarning'
        message={{
          descriptionType: 'warning_message',
          description: t('stop_demo_message'),
        }}
        onConfirm={handleStopDemo}
        visible={visible}
        setVisible={setVisible}
      />
    </div>
  )
}

export default DemoModal
