export const isAllConfigTheSame = (elections) => {
  return !elections.some((election) => {
    if (election.id === elections[0].id) return false

    if (election.title !== elections[0].title && !(!election.title && !elections[0].title))
      return true
    if (
      election.subTitle !== elections[0].subTitle &&
      !(!election.subTitle && !elections[0].subTitle)
    )
      return true
    if (election.logoUrl !== elections[0].logoUrl && !(!election.logoUrl && !elections[0].logoUrl))
      return true
    if (
      election.supportPhone !== elections[0].supportPhone &&
      !(!election.supportPhone && !elections[0].supportPhone)
    )
      return true
    if (
      election.supportEmail !== elections[0].supportEmail &&
      !(!election.supportEmail && !elections[0].supportEmail)
    )
      return true
    return false
  })
}
