import { createSlice } from '@reduxjs/toolkit'
import setDesignVariables from '../../utils/lib/setDesignVariables'
import { getCookie } from '../../utils/security/cookies'

// Services
import { requestThemeStates, requestEditThemeStates, requestResetColorsStates } from './services'

// Define a type for the slice state
export type ThemeState = {
  colors: { [key: string]: string }
  logoUrl: string | null
  backgroundUrl: string | null
  enableKeyVoteLogo: boolean
}

// Define the initial state using that type
const initialState: ThemeState = {
  colors: {},
  logoUrl: null,
  backgroundUrl: null,
  enableKeyVoteLogo: false,
}

export const THEME_KEY = 'theme'

// Utils
const getInitialState = () => {
  let theme

  try {
    theme = getCookie(THEME_KEY) || initialState
  } catch {
    theme = initialState
  }
  setDesignVariables(theme.colors)
  return theme
}

export const theme = createSlice({
  name: 'theme',
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    requestThemeStates(builder)
    requestEditThemeStates(builder)
    requestResetColorsStates(builder)
  },
})
