import { createSlice } from '@reduxjs/toolkit'

// Services
import { requestProfileStates } from './services'

// Utils

// Define a type for the slice state
export type ProfileState = {
  userId: string | undefined
}

// Define the initial state using that type
const initialState: ProfileState = {
  userId: undefined,
}

export const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestProfileStates(builder)
  },
})

// export const {} = profile.actions
