import { createSlice } from '@reduxjs/toolkit'
import {
  requestRolesStates,
  requestPrivilegesStates,
  requestSetPrivilegesStates,
  requestAdminPrivilegesStates,
  requestResetRolePrivilegesStates,
  requestSetRolePrivilegesStates,
} from './services'

// Services

// Utils

// Define a type for the slice state
export type RolesState = {
  roles: string[]
  privileges: any
  tmpPrivileges: any
  adminPrivileges: any
  userIds: string[]
  collegeIds: any
}

// Define the initial state using that type
const initialState: RolesState = {
  roles: [],
  privileges: undefined,
  tmpPrivileges: undefined,
  adminPrivileges: undefined,
  userIds: [],
  collegeIds: undefined,
}

export const roles = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    requestSetCollegeIds: (state, { payload }) => {
      const updated: any = payload.toUpdate.reduce((acc, value) => {
        acc[value.collegeId] = value.status
        return acc
      }, {})
      state.collegeIds = {
        ...payload.collegeIdsBase,
        ...updated,
      }
    },
    setTmpPrivileges: (state, { payload }) => {
      state.tmpPrivileges = payload.privileges
    },
    requestSetUserIds: (state, { payload }) => {
      state.userIds = payload.userIds
    },
  },
  extraReducers: (builder) => {
    requestRolesStates(builder)
    requestPrivilegesStates(builder)
    requestSetPrivilegesStates(builder)
    requestAdminPrivilegesStates(builder)
    requestResetRolePrivilegesStates(builder)
    requestSetRolePrivilegesStates(builder)
  },
})

export const { setTmpPrivileges, requestSetCollegeIds, requestSetUserIds } = roles.actions
