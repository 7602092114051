import { useCallback, useEffect, useRef, useState } from 'react'
import Icon from '../Icon/Icon'
import './Button.scss'

interface ButtonProps {
  label: string
  onClick?: (event: MouseEvent, params?: any) => void
  fill?: boolean
  danger?: boolean
  nothing?: boolean
  icon?: string
  keyParams?: any
  disabled?: boolean
  type?: 'primary' | 'tertiary'
  className?: string
  onDisabledClick?: (event: MouseEvent, params?: any) => void
  transparent?: boolean
  accent?: boolean
  disabledFake?: boolean
  timer?: number
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  fill,
  danger,
  nothing,
  icon,
  disabled,
  keyParams,
  className = '',
  onDisabledClick,
  transparent,
  disabledFake = false,
  timer = 500,
  accent,
}) => {
  const clicked = useRef(false)
  const [fakeDisable, setFakeDisabled] = useState(false)

  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true // Will set it to true on mount ...
    return () => {
      mounted.current = false
    } // ... and to false on unmount
  }, [])

  const handleClick = useCallback(
    (ev) => {
      if (mounted.current) {
        ev.preventDefault()
        if (!disabledFake) {
          if (clicked.current) return
          clicked.current = true
        }
        disabled ? onDisabledClick?.(ev, keyParams) : onClick?.(ev, keyParams)
        if (!disabledFake) {
          setFakeDisabled(true)
          setTimeout(() => {
            if (mounted.current) {
              setFakeDisabled(false)
            }
            clicked.current = false
          }, timer)
        }
      }
    },
    [disabledFake, disabled, onDisabledClick, keyParams, onClick, timer]
  )

  return (
    <div
      onClick={handleClick}
      className={`button ${accent ? 'accent' : ''} ${transparent ? 'transparent' : ''} ${
        disabled || fakeDisable ? 'disabled' : ''
      } ${fill ? 'filled' : ''} ${danger ? 'danger' : ''} ${nothing ? 'nothing' : ''} ${className}`}
    >
      {!!icon && <Icon icon={icon} />}
      <div>{label}</div>
    </div>
  )
}

export default Button
