import { decryptData, encryptData } from './encrypt'

export const setSessionStorage = (key: string, value: string = '', decrypt: boolean = true) => {
  const valueEnc = decrypt ? encryptData(value) : value
  sessionStorage.setItem(key, valueEnc)
}

export const getSessionStorage = (key: string, decrypt: boolean = true) => {
  const value = sessionStorage.getItem(key)

  if (value) {
    return decrypt ? decryptData(value) : value
  }
  return null
}

export const deleteSessionStorage = (key: string) => {
  sessionStorage.removeItem(key)
}

export const clearSessionStorage = () => {
  sessionStorage.clear()
}
