import { createSlice } from '@reduxjs/toolkit'

// Services
import {
  requestAnimatorPollResolutionsStates,
  requestAnimatorPollResolutionStates,
  requestMeAnimatorState,
  requestResolutionStatsStates,
  requestResultsAnimateStates,
  requestSetAnimatorInformationState,
} from './services'

// Define a type for the slice state
export type AnimatorState = {
  isLoading: boolean
  isLoadingItem: boolean
  pollIds: string[]
  pollById: { [id: string]: any }
  resolutions: { [id: number]: any }
  needsAnimatorInformation: boolean
  me: null | any
  errors: { [id: string]: any }
  results: { [id: number]: any }
}

// Define the initial state using that type
const initialState: AnimatorState = {
  isLoading: false,
  isLoadingItem: false,
  pollIds: [],
  pollById: {},
  resolutions: {},
  me: null,
  needsAnimatorInformation: false,
  errors: {},
  results: {},
}

export const animator = createSlice({
  name: 'animator',
  initialState,
  reducers: {
    setAnimatorInformations: (state, { payload: value }) => {
      state.needsAnimatorInformation = value
    },
  },
  extraReducers: (builder) => {
    requestMeAnimatorState(builder)
    requestResolutionStatsStates(builder)
    requestSetAnimatorInformationState(builder)
    requestAnimatorPollResolutionStates(builder)
    requestAnimatorPollResolutionsStates(builder)
    requestResultsAnimateStates(builder)
  },
})

export const { setAnimatorInformations } = animator.actions
