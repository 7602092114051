// Hooks
import React, { useMemo } from 'react'
import { useUserId } from '../../redux/profile/hooks'
import { useUser } from '../../redux/user/hooks'

// Components
import Icon from '../../atoms/Icon/Icon'

// Utils
import getFullName from '../../utils/lib/getFullName'
import getColorMaker from '../../utils/lib/getColorMarker'
import getFirstLetters from '../../utils/lib/getFirstLetters'

// Style & Types
import './UserDefaultPicture.scss'
import IconTypes from '../../utils/types/IconTypes'

interface UserPicture {
  userId?: string | null
  className?: string
  name?: string
  elementId?: string
  src?: string
  candidatePicturesDisplayed?: boolean
}

const UserDefaultPicture: React.FC<UserPicture> = ({
  userId,
  src,
  className = '',
  name = '',
  elementId = 'UserPicture',
  candidatePicturesDisplayed = true,
}) => {
  const myId = useUserId()
  const { user } = useUser(userId || (userId !== null ? myId : null))

  // Memos
  const fullName = useMemo(() => getFullName(user) || name, [user, name])
  const backgroundStyle = useMemo(
    () => ({ backgroundColor: getColorMaker(fullName, user?.username) }),
    [fullName, user?.username]
  )
  const displayName = useMemo(() => getFirstLetters(user) || name, [user, name])

  if (!user && !name) {
    return (
      <div id={elementId} className={`UserDefaultProfilePicture icon hasPic ${className}`}>
        <Icon icon={IconTypes.USER} />
      </div>
    )
  }

  if ((!!user?.pic || !!user?.avatar || !!src) && !!candidatePicturesDisplayed) {
    return (
      <div id={elementId} className={`UserDefaultProfilePicture hasPic ${className}`}>
        <img alt={fullName || ''} src={src || user?.pic || user?.avatar} />
      </div>
    )
  }

  return (
    <div
      id={elementId}
      className={`UserDefaultProfilePicture ${className} ${!user ? 'no_user' : ''}`}
      style={backgroundStyle}
    >
      <div className='InitialsPicture' data-testid='initials'>
        {displayName}
      </div>
    </div>
  )
}

export default React.memo(UserDefaultPicture)
