import { createSlice } from '@reduxjs/toolkit'

// // Services
import { requestAddCandidateToListsState } from '../../pages/AdminPoll/partials/AddCandidateModal/services'
import {
  requestChangeListOrderStates,
  requestDeleteCandidateStates,
  requestDeleteListStates,
  requestListsStates,
  requestReorderCandidatesStates,
  requestUpdateListStates,
} from './services'

// Utils

// Define a type for the slice state
export type ListsState = {
  lists: Array<any>
  listIds: { [pollId: number]: number[] }
  listsById: { [id: string]: any }
  isLoading: boolean
}

// Define the initial state using that type
const initialState: ListsState = {
  lists: [],
  listIds: {},
  listsById: {},
  isLoading: false,
}

export const lists = createSlice({
  name: 'lists',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestListsStates(builder)
    requestDeleteListStates(builder)
    requestUpdateListStates(builder)
    requestDeleteCandidateStates(builder)
    requestChangeListOrderStates(builder)
    requestReorderCandidatesStates(builder)
    requestAddCandidateToListsState(builder)
  },
})
