import { createSlice } from '@reduxjs/toolkit'
import { INIT_PURPOSE, PURPOSE_LIST } from './constants'
import {
  requestChangeTypeTemplateState,
  requestDeletePasswordLogState,
  requestUpdateMessageStatusLogState,
  requestInitDataState,
  requestPasswordStatusState,
  requestSendCodesState,
  requestDeletePlannedState,
  requestSendStatusState,
  requestTypeUsersState,
} from './services'

// Define a type for the slice state
type DatasType = {
  type: string
  startDate: string
  endDate: string
  status: string
  nbSent: number
  nbTotalSent: number
  plannedSendTime: Date
  id: number
}

type ContentData = {
  // General
  id: number
  text?: string

  // Email Only
  content?: any

  // SMS Only
  maxLength?: number
  numberOfSms?: number
}

export type SendCodesState = {
  initTab: string | null
  tabs: Array<string>
  disabledTabs: Array<string>
  datas: { [key: string]: ContentData } | {}
  typesDatas: DatasType[]
  isLoading: boolean
  purposeList: string[]
  currentPurpose: string
  usersType: {
    [key: string]: { nb: number; total: number }
  }
}

// Define the initial state using that type
const initialState: SendCodesState = {
  initTab: null,
  typesDatas: [],
  datas: {},
  disabledTabs: [],
  tabs: [],
  isLoading: true,
  purposeList: PURPOSE_LIST,
  currentPurpose: INIT_PURPOSE,
  usersType: {},
}

export const sendCodes = createSlice({
  name: 'sendCodes',
  initialState,
  reducers: {
    setStatePurpose: (state, { payload: value }) => {
      state.currentPurpose = value
    },
  },
  extraReducers: (builder) => {
    requestInitDataState(builder)
    requestSendCodesState(builder)
    requestTypeUsersState(builder)
    requestSendStatusState(builder)
    requestPasswordStatusState(builder)
    requestDeletePasswordLogState(builder)
    requestDeletePlannedState(builder)
    requestUpdateMessageStatusLogState(builder)
    requestChangeTypeTemplateState(builder)
  },
})

export const { setStatePurpose } = sendCodes.actions
