import { useIonRouter } from '@ionic/react'
import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../init/store'
import { isLoggedRoutes } from '../../routes/constants/routes'
import {
  requestGetGeneralHelpFiles,
  requestGetPollHelpFiles,
  requestGetRoleHelpFiles,
  requestSetGeneralHelpFiles,
  requestSetPollHelpFiles,
  requestSetRoleHelpFiles,
} from './services'

export const useGeneralHelpFiles = () => {
  const dispatch = useAppDispatch()

  const pdfFile = useAppSelector((state) => state.helpFiles.general.globalHelpPdfUrl) || null
  const videoFile = useAppSelector((state) => state.helpFiles.general.globalHelpMp4Url) || null

  const getGeneralHelpFiles = useCallback(() => {
    dispatch(requestGetGeneralHelpFiles())
  }, [dispatch])
  const changePdfFile = useCallback(
    (url) => {
      dispatch(requestSetGeneralHelpFiles({ globalHelpPdfUrl: url }))
    },
    [dispatch]
  )
  const changeVideoFile = useCallback(
    (url) => dispatch(requestSetGeneralHelpFiles({ globalHelpMp4Url: url })),
    [dispatch]
  )

  return {
    getGeneralHelpFiles,
    pdfFile,
    videoFile,
    changePdfFile,
    changeVideoFile,
  }
}

export const useRoleHelpFiles = (role?) => {
  const dispatch = useAppDispatch()
  const {
    routeInfo: { pathname },
  } = useIonRouter()

  // Info
  const meRole = useAppSelector((state) => state.auth.role)
  const isLogged = useAppSelector((state) => !!state.auth.token)

  // Files
  const pdfFile =
    useAppSelector((state) => state.helpFiles.role[role || meRole]?.helpPdfUrl) || null
  const videoFile =
    useAppSelector((state) => state.helpFiles.role[role || meRole]?.helpMp4Url) || null

  // Actions
  const getRoleHelpFiles = useCallback(() => {
    dispatch(requestGetRoleHelpFiles(role || meRole))
  }, [dispatch, role, meRole])

  const changePdfFile = useCallback(
    (url) => {
      dispatch(requestSetRoleHelpFiles({ helpPdfUrl: url, role: role || meRole }))
    },
    [dispatch, role, meRole]
  )
  const changeVideoFile = useCallback(
    (url) => dispatch(requestSetRoleHelpFiles({ helpMp4Url: url, role: role || meRole })),
    [dispatch, role, meRole]
  )

  // Effect
  useEffect(() => {
    if (isLogged && isLoggedRoutes()) {
      getRoleHelpFiles()
    }
  }, [getRoleHelpFiles, isLogged, pathname])

  return {
    getRoleHelpFiles,
    pdfFile,
    videoFile,
    changePdfFile,
    changeVideoFile,
  }
}

export const usePollHelpFiles = (pollId) => {
  const dispatch = useAppDispatch()

  const pdfFile = useAppSelector((state) => state.helpFiles.poll[pollId]?.helpPdfUrl) || null
  const videoFile = useAppSelector((state) => state.helpFiles.poll[pollId]?.helpMp4Url) || null

  const getPollHelpFiles = useCallback(() => {
    dispatch(requestGetPollHelpFiles(pollId))
  }, [dispatch, pollId])
  const changePdfFile = useCallback(
    (url) => {
      dispatch(requestSetPollHelpFiles({ helpPdfUrl: url, pollId }))
    },
    [dispatch, pollId]
  )
  const changeVideoFile = useCallback(
    (url) => dispatch(requestSetPollHelpFiles({ helpMp4Url: url, pollId })),
    [dispatch, pollId]
  )

  return {
    getPollHelpFiles,
    pdfFile,
    videoFile,
    changePdfFile,
    changeVideoFile,
  }
}
