import isEmailValid from '../../../utils/booleans/isEmailValid'
import { isOnlyText } from '../../../utils/lib/isOnlyText'

export const defaultInfo: any = {
  collegeId: 1,
}

export const ALPHABETIC_REGEX = '^[A-Za-zÀ-ÿ]+$'
export const ALPHANUMERIC_REGEX = '^[A-Za-zÀ-ÿ0-9]+$'
export const LUHN_FORMULA_REGEX = '^[0-9]+$'
export const NO_AMBIGUOUS = '^[^iI1loO0]+$'
export const ALPHA_REGEX = '^[A-Za-zÀ-ÿ? ?-]+$'

export const NUMERIC_REGEX = '^[0-9]+$'

export const FLOAD_NUMERIC_REGEX = '^[0-9?,]+$'

export type InfoType = {
  username?: string
  firstName?: string
  lastName?: string
  role: string | null
  email?: string
  phoneNumber?: string
  login?: string

  // Collaborator
  civility?: string | null
  personalInformation?: string
  address1?: string
  address2?: string
  address3?: string
  address4?: string
  postalCode?: string
  city?: string
  country?: string
  birthDate?: string
  hiringDate?: string
  description?: string
  // sex?: string
  welcomeMessage?: string
  college?: any
  collegeId?: number | null
  establishment?: any
  establishmentId?: number | null
  ignoreConstraints?: boolean
  establishmentAssigned?: boolean
  mfaActive: boolean
  mfaValidated: boolean
}

export const defaultMandatoryCheck = {
  firstName: false,
  lastName: false,
  birthDate: false,
  // sex: false,
  email: false, // Just if it is written
  phoneNumber: false, // Just if it is written
  establishmentId: false,
  collegeId: false,
}

export const defaultMandatoryCheckNonCSE = {
  firstName: false,
  lastName: false,
  birthDate: false,
  // sex: false,
  email: false, // Just if it is written
  phoneNumber: false, // Just if it is written
}
export const checkData = (info: InfoType, isCSE: boolean, isLoginError?: Function) => {
  const maxBirthdate = new Date()
  maxBirthdate.setDate(31)
  maxBirthdate.setMonth(11)
  maxBirthdate.setFullYear(maxBirthdate.getFullYear() - 15)
  const maxBirthdateIso = maxBirthdate.toISOString().split('T')[0]

  return {
    firstName: !info.firstName?.length || !isOnlyText(info.firstName),
    lastName: !info.lastName?.length || !isOnlyText(info.lastName),
    birthDate: !info.birthDate || info.birthDate >= maxBirthdateIso,
    role: !info.role?.length,
    login: info.login?.length && !!isLoginError && isLoginError(info.login, info.ignoreConstraints),
    // sex: !info.sex?.length,
    email: !!info.email && !isEmailValid(info.email), // Just if it is written
    phoneNumber: info['phoneIsEmpty'] === false && !info.phoneNumber?.length, // Just if it is written
    //postalCode: (info.country?.length || info.city?.length) && !info.postalCode?.length, // Just if it is written
    //city: (info.country?.length || info.postalCode?.length) && !info.city?.length, // Just if it is written
    //country: (info.postalCode?.length || info.city?.length) && !info.country?.length, // Just if it is written
    postalCode: info.city?.length && !info.postalCode?.length, // Just if it is written
    city: info.postalCode?.length && !info.city?.length, // Just if it is written
    establishmentId: info.establishmentAssigned && isCSE && !info.establishmentId,
    collegeId: info.establishmentAssigned && isCSE && !info.collegeId,
  }
}

export const mandatoryByError = {
  mismatching_country_city_postalCode: {
    postalCode: true,
    city: true,
    country: true,
  },
}
