import { createSlice } from '@reduxjs/toolkit'

// Services
import { requestServerTimeState } from './services'

// Define a type for the slice state
export type TimeState = {
  serverTime?: any
  localTime?: any
}

// Define the initial state using that type
const initialState: TimeState = {
  serverTime: { currentTime: Math.floor(Date.now() / 1000) * 1000 },
  localTime: { currentTime: Math.floor(Date.now() / 1000) * 1000 },
}

export const time = createSlice({
  name: 'time',
  initialState,
  reducers: {
    updateServerTime: (state) => {
      //Récupération du timestamp de l'heure du server avec celui de l'heure local en backup
      const timestamp = state.serverTime.currentTime

      //Récupération du timestamp de l'heure local actuel
      const localTimestamp = Math.floor(Date.now() / 1000) * 1000

      //Récupération du timestamp de l'heure local à la précédente mise à jour
      const previousLocalTimestamp = state.localTime.currentTime

      //Calcul de l'heure serveur ajusté du delta passé entre l'heure local actuelle et la precedente
      //Sert à la prise en compte du fait que les setTimeout et setInterval ne resepectent pas les timer spécifié si l'onglet et en arrière plan (environ 2s, 20s ou 60s au lieu des 1s spécifié entre chaque interval)
      const newCurrentTime = timestamp + (localTimestamp - previousLocalTimestamp)

      //Mise à jour de l'heure serveur mise à jour et de l'heure locale "precedente"
      if (newCurrentTime && state.serverTime.currentTime !== newCurrentTime)
        state.serverTime = { currentTime: newCurrentTime }

      if (state.serverTime.localTime !== localTimestamp)
        state.localTime = { currentTime: localTimestamp }
    },
  },
  extraReducers: (builder) => {
    requestServerTimeState(builder)
  },
})

export const { updateServerTime } = time.actions
// export const {} = appConfig.actions
