import { createSlice } from '@reduxjs/toolkit'

// Services
import { requestCommonUsersStates } from './services'

// Define a type for the slice state
export type UsersState = {
  usersByPage: { [key: string]: Array<number | string> }
  totalByPage: { [key: string]: number }
  isLoading: { [key: string]: boolean }
}

// Define the initial state using that type
const initialState: UsersState = {
  usersByPage: {},
  totalByPage: {},
  isLoading: {},
}

export const commonUsers = createSlice({
  name: 'commonUsers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    requestCommonUsersStates(builder)
  },
})
