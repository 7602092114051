import { createSlice } from '@reduxjs/toolkit'

// Services
import {
  requestGetExternalSendersStates,
  requestPgpKeysStates,
  requestPostPgpKeyStates,
  requestRemovePgpKeyStates,
  requestSetExternalSendersStates,
} from './services'

// Utils

// Define a type for the slice state
export type PgpKeysState = {
  keys: { [key: string]: any }[]
  isLoading: boolean
  file: File | null
  externalSenders: {
    [key: string]: {
      name?: string
      email?: string
      encryptionKeyId?: number
    }
  }
}

// Define the initial state using that type
const initialState: PgpKeysState = {
  keys: [],
  isLoading: false,
  file: null,
  externalSenders: {
    id: {},
    pwd: {},
  },
}

export const pgpKeys = createSlice({
  name: 'pgpKeys',
  initialState,
  reducers: {
    RemoveKey: (state, { payload: data }) => {
      state.keys = data
    },
  },
  extraReducers: (builder) => {
    requestPgpKeysStates(builder)
    requestPostPgpKeyStates(builder)
    requestRemovePgpKeyStates(builder)
    requestSetExternalSendersStates(builder)
    requestGetExternalSendersStates(builder)
  },
})

export const { RemoveKey } = pgpKeys.actions
