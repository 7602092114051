import { useCallback, useEffect, useState } from 'react'

import Select, { MenuPlacement } from 'react-select'
import Creatable from 'react-select/creatable'
import useLocalize from '../../locales/hook'

import './DropdownPicker.scss'

interface Props {
  datas: Array<{
    value: string
    label: string
  }>
  disabled?: boolean
  onChange: (el: any, valueKey?: string) => void
  initValue?: any
  label?: string
  placeholder?: string
  menuPlacement?: MenuPlacement
  maxMenuHeight?: number
  valueKey?: string
  error?: boolean
  isInputable?: boolean
  customAllowed?: boolean
}

interface ValueProps {
  value: string
  label: string
}

const customStyles = {
  dropdownIndicator: (defaultStyles: any, state) => ({
    ...defaultStyles,
    opacity: state?.isDisabled ? '0.25' : '1',
    color: 'var(--ion-color-primary) !important',
    cursor: 'pointer',
  }),
  indicatorsContainer: (defaultStyles: any) => ({
    ...defaultStyles,
    backgroundColor: 'var(--ion-toolbar-background)',
    borderLeft: 'var(--ion-border)',
  }),
  placeholder: (defaultStyles: any) => ({
    ...defaultStyles,
    fontWeight: '300',
    color: '#dcdcdc',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 'calc(100% - 10px)',
  }),
  container: (style: any, state: any) => ({
    ...style,
    borderRadius: '4px',
    border: state.isFocused ? '1px solid var(--ion-darker-border-color)' : 'var(--ion-border)',
    margin: '8px 0px',
  }),
  control: (style: any) => ({
    ...style,
    border: '0px',
    borderRadius: '4px',
    boxShadow: 'none',
    overflow: 'hidden',
  }),
  menu: (style: any, state: any) => ({
    ...style,
    marginTop: -2,
    marginBottom: -2,
    borderRadius: state.placement === 'top' ? '4px 4px 0px 0px' : '0px 0px 4px 4px',
    marginLeft: -1,
    marginRight: -1,
    width: '100.6%',
    border: '1px solid var(--ion-darker-border-color)',
    borderTopWidth: state.placement === 'top' ? 1 : 0,
    borderBottomWidth: state.placement === 'top' ? 0 : 1,
    boxShadow: 'none',
    outline: 'none',
    zIndex: 100,
  }),
  option: (style: any, state: any) => ({
    ...style,
    backgroundColor: state.isRtl
      ? 'red'
      : state.isSelected
      ? 'rgba(var(--ion-color-primary-rgb), .25)'
      : state.isFocused
      ? 'rgba(var(--ion-color-primary-rgb), .05)'
      : null,
    color: state.isSelected ? 'black' : null,
  }),
}

const components = { IndicatorSeparator: () => null }

const DropdownPicker: React.FC<Props> = ({
  datas,
  initValue,
  onChange,
  disabled,
  label,
  placeholder,
  menuPlacement = 'auto',
  maxMenuHeight = 200,
  valueKey,
  error,
  customAllowed,
}) => {
  const t = useLocalize()
  const [value, setValue] = useState<ValueProps | null>(
    initValue
      ? {
          value: initValue?.value ? initValue.value + '' : initValue,
          label: initValue?.label || initValue,
        }
      : null
  )

  useEffect(() => {
    setValue(
      initValue
        ? {
            value: initValue?.value ? initValue.value + '' : initValue,
            label: initValue?.label || initValue,
          }
        : null
    )
  }, [initValue])

  const handleChange = useCallback(
    (el: any) => {
      if (el) {
        onChange(el, valueKey)
        setValue(el)
      }
    },
    [onChange, valueKey]
  )

  const handleLabelFormat = useCallback((value: string) => value, [])

  const optionMessage = useCallback(() => t('no_option'), [])

  if (customAllowed) {
    return (
      <div className={`dropdownPicker  ${error ? 'error' : ''}`}>
        {!!label && <div className='label'>{label}</div>}
        <Creatable
          placeholder={placeholder}
          className='selector'
          value={value}
          isDisabled={disabled}
          hideSelectedOptions={false}
          styles={customStyles}
          blurInputOnSelect={true}
          onChange={handleChange}
          components={components}
          formatCreateLabel={handleLabelFormat}
          options={datas}
          menuPlacement={menuPlacement}
          maxMenuHeight={maxMenuHeight}
        />
      </div>
    )
  }

  return (
    <div className={`dropdownPicker  ${error ? 'error' : ''}`}>
      {!!label && <div className='label'>{label}</div>}
      <Select
        placeholder={placeholder}
        className='selector'
        value={value}
        isDisabled={disabled}
        hideSelectedOptions={false}
        styles={customStyles}
        blurInputOnSelect={true}
        onChange={handleChange}
        components={components}
        options={datas}
        menuPlacement={menuPlacement}
        maxMenuHeight={maxMenuHeight}
        noOptionsMessage={optionMessage}
      />
    </div>
  )
}

export default DropdownPicker
