export const CIVILITY = ['Monsieur', 'Madame', 'Mademoiselle']

export const CIVILITY_VALUE = {
  Monsieur: 'MISTER',
  Mademoiselle: 'MISS',
  Madame: 'MADAM',
  MISTER: 'MISTER',
  MISS: 'MISS',
  MADAM: 'MADAM',
}

export const CIVILITY_DISPLAY = {
  Monsieur: 'Monsieur',
  MISTER: 'Monsieur',
  Madame: 'Madame',
  Mademoiselle: 'Mademoiselle',
  MISS: 'Mademoiselle',
  MADAM: 'Madame',
}

export const SEX = ['H', 'F']

export const SEX_VALUE = {
  H: 'MAN',
  F: 'WOMAN',
  MAN: 'MAN',
  WOMAN: 'WOMAN',
}

export const SEX_DISPLAY = {
  H: 'H',
  F: 'F',
  WOMAN: 'F',
  MAN: 'H',
}

export const ONE_HOUR = 3600000
